/* eslint-disable */
import { Layout } from '@components/Layouts';
import { PrivacyNavigationHeader } from '@components/Ui';

import '../../terms-page.css';

export const CookiePolicyPage = () => {
  return (
    <Layout>
      <PrivacyNavigationHeader />
      <div className="terms-page py-6">
        <div>
          <h1>
            Cookie Policy
          </h1>
          <p>
            <span>Beeple Inc. 
              ("Beeple", "we", "us", or "our") employs cookies and similar
              tracking technologies on our website and mobile applications
              (referred to individually as “platform” and collectively as
              “platforms”). This Cookie Policy provides detailed information
              about these technologies and the choices you have regarding their
              use.
            </span>
          </p>
          <p>
            <span></span>
          </p>
          <h2>
              1. What is a “cookie” and what are “similar tracking
              technologies”?
          </h2>
          <p>
            <span>
              A “cookie” is a small text file that is stored and/or read by your
              web browser on the hard disk of the device you are using, such as
              a desktop computer, laptop, or smartphone, when you visit
              websites. Beeple also utilizes other tracking technologies,
              including “pixels,” “tracking scripts,” and “software development
              kits” (SDKs). These technologies consist of small transparent
              image files or snippets of code embedded in our website, emails,
              and mobile apps, which monitor and record your interactions with
              them. In this Cookie Policy, all these technologies are
              collectively referred to as “cookies.”
            </span>
          </p>
          <p>
            <span></span>
          </p>
          <h2>
              2. What are the different types of cookies and how are they used
              on our platforms?
          </h2>
          <p>
            <span>
              On our platforms, we utilize several types of cookies, each
              serving distinct functions:
            </span>
          </p>
          <p>
            <span></span>
          </p>
          <p>
            <b>Essential Cookies:</b>
          </p>
          <p>
            <span>
              These cookies are fundamental for you to navigate and interact
              with our platforms. They enable basic functionalities such as
              logging in, booking a ride, and ensuring platform security.
              Essential cookies are crucial for the proper operation of Beeple
              Inc. and, therefore, cannot be disabled.
            </span>
          </p>
          <p>
            <span></span>
          </p>
          <p>
            <b>Performance Cookies:</b>
          </p>
          <p>
            <span>
              Performance cookies collect information about how you use our
              platforms. They help us identify which pages are the most and
              least popular and gauge the effectiveness of our content. This
              data aids us in optimizing our platforms to ensure that you can
              swiftly find what you need. These cookies may be set either by
              Beeple Inc. or by our analytics partners. Without these cookies,
              we lose valuable insight into our site's performance, and your
              experience could be less efficient as we would not be aware of
              your past visits.
            </span>
          </p>
          <p>
            <span></span>
          </p>
          <p>
            <b>Marketing and Targeting Cookies:</b>
          </p>
          <p>
            <span>
              These cookies track your visits to our platform, the pages you
              have viewed, and the links you have clicked. They are primarily
              used to display advertisements that are tailored to your
              interests. By doing so, we aim to enhance the relevance of the ads
              you see and measure the effectiveness of our advertising efforts.
              These cookies may be set by us or by our advertising partners. If
              you choose not to accept these cookies, you will still see ads,
              but they may be less relevant to your interests.
            </span>
          </p>
          <p>
            <span></span>
          </p>
          <h2>
            3. How can you manage your cookie preferences?
          </h2>
          <p>
            <span></span>
          </p>
          <p>
            <span>
              When you first visit our platforms, a cookie banner is presented,
              providing detailed information about the use of cookies. Within
              this banner, you have the opportunity to consent to the use of
              performance cookies and marketing and targeting cookies.
            </span>
          </p>
          <p>
            <span>
              It's important to note that your preferences are specific to the
              device and web browser you are using. If you access our platforms
              using different devices or browsers, you will need to set your
              preferences on each one separately.
            </span>
          </p>
          <p>
            <span>
              You have the flexibility to change your cookie settings at any
              time. To modify your preferences, simply navigate to the cookie
              settings available in the footer of our website or within your
              member account settings.
            </span>
          </p>
          <p>
            <span></span>
          </p>
          <h2>
            4. Updates to our Cookie Policy
          </h2>
          <p>
            <span></span>
          </p>
          <p>
            <span>
              Whenever there are changes to our cookie usage, we will update
              this Cookie Policy and note the date of the last modification. The
              most recent update was made on 1 April 2024. For registered
              members, we will also communicate these changes directly, ensuring
              you are always informed about how we manage cookies.
            </span>
          </p>
          <p>
            <span></span>
          </p>
          <h2>
            5. Contact Information
          </h2>
          <p>
            <span>
              If you have any questions regarding our Cookie Policy or need
              further assistance with cookie-related inquiries, please do not
              hesitate to contact us via email at cookie@beeple.ca. We are here
              to help and ensure that your concerns are addressed promptly.
            </span>
          </p>
          <p>
            <span></span>
          </p>
        </div>
      </div>
    </Layout>
  );
};

import { homeSteps } from '@content';

import { Button, Theme } from '@components';
import { Layout } from '@components/Layouts';
import { SearchTrip } from '@components/SearchTrip';
import { CreateTripButton, RecentTrips } from '@components/Ui';
import homeBannerImage from '@images/home-banner.png';

export const HomePage = () => {
  const makeStep = (
    { title, text, image }: (typeof homeSteps)[number],
    index: number,
  ) => (
    <div
      key={title}
      className="flex flex-1 items-end rounded-[20px] bg-white pb-6 pl-9 pt-10 shadow-[0px_0px_6px_0px_#ECECEC] transition-transform hover:-translate-y-1"
    >
      <div>
        <h4 className="text-20 font-600 text-primary">{title}</h4>
        <p className="my-6 text-16 text-secondary">{text}</p>
        <span className="flex h-9 w-9 items-center justify-center rounded-full bg-hannah text-20 font-700 text-white">
          {index + 1}
        </span>
      </div>
      <img
        src={image}
        alt="Step"
        className="relative top-6 h-auto w-40 shrink-0 "
      />
    </div>
  );

  return (
    <Layout headerVariant="without-border">
      <div slot="wrapper" className="w-full pb-24 pt-10 sm:pt-0">
        <Theme.Wrapper>
          <SearchTrip variant="white" />
          <RecentTrips />
        </Theme.Wrapper>
        <div className="mt-16 bg-background py-14">
          <Theme.Wrapper className="flex justify-around gap-6 py-3 md:max-w-[480px] md:flex-col">
            {homeSteps.map(makeStep)}
          </Theme.Wrapper>
        </div>
        <Theme.Wrapper className="mt-16 flex justify-around md:flex-col md:items-center">
          <div className="mt-12 max-w-[360px]">
            <h3 className="text-40 font-700 text-hannah">Beep-Beep.</h3>
            <div className="text-32 font-700 leading-40">
              Find travel companions for your trip
            </div>
            <p className="mt-5 text-18 text-secondary">
              Cover your gas expenses and meet new people
            </p>
            <CreateTripButton className="mt-14 block md:mt-6">
              <Button
                stopPropagation={false}
                variant="hannah"
                className="rounded-[10px]"
              >
                Publish a ride
              </Button>
            </CreateTripButton>
          </div>
          <img src={homeBannerImage} alt="home banner" width="500" />
        </Theme.Wrapper>
      </div>
    </Layout>
  );
};

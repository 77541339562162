import { useCallback, useEffect, useState } from 'react';
import { useImmer } from 'use-immer';

import { Trip } from '@typings';
import { PATH } from '@constants';
import { getTrips } from '@services';
import { decrementTripsNotifications } from '@slices';
import { useDispatch, usePagination } from '@hooks';
import { toastifyError } from '@utils';

import { Button, ChaseSpinner, Link, Render } from '@components';
import { DriverTripCard } from '@components/Ui';

export const DriverTripsPage = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [isFetched, setFetched] = useState(false);
  const [trips, setTrips] = useImmer<Trip.DriverItem[]>([]);
  const {
    pagination: { next, total },
    setPagination,
  } = usePagination();

  const fetchTrips = useCallback(
    async (page?: number | null) => {
      try {
        setLoading(true);

        const { items: trips, ...pagination } = await getTrips(page);

        setTrips((state) => state.concat(trips));
        setPagination(pagination);
      } catch (error) {
        toastifyError(error);
      } finally {
        setLoading(false);
        setFetched(true);
      }
    },
    [setTrips, setPagination],
  );

  useEffect(() => {
    fetchTrips();
  }, [fetchTrips]);

  /**
   * Changing booking status affect `request`, `freeSeats` and `notifications.trips` values.
   * Currently, we don't have websockets to update data. It decrements manually on approving/declining
   */
  const handleBookingStatusChange = (
    tripId: string,
    {
      id: bookingId,
      passengers: seats,
      status,
    }: Pick<Trip.PassengerItem, 'id' | 'passengers' | 'price' | 'status'>,
  ) => {
    setTrips((trips) => {
      const trip = trips.find(({ id }) => id === tripId);
      const booking = trip?.bookings.find(({ id }) => id === bookingId);
      const previousStatus = booking?.status;

      if (!trip || !booking) {
        return trips;
      }

      /**
       * Manually decrementing until web sockets is implemented
       */
      if (previousStatus !== 'approved') {
        dispatch(decrementTripsNotifications());
      }

      if (previousStatus !== 'approved' && status !== 'completed') {
        trip.freeSeats += seats;
      }

      if (status === 'approved') {
        trip.freeSeats -= seats;
      }

      trip.requests -= 1;
      booking.status = status;
    });
  };

  /**
   * `requests` let us manipulate `notifications.trips` count
   */
  const handleTripCancel = (tripId: string, requests: number) => {
    setTrips((trips) => {
      const trip = trips.find(({ id }) => id === tripId);

      if (!trip) {
        return trips;
      }

      trip.requests = 0;
      trip.status = 'canceled';

      if (requests) {
        dispatch(decrementTripsNotifications(requests));
      }
    });
  };

  const makeItem = (trip: Trip.DriverItem) => {
    return (
      <DriverTripCard
        {...trip}
        key={trip.id}
        onBookingChange={handleBookingStatusChange}
        onTripCancel={handleTripCancel}
      />
    );
  };

  const renderContent = () => {
    if (!isFetched) {
      return (
        <div className="mt-20 flex justify-center">
          <ChaseSpinner color="eva" className="h-10 w-10" />
        </div>
      );
    }

    if (isFetched && trips.length === 0) {
      return (
        <div className="flex flex-col items-center gap-4">
          <p className="mt-20 text-center text-24 font-600 text-eva">
            No trips found
          </p>
          <Link variant="eva" className="px-10" to={PATH.TRIP_CREATING}>
            Make trip
          </Link>
        </div>
      );
    }

    return (
      <>
        <p className="text-14 font-600 text-eva">{total} trips</p>
        {trips.map(makeItem)}
        <Render if={next}>
          <div className="mt-6 text-center">
            <Button
              loading={loading}
              variant="eva"
              onClick={() => fetchTrips(next)}
            >
              Load more
            </Button>
          </div>
        </Render>
      </>
    );
  };

  return <div className="flex flex-col gap-2">{renderContent()}</div>;
};

import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { faAddressCard } from '@fortawesome/pro-light-svg-icons';
import {
  faCircleCheck,
  faCircleXmark,
} from '@fortawesome/pro-regular-svg-icons';
import clsx from 'clsx';

import { axios } from '@features';
import { UserState } from '@slices';
import { as, getFullName, isEmailVerified } from '@utils';

import { Avatar, Button, ChaseSpinner, Icon, Render, Theme } from '@components';
import { Layout } from '@components/Layouts';

type Item = {
  title: string;
  value: string | number | null;
};

export const UserPage = () => {
  const { userId } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<UserState>();

  const {
    avatar,
    firstName,
    lastName,
    emailVerifiedAt,
    trips,
    bookings,
    createdAt,
  } = as.o<UserState>(user);
  const isUserEmailVerified = isEmailVerified(emailVerifiedAt);

  const personal: Item[] = [
    { title: 'Name', value: getFullName(firstName, lastName) },
    { title: 'Trips as passenger', value: bookings },
    { title: 'Trips as driver', value: trips },
    { title: 'With Beeple', value: dayjs(createdAt).fromNow(true) },
  ];

  useEffect(() => {
    const run = async () => {
      try {
        const user = await axios.get<never, UserState>(`/profile/${userId}`);

        setUser(user);
        setLoading(false);
      } catch (error) {
        /**
         * Conrinue regardless error
         */
      }
    };

    run();
  }, [userId]);

  const makeItem = (text: string, isChecked: boolean = false) => {
    const icon = isChecked ? faCircleCheck : faCircleXmark;
    const color = isChecked ? 'text-[#22AF27]' : 'text-[#EAC916]';

    return (
      <div key={text} className="flex items-center gap-1.5">
        <Icon icon={icon} className={clsx('h-5 w-5 text-16', color)} />
        <p className="text-label-1">{text}</p>
      </div>
    );
  };

  const makeTableRow = ({ title, value }: Item) => (
    <tr key={title}>
      <td className="pr-6 text-14">{title}:</td>
      <td className="text-14 font-600">{as(value, '-')}</td>
    </tr>
  );

  const renderTable = (data: Item[]) => {
    return (
      <table className="border-separate border-spacing-4">
        <tbody>{data.map(makeTableRow)}</tbody>
      </table>
    );
  };

  if (loading) {
    return (
      <Layout>
        <div className="mt-20 flex justify-center">
          <ChaseSpinner color="primary" className="h-10 w-10" />
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className="flex items-start gap-14 py-6 md:flex-col md:items-center md:gap-6">
        <div className="sticky top-4 flex basis-[302px] flex-col items-center gap-4 md:static md:w-full md:basis-auto">
          <Avatar
            src={avatar}
            className="h-32 w-32 bg-neutral-300 text-48 text-white shadow-[2px_2px_12px_0px_rgba(0,0,0,0.15)]"
          />
          <p className="text-h6">{getFullName(firstName, lastName)}</p>
          <Theme.Container className="flex w-full flex-col gap-3 p-4">
            <p className="mb-3 text-title-2">Verified information</p>
            {makeItem('Phone number', true)}
            <Render if={isUserEmailVerified}>{makeItem('Email', true)}</Render>
            <Render if={!isUserEmailVerified}>
              <div className="my-2 h-px bg-neural-01" />
              {makeItem('Email', false)}
            </Render>
          </Theme.Container>
          <Button
            variant="hannah"
            className="w-full"
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
        </div>
        <div className="flex flex-1 flex-col gap-4">
          <Theme.Container className="p-5">
            <p className="flex items-center justify-between text-body-1-s">
              Personal information
              <Icon
                icon={faAddressCard}
                className="h-8 w-8 text-24 text-hannah"
              />
            </p>
            {renderTable(personal)}
          </Theme.Container>
          <Theme.Container className="flex min-h-[400px] items-center justify-center p-5 md:min-h-0 md:py-10">
            <p className="text-center text-h5">
              We are working to have profile ratings and reviews here
            </p>
          </Theme.Container>
        </div>
      </div>
    </Layout>
  );
};

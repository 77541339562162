export const formatToSnakeCase = (input: unknown): unknown => {
  if (input instanceof File || input instanceof FileList) {
    return input;
  }

  if (typeof input === 'object' && input !== null) {
    if (Array.isArray(input)) {
      return input.map(formatToSnakeCase);
    }
    const formattedObject: unknown = {};

    Object.keys(input).forEach((key) => {
      const snakeCaseKey = key
        .replace(/([a-z0-9])([A-Z])/g, '$1_$2')
        .toLowerCase();
      // @ts-expect-error todo: resolve error
      formattedObject[snakeCaseKey] = formatToSnakeCase(input[key]);
    });

    return formattedObject;
  }

  return input;
};

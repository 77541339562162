import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { PATH } from '@constants';
import { cn } from '@utils';

import { Link } from '@components';

import { BackButton } from './BackButton';

const links = [
  {
    title: 'Terms and Conditions',
    path: PATH.TERMS_AND_CONDITIONS,
  },
  {
    title: 'Privacy and Data Protection Policy',
    path: PATH.PRIVACY_POLICY,
  },
  {
    title: 'Cookie Policy',
    path: PATH.COKIE_POLICY,
  },
];

export const PrivacyNavigationHeader = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const makeLink = ({ title, path }: (typeof links)[number]) => {
    const isActive = pathname === path;

    return (
      <Link
        variant="ghost"
        key={path}
        to={path}
        className={cn(
          'h-8 text-center text-eva hover:text-eva-dark md:h-auto',
          {
            'text-hannah hover:text-hannah': isActive,
          },
        )}
      >
        {title}
      </Link>
    );
  };

  return (
    <div className="mt-8 flex items-center gap-3">
      <BackButton />
      <div className="flex flex-1 justify-around md:flex-col">
        {links.map(makeLink)}
      </div>
    </div>
  );
};

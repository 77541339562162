import { useContext, useState } from 'react';

import { Trip } from '@typings';
import { toast } from '@features';
import { bookTripSeats, getEmailVerificationToken } from '@services';
import { UserState } from '@slices';
import {
  isAuthenticatedSelector,
  isEmailVerifiedSelector,
  userSelector,
} from '@selectors';
import { useSelector } from '@hooks';
import { toastifyError } from '@utils';

import { Button, Modal } from '@components';
import { TripsContext } from '@components/TripsProvider';

import {
  AuthModal,
  EmailVerificationModal,
  UpdateProfileModal,
} from '../Modals';

type ModalType = 'profile' | 'email' | null;

type Props = {
  tripId: string;
  booking: Trip.Item['booking'];
};

export const TripBookButton = ({ tripId, booking }: Props) => {
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const isEmailVerified = useSelector(isEmailVerifiedSelector);
  const { firstName } = useSelector(userSelector);

  const { seats, onSeatsBook } = useContext(TripsContext);

  const [loading, setLoading] = useState(false);
  /**
   * Generates unique ID for modals
   */
  const [uuid, setUuid] = useState('');
  /**
   * Manages email `confirm-email` modal's token after `new-user` process
   */
  const [emailConfirmationToken, setEmailConfirmationToken] = useState('');
  const [modal, setModal] = useState<ModalType>(null);

  const bookingStatus = booking?.status;

  const resetModal = () => {
    setModal(null);
    setUuid('');
  };

  const resetEmailConfirmationModal = () => {
    resetModal();

    if (emailConfirmationToken) {
      setEmailConfirmationToken('');
    }
  };

  const handleModalChange = (modal: ModalType) => {
    const uuid = Math.random().toString(16).slice(2);

    setModal(modal);
    setUuid(uuid);
  };

  const handleProfileFill = async ({ email }: UserState) => {
    try {
      const { verify: token } = await getEmailVerificationToken(email!);

      setEmailConfirmationToken(token);
      handleModalChange('email');
    } catch (error) {
      resetModal();
    }
  };

  const handleBook = async () => {
    try {
      setLoading(true);

      const booking = await bookTripSeats({ tripId, seats });

      onSeatsBook(tripId, booking);

      toast.success('Your trip has been successfully booked');
    } catch (error) {
      toastifyError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleBookProcess = () => {
    if (!firstName) {
      handleModalChange('profile');

      return;
    }

    if (!isEmailVerified) {
      handleModalChange('email');

      return;
    }

    handleBook();
  };

  if (bookingStatus) {
    return null;
  }

  if (!isAuthenticated) {
    return (
      <Modal content={<AuthModal />}>
        <Button variant="eva" className="px-12 sm:w-full">
          Book
        </Button>
      </Modal>
    );
  }

  return (
    <>
      {modal === 'profile' && (
        <Modal
          key={uuid}
          defaultOpen
          onClose={resetModal}
          content={<UpdateProfileModal onSuccess={handleProfileFill} />}
        />
      )}
      {modal === 'email' && (
        <Modal
          defaultOpen
          key={uuid}
          onClose={resetEmailConfirmationModal}
          content={
            <EmailVerificationModal
              token={emailConfirmationToken}
              onSuccess={handleBook}
            />
          }
        />
      )}
      <Button
        loading={loading}
        variant="eva"
        className="px-12 sm:w-full"
        onClick={handleBookProcess}
      >
        Book
      </Button>
    </>
  );
};

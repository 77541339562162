import DatePicker, { ReactDatePickerProps } from 'react-datepicker';

import { noop } from '@utils';

import 'react-datepicker/dist/react-datepicker.css';

type Props = {
  className?: string;
  variant?: 'react-datepicker-search' | 'react-datepicker-departure-date';
  value: ReactDatePickerProps['selected'];
  minDate?: ReactDatePickerProps['minDate'];
  timeIntervals?: ReactDatePickerProps['timeIntervals'];
  timeCaption?: ReactDatePickerProps['timeCaption'];
  showTimeSelect?: ReactDatePickerProps['showTimeSelect'];
  showTimeSelectOnly?: ReactDatePickerProps['showTimeSelectOnly'];
  dateFormat?: ReactDatePickerProps['dateFormat'];
  placeholder?: string;
  onValueChange?: ReactDatePickerProps['onChange'];
};

export const InputDatePicker = ({
  variant = 'react-datepicker-search',
  placeholder,
  value,
  minDate = new Date(),
  dateFormat = 'yyyy-MM-dd',
  onValueChange = noop,
  ...props
}: Props) => {
  const handleInputPrevent = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.preventDefault();
  };

  return (
    <DatePicker
      {...props}
      disabledKeyboardNavigation
      wrapperClassName={variant}
      placeholderText={placeholder}
      minDate={minDate}
      dateFormat={dateFormat}
      selected={value}
      onChange={onValueChange}
      onKeyDown={handleInputPrevent}
    />
  );
};

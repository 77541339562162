import { extendTailwindMerge } from 'tailwind-merge';
import { TV, tv as tailwindVariants } from 'tailwind-variants';
import clsx, { ClassValue } from 'clsx';

export { type VariantProps } from 'tailwind-variants';

const fontSizeList = Array.from({ length: 101 }).map(
  (_, index) => `text-${index}`,
);

const twMergeConfig = {
  classGroups: {
    'font-size': [
      ...fontSizeList,
      'text-h0',
      'text-h1',
      'text-h2',
      'text-h3',
      'text-h4',
      'text-h5',
      'text-h6',
      'text-body',
      'text-footnote',
      'text-footnote-large',
      'text-caption',
      'text-caption-2',
      'text-caption-3',
      'text-button',
      'text-headline',
      'text-title-1',
      'text-title-2',
      'text-body-1-r',
      'text-body-1-m',
      'text-body-1-s',
      'text-body-2-r',
      'text-body-2-m',
      'text-body-2-s',
      'text-label-1',
      'text-button-1',
      'text-subtitle',
      'text-footnote',
    ],
  },
};

const twMerge = extendTailwindMerge(twMergeConfig);

export const cn = (...classNames: ClassValue[]) => twMerge(clsx(classNames));

/**
 * todo: resolve new tailwind-variants version ReturnType error
 */
// @ts-ignore
export const tv: TV = (options) => tailwindVariants(options, { twMergeConfig });

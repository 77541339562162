import { Outlet, useLocation } from 'react-router-dom';

import { PATH } from '@constants';
import { notificationsSelector } from '@selectors';
import { useSelector } from '@hooks';
import { cn } from '@utils';

import { Link, Render } from '@components';
import { Layout } from '@components/Layouts';
import { BackButton } from '@components/Ui';

export const TripsLayout = () => {
  const { pathname } = useLocation();
  const { trips } = useSelector(notificationsSelector);

  const getClassName = (isActive: boolean) =>
    cn(
      'relative flex h-[18px] items-center justify-center rounded-full border border-eva bg-transparent text-10 font-500 text-eva transition-colors hover:bg-eva hover:text-white sm:h-6',
      { 'bg-eva text-white': isActive },
    );

  return (
    <Layout>
      <div className="p-6 sm:px-0">
        <header className="mb-5 flex w-full items-center gap-1.5">
          <BackButton />
          <p className="mr-2 text-18 font-600 text-eva">My trips</p>
          <Link
            className={getClassName(pathname === PATH.PASSENGER_TRIPS)}
            to={PATH.PASSENGER_TRIPS}
          >
            Passenger
          </Link>
          <Link
            className={getClassName(pathname === PATH.DRIVER_TRIPS)}
            to={PATH.DRIVER_TRIPS}
          >
            Driver
            <Render if={trips > 0}>
              <span className="absolute right-0 top-0 flex h-4 w-4 -translate-y-1/2 translate-x-1/2 items-center justify-center rounded-full bg-[#F31212] text-11 font-700 text-white">
                {trips}
              </span>
            </Render>
          </Link>
        </header>
        <Outlet />
      </div>
    </Layout>
  );
};

import { Children } from 'react';

type Slots<T extends string> = {
  [K in T]?: React.ReactElement;
} & {
  content?: React.ReactNode;
};

/**
 * Extracts children nodes with `slot` attribute for Slot
 */
export const getSlots = <T extends string>(children: React.ReactNode) => {
  const collection = Children.toArray(children);

  const slots: Slots<T> = collection.reduce((components, component) => {
    const { slot } = (component as React.ReactElement).props ?? {};

    if (slot) {
      return { ...components, [slot]: component };
    }

    return components;
  }, {});

  slots.content = collection.filter(
    (component) => !(component as React.ReactElement).props?.slot,
  );

  return slots;
};

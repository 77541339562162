import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { AnyFunction, ModalProps } from '@typings';
import { PHOTO_MIMETYPES } from '@constants';
import { toast } from '@features';
import { updateProfile } from '@thunks';
import { userSelector } from '@selectors';
import { useDispatch, useSelector } from '@hooks';
import {
  getFileForSubmit,
  getFileImage,
  noop,
  normalizeFormErrors,
  toastifyError,
  zodErrorMessage,
} from '@utils';

import { Avatar, Button, Field, Modal } from '@components';

const AVATAR_MAX_SIZE = 5242880;

type Schema = z.infer<typeof schema>;

const schema = z.object({
  avatar: z
    .union([z.instanceof(FileList), z.string(), z.null()])
    .refine((avatar) => {
      if (avatar instanceof FileList) {
        return avatar[0].size < AVATAR_MAX_SIZE;
      }

      /**
       * Pass if not binary file
       */
      return true;
    }, zodErrorMessage('Max size is up to 5 MB')),
});

type Props = ModalProps & {
  onSuccess?: AnyFunction;
};

export const UpdateProfileAvatarModal = ({
  onSuccess = noop,
  closeModal = noop,
}: Props) => {
  const dispatch = useDispatch();
  const {
    avatar: userAvatar,
    email,
    firstName,
    lastName,
    dateOfBirth,
  } = useSelector(userSelector);

  const { watch, register, handleSubmit, formState } = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: {
      avatar: userAvatar,
    },
  });

  const [loading, setLoading] = useState(false);

  const watchAvatar = watch('avatar');
  const avatar = getFileImage(watchAvatar);
  const errors = normalizeFormErrors(formState.errors);

  const handleProfileSubmit = handleSubmit(async ({ avatar }) => {
    try {
      setLoading(true);

      const payload = {
        firstName,
        lastName,
        email,
        dateOfBirth,
        avatar: getFileForSubmit(avatar),
      };

      await dispatch(updateProfile(payload));

      toast.success('Your avatar has been successfully updated');
      onSuccess();
      closeModal();
    } catch (error) {
      toastifyError(error);
    } finally {
      setLoading(false);
    }
  });

  return (
    <Modal.Content title="Photo" className="w-[320px]">
      <form
        onSubmit={handleProfileSubmit}
        className="flex flex-col items-center"
      >
        <Field.File
          {...register('avatar')}
          accept={PHOTO_MIMETYPES}
          className="group relative flex h-32 w-32 rounded-full border bg-[#7c909b]/10 text-secondary transition-colors hover:border-primary"
        >
          <Avatar src={avatar} className="h-32 w-32 text-48 text-eva" />
        </Field.File>
        <Field.Error className="text-center">{errors.avatar}</Field.Error>
        <Field.Note className="mt-6 text-center">
          Max image size is up to 5 MB
        </Field.Note>
        <Button
          loading={loading}
          type="submit"
          variant="hannah"
          className="mt-2 w-full"
        >
          Save
        </Button>
      </form>
    </Modal.Content>
  );
};

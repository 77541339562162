import { faPlusCircle } from '@fortawesome/pro-light-svg-icons';

import { PATH } from '@constants';
import { tv, VariantProps } from '@utils';

import { Icon, Link, Theme } from '@components';
import { CreateTripButton, Logo } from '@components/Ui';
import { UserPanel } from '@components/UserPanel';

const variants = tv({
  base: 'px-2 py-8',
  variants: {
    variant: {
      'with-border': 'border-b border-[#3F4E61]/25',
      'without-border': 'border-0',
    },
  },
  defaultVariants: {
    variant: 'with-border',
  },
});

type Props = VariantProps<typeof variants> & {
  className?: string;
};

export const HeaderLayout = ({ className, variant }: Props) => {
  return (
    <header className={variants({ variant, className })}>
      <Theme.Wrapper className="flex w-full items-center justify-between sm:gap-4 sm:px-0">
        <div className="relative">
          <Link
            theme
            to={PATH.HOME}
            className="flex items-center gap-2 sm:gap-1.5"
          >
            <Logo className="w-[120px] sm:w-24" />
            <div className="h-7 w-0.5 bg-eva" />
            <p className="text-14 font-500 text-eva">Carpooling</p>
          </Link>
          <p className="pointer-events-none absolute right-0 top-0 translate-x-full rounded-full bg-hannah px-2 text-14 font-600 uppercase text-white sm:-top-2 sm:right-2">
            Beta
          </p>
        </div>
        <div className="flex items-center gap-4">
          <CreateTripButton className="flex items-center gap-2 text-eva sm:gap-0 sm:text-0">
            <Icon icon={faPlusCircle} className="text-24" />
            Publish a ride
          </CreateTripButton>
          <UserPanel />
        </div>
      </Theme.Wrapper>
    </header>
  );
};

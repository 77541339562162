import { cn } from '@utils';

type Props = {
  className?: string;
};

export const LogoIcon = ({ className }: Props) => {
  return (
    <svg
      className={cn('h-auto w-auto', className)}
      viewBox="0 0 500 500"
      fill="currentColor"
    >
      <g
        transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path d="M1537 4250 c-109 -28 -194 -111 -230 -224 l-22 -71 0 -1440 0 -1440 23 -58 c32 -79 78 -135 138 -169 139 -78 316 -51 420 65 41 46 84 136 84 177 0 30 11 25 65 -30 62 -63 173 -140 250 -175 237 -104 553 -108 795 -8 391 161 627 543 671 1086 20 243 -5 470 -77 687 -171 515 -626 781 -1179 689 -147 -24 -269 -80 -414 -189 -51 -39 -95 -70 -97 -70 -2 0 -4 188 -4 419 0 239 -4 441 -10 471 -23 121 -89 212 -184 256 -59 27 -171 38 -229 24z m1124 -1480 c77 -24 107 -43 177 -112 63 -62 122 -174 154 -293 19 -69 21 -107 22 -280 0 -175 -3 -210 -22 -280 -50 -183 -151 -319 -288 -386 -117 -58 -284 -52 -416 14 -127 62 -223 193 -270 366 -18 65 -21 104 -21 266 0 164 3 201 21 270 62 229 202 393 372 439 80 21 195 20 271 -4z" />
      </g>
    </svg>
  );
};

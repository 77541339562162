import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { AnyFunction } from '@typings';
import { toast } from '@features';
import { verifyEmail } from '@services';
import { setUser } from '@slices';
import { useDispatch } from '@hooks';
import { noop, setFormBackendErrors, zodErrorMessage } from '@utils';

import { Button, Field, Render } from '@components';

type Schema = z.infer<typeof schema>;

const schema = z.object({
  code: z.string().min(1, zodErrorMessage('Code is required')),
});

type Props = {
  email: string;
  token: string;
  onBack?: () => void;
  onSuccess?: AnyFunction;
};

export const EmailVerificationCodeModalContent = ({
  email,
  token,
  onBack,
  onSuccess = noop,
}: Props) => {
  const dispatch = useDispatch();

  const { control, handleSubmit, setError, getValues } = useForm<Schema>({
    resolver: zodResolver(schema),
  });

  const [loading, setLoading] = useState(false);

  const handleCodeSubmit = handleSubmit(async ({ code }) => {
    try {
      setLoading(true);

      const user = await verifyEmail({ code, token });

      dispatch(setUser(user));

      toast.success('Email is confirmed!');
      onSuccess();
    } catch (error) {
      setFormBackendErrors({ error, setError, getValues });
    } finally {
      setLoading(false);
    }
  });

  return (
    <form onSubmit={handleCodeSubmit}>
      <p className="mb-6 text-16 text-eva">
        Enter the code we sent you to <b>{email}</b>
      </p>
      <Controller
        control={control}
        name="code"
        render={({ field: { onChange }, fieldState: { error } }) => {
          const fieldError = error?.message;

          return (
            <>
              <Field.OtpInput
                length={6}
                error={fieldError}
                onChange={(value) => onChange({ target: { value } })}
              />
              <Field.Error>{fieldError}</Field.Error>
            </>
          );
        }}
      />
      <Button
        loading={loading}
        type="submit"
        variant="hannah"
        className="mt-6 w-full"
      >
        Confirm
      </Button>
      <Render if={onBack}>
        <Button
          variant="ghost-secondary"
          className="mx-auto mt-2 flex"
          onClick={onBack}
        >
          Back
        </Button>
      </Render>
    </form>
  );
};

import { tripSelector } from '@selectors';
import { useSelector } from '@hooks';
import { tripRules } from '@content';

import { TripRule } from './TripRule';

type Rule = typeof tripRules.baggage;

export const TripRules = () => {
  const { rules: state } = useSelector(tripSelector);

  const makeItem = ({ title, rule, rules }: Rule) => (
    <TripRule
      key={rule}
      activeRule={state[rule]}
      rule={rule}
      rules={rules}
      title={title}
    />
  );

  return (
    <div className="flex basis-[560px] flex-col gap-6 md:basis-auto">
      <h4 className="text-center text-title-1 font-500">Add some trip rules</h4>
      <div className="grid grid-cols-2 gap-x-4 gap-y-3 sm:grid-cols-1">
        {Object.values(tripRules).map(makeItem)}
      </div>
    </div>
  );
};

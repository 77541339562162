import { useImmer } from 'use-immer';

import { AnyFunction, ModalProps } from '@typings';
import { userSelector } from '@selectors';
import { useSelector } from '@hooks';
import { noop } from '@utils';

import { Modal } from '@components';

import { EmailVerificationCodeModalContent } from './EmailVerificationCodeModalContent';
import { EmailVerificationEmailModalContent } from './EmailVerificationEmailModalContent';

enum Stage {
  Email = 'email',
  Code = 'code',
}

type State = {
  stage: Stage;
  email?: string;
  token?: string;
};

type Props = ModalProps & {
  token?: string;
  onSuccess?: AnyFunction;
};

export const EmailVerificationModal = ({
  token: tokenProp,
  onSuccess = noop,
  closeModal = noop,
}: Props) => {
  const { email: userEmail } = useSelector(userSelector);

  const [{ stage, email, token }, setState] = useImmer<State>({
    stage: tokenProp ? Stage.Code : Stage.Email,
    email: userEmail ?? undefined,
    token: tokenProp,
  });

  const setStage = (stage: Stage) => {
    setState((state) => {
      state.stage = stage;
    });
  };

  const handleEmailStageComplete = (email: string, token: string) => {
    setState({
      email,
      token,
      stage: Stage.Code,
    });
  };

  const handleVerificationSuccess = () => {
    closeModal();
    onSuccess();
  };

  const renderContent = () => {
    if (stage === Stage.Email) {
      return (
        <EmailVerificationEmailModalContent
          email={email}
          onSuccess={handleEmailStageComplete}
        />
      );
    }

    /**
     * `phone` and `token` exist on this stage
     */
    return (
      <EmailVerificationCodeModalContent
        email={email as string}
        token={token as string}
        onBack={() => setStage(Stage.Email)}
        onSuccess={handleVerificationSuccess}
      />
    );
  };

  return (
    <Modal.Content title="Email verification" className="w-[480px]">
      {renderContent()}
    </Modal.Content>
  );
};

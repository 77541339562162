import clsx from 'clsx';

import { setTripRule } from '@slices';
import { useDispatch } from '@hooks';
import { cn } from '@utils';
import { tripRules } from '@content';

import { Button, Icon } from '@components';

type Rules = typeof tripRules.baggage.rules;

type Props = {
  activeRule?: string;
  title: string;
  rule: string;
  rules: Rules;
};

export const TripRule = ({ activeRule, title, rule, rules }: Props) => {
  const dispatch = useDispatch();

  const handleChange = (value: string) => {
    dispatch(setTripRule({ name: rule, value }));
  };

  const makeItem = ({
    title,
    value,
    icon,
  }: (typeof tripRules.baggage.rules)[number]) => (
    <Button
      key={value}
      theme
      className={cn(
        'flex flex-1 flex-col items-center gap-2 text-caption-3 text-secondary transition-colors hover:text-primary',
        { 'text-primary': activeRule === value },
      )}
      onClick={() => handleChange(value)}
    >
      <Icon
        className={clsx('text-24', { 'text-hannah': activeRule === value })}
        icon={icon}
      />
      <p>{title}</p>
    </Button>
  );

  return (
    <div className="group rounded-2xl p-2 shadow-[0px_0px_5px_0px_#F2F2F2] transition-shadow hover:shadow-[0px_0px_5px_0px_#E1E1E5]">
      <p className="text-center text-footnote-large text-secondary transition-colors group-hover:text-primary">
        {title}
      </p>
      <div className="mt-4 flex justify-center gap-3">
        {rules.map(makeItem)}
      </div>
    </div>
  );
};

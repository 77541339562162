import { Loader } from '@googlemaps/js-api-loader';

import { GOOGLE_MAPS_API_KEY } from '@constants';

export const googlemaps = {
  /**
   * Determinants maps loading
   */
  loaded: false,

  async init() {
    if (!GOOGLE_MAPS_API_KEY) {
      /**
       * todo: resolve console
       */
      // eslint-disable-next-line no-console
      console.warn('No GOOGLE_MAPS_API_KEY provided');

      return;
    }

    try {
      const loader = new Loader({
        apiKey: GOOGLE_MAPS_API_KEY,
        version: 'weekly',
        language: 'en',
        region: 'CA',
        libraries: [
          'maps',
          'places',
          'routes',
          'geocoding',
          'marker',
          'geometry',
        ],
      });

      await loader.load();

      this.loaded = true;

      /**
       * todo: resolve console
       */
      // eslint-disable-next-line no-console
      console.log('Maps loaded');
    } catch (error) {
      /**
       * Continue regardless error
       */
      /**
       * todo: resolve console
       */
      // eslint-disable-next-line no-console
      console.log('Maps error');
      /**
       * todo: resolve console
       */
      // eslint-disable-next-line no-console
      console.log({ error });
    }
  },

  AutocompleteService() {
    if (!this.loaded) {
      /**
       * todo: resolve console
       */
      // eslint-disable-next-line no-console
      console.log('AutocompleteService this.loaded');

      return;
    }

    if (!google?.maps?.places?.AutocompleteService) {
      /**
       * todo: resolve console
       */
      // eslint-disable-next-line no-console
      console.log('google?.maps?.places?.AutocompleteService');

      return;
    }

    return new google.maps.places.AutocompleteService();
  },

  Geocoder() {
    if (!this.loaded) {
      /**
       * todo: resolve console
       */
      // eslint-disable-next-line no-console
      console.log('Geocoding this.loaded');

      return;
    }

    return new google.maps.Geocoder();
  },

  Map(ref: HTMLElement, options?: google.maps.MapOptions) {
    if (!this.loaded) {
      /**
       * todo: resolve console
       */
      // eslint-disable-next-line no-console
      console.log('Map this.loaded');

      return;
    }

    return new google.maps.Map(ref, options);
  },
};

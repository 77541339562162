import clsx from 'clsx';

import { tv } from '@utils';

import { Logo } from '@components/Ui';

const variants = tv({
  base: 'shrink-0 shadow-[2px_2px_12px_0px_rgba(0,0,0,0.25)] overflow-hidden text-hannah',
  variants: {
    shape: {
      circle: 'rounded-full',
      rectangle: 'rounded-2xl',
    },
  },
  defaultVariants: {
    shape: 'circle',
  },
});

type Props = {
  className?: string;
  iconClassName?: string;
  shape?: 'circle' | 'rectangle';
  src: string | null;
};

export const Avatar = ({ className, iconClassName, src, shape }: Props) => {
  const classNames = variants({ shape, className });

  if (src) {
    return (
      <img className={clsx('object-cover', classNames)} src={src} alt="" />
    );
  }

  return (
    <div className={clsx('flex items-center justify-center', classNames)}>
      <Logo.Icon className={iconClassName} />
    </div>
  );
};

import { Location } from '@typings';

type Options = {
  isDetailed?: boolean;
};

export const getFormattedPlace = (
  { city, state, address }: Location.Location,
  { isDetailed }: Options = {},
) => {
  const place = `${city}, ${state}`;

  return isDetailed && address ? `${address}, ${place}` : place;
};

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ModalProps } from '@typings';
import { PATH } from '@constants';
import { deleteAccount } from '@thunks';
import { useDispatch } from '@hooks';
import { noop, toastifyError } from '@utils';

import { Button, Modal } from '@components';

type Props = ModalProps;

export const DeleteAccountModal = ({ closeModal = noop }: Props) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    try {
      setLoading(true);

      await dispatch(deleteAccount());

      navigate(PATH.HOME);
      closeModal();
    } catch (error) {
      toastifyError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal.Content title="Delete account" className="w-[480px]">
      <p className="pt-4 text-center">
        Are you sure you want to delete this account?
      </p>
      <div className="mt-8 flex justify-end gap-4">
        <Button variant="ghost" className="px-10" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          variant="hannah"
          loading={loading}
          className="px-10"
          onClick={handleDelete}
        >
          Yes, I am sure
        </Button>
      </div>
    </Modal.Content>
  );
};

import { cn } from '@utils';

type Props = {
  className?: string;
  children?: React.ReactNode;
};

export const ThemeTripContainer = ({ className, children }: Props) => (
  <div className={cn('rounded-2xl bg-[#F5FBFF] px-7 py-3', className)}>
    {children}
  </div>
);

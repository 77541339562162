import { Outlet } from 'react-router-dom';

import { Layout } from '@components/Layouts';
import { UserProfile } from '@components/Ui';

/**
 * Profile layout
 */
export const ProfileLayout = () => {
  return (
    <Layout>
      <div className="flex items-start gap-14 py-6 md:flex-col md:items-center md:gap-4">
        <UserProfile />
        <div className="flex-1 md:w-full">
          <Outlet />
        </div>
      </div>
    </Layout>
  );
};

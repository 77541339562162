import { AnyFunction, ModalProps } from '@typings';
import { noop } from '@utils';

import { Button, Modal } from '@components';

type Props = ModalProps & {
  isBooking?: boolean;
  onCancel?: AnyFunction;
};

export const CancelTripModal = ({
  isBooking = false,
  onCancel = noop,
  closeModal = noop,
}: Props) => {
  const entry = isBooking ? 'passenger' : 'trip';

  const handleCancel = () => {
    onCancel();
    closeModal();
  };

  return (
    <Modal.Content title={`Cancel ${entry}`} className="w-[480px]">
      <p className="my-8 text-center">
        Are you sure you want to cancel this {entry}?
      </p>
      <div className="flex justify-end gap-4">
        <Button variant="ghost" onClick={closeModal}>
          Close
        </Button>
        <Button variant="hannah" onClick={handleCancel}>
          Cancel {entry}
        </Button>
      </div>
    </Modal.Content>
  );
};

import { Chat, Params } from '@typings';
import { axios } from '@features';
import { ConfigState, NotificationsState, UserState } from '@slices';

export const authInitialization = async () =>
  axios.get<
    never,
    {
      config: ConfigState;
      user: UserState;
      notifications: NotificationsState | null;
    }
  >('/init');

export const authLogin = (phone: string) =>
  axios.post<never, { verify: string }>('/login', { phone });

export const authVerifyCode = ({ token, code }: Params.VerifyCode) =>
  axios.post<
    never,
    { token: string; user: UserState; notifications: NotificationsState | null }
  >(`/phone/verify/${token}`, { code });

export const authLogout = () => axios.post('/logout');

export const updateProfile = (user: Params.UpdateProfile) => {
  return axios.postForm<never, UserState>('/profile', user);
};

export const addUserVehicle = (vehicle: Params.AddUserVehicle) =>
  axios.post<never, UserState>('/profile/vehicles', vehicle);

export const updateUserVehicle = ({
  id,
  ...vehicle
}: Params.UpdateUserVehicle) =>
  axios.put<never, UserState>(`/profile/vehicles/${id}`, vehicle);

export const getChats = () => {
  return axios.get<never, Chat.Model[]>('/messages');
};

export const sendChatMessage = ({
  tripId,
  ...payload
}: Params.SendChatMessage) => {
  return axios.post<never, Chat.Message>(`/messages/${tripId}`, payload);
};

export const readChatMessages = ({
  tripId,
  receiverId,
}: Params.ReadChatMessages) => {
  return axios.post<never, NotificationsState>(
    `/messages/${tripId}/${receiverId}/read`,
  );
};

export const getEmailVerificationToken = (email: string) =>
  axios.put<never, { verify: string }>('/profile/email', { email });

export const verifyEmail = ({ token, code }: Params.VerifyCode) => {
  return axios.post<never, UserState>(`/email/verify/${token}`, { code });
};

export const deleteAccount = () => {
  return axios.delete('/profile');
};

import { faMinus, faPlus } from '@fortawesome/pro-solid-svg-icons';

import { Trip } from '@typings';
import { setTripDetailsWaypoint } from '@slices';
import { tripSelector } from '@selectors';
import { useDispatch, useSelector } from '@hooks';

import { Button, Icon, Modal } from '@components';

export const TripWaypointPricesModal = () => {
  const OFFSET = 5;

  const dispatch = useDispatch();
  const {
    details: { waypoints },
  } = useSelector(tripSelector);

  const adjustPrice = (price: number, coefficient: number) =>
    Math.round((price * coefficient) / OFFSET) * OFFSET;

  const isMinimalPrice = (price: number) => price <= OFFSET;
  const isMaximalPrice = (price: number, recommendedPrice: number) =>
    price >= adjustPrice(recommendedPrice, 1.25);

  const handlePriceChange = ({
    price,
    diff,
    index,
  }: {
    price: number;
    diff: number;
    index: number;
  }) => {
    const offset = price % OFFSET;
    const concatination = diff > 0 ? OFFSET - offset : offset * -1;
    const isMultipleOf = offset === 0;

    const newPrice = isMultipleOf ? price + diff : price + concatination;

    dispatch(setTripDetailsWaypoint({ index, price: newPrice }));
  };

  const makeWaypoint = (
    {
      departure,
      price,
      origin,
      destination,
      recommendedPrice,
    }: Trip.WaypointDetails,
    index: number,
  ) => {
    return (
      <div
        key={departure}
        className="flex items-center justify-between sm:flex-col sm:gap-2"
      >
        <p className="text-footnote-large">
          {origin.city} - {destination.city}
        </p>
        <div className="flex items-center gap-3">
          <Button
            theme
            className="rounded-full bg-eva text-[11px] text-white transition-colors hover:bg-eva-dark disabled:bg-eva-light"
            disabled={isMinimalPrice(price)}
            onClick={() =>
              handlePriceChange({
                price,
                diff: -5,
                index,
              })
            }
          >
            <Icon className="h-5 w-5" icon={faMinus} />
          </Button>
          <div className="w-32 rounded-2xl bg-[#ECF8FF]">
            <p className="text-center text-20 text-primary">
              <span className="space-after text-[14px] text-[#7C909B]">
                CA$
              </span>
              <span className="font-600">{price}</span>
              <span className="text-[10px]">.00</span>
            </p>
          </div>
          <Button
            theme
            disabled={isMaximalPrice(price, recommendedPrice)}
            className="rounded-full bg-eva text-[11px] text-white transition-colors hover:bg-eva-dark disabled:bg-eva-light"
            onClick={() =>
              handlePriceChange({
                price,
                diff: 5,
                index,
              })
            }
          >
            <Icon className="h-5 w-5" icon={faPlus} />
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Modal.Content title="Waypoint prices" className="w-[400px]">
      <div className="flex flex-col gap-4">{waypoints.map(makeWaypoint)}</div>
    </Modal.Content>
  );
};

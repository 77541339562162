import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import dayjs, { Dayjs } from 'dayjs';
import { StringKeyOf } from 'type-fest';
import { useImmer } from 'use-immer';
import { z } from 'zod';
import { faLocationArrow } from '@fortawesome/pro-solid-svg-icons';

import { Location, Place, TripOutletContext } from '@typings';
import { DATE_FORMAT } from '@constants';
import { googlemaps } from '@features';
import { setTrip } from '@slices';
import { tripSelector } from '@selectors';
import { useDispatch, useSelector } from '@hooks';
import {
  formatAddressInput,
  formatToCamelCase,
  geocode,
  normalizeFormErrors,
  toastifyError,
} from '@utils';

import { Button, Field, Icon, Modal } from '@components';
import { TripLayout } from '@components/Layouts';
import {
  GoogleMapModal,
  InputDatePicker,
  PlacePredictions,
} from '@components/Ui';

type PredictionsImmer = {
  origin: Location.PlacePrediction[];
  destination: Location.PlacePrediction[];
};
type PredictionKeys = StringKeyOf<PredictionsImmer>;

type InputsImmer = {
  origin: string;
  destination: string;
};

type Schema = z.infer<typeof schema>;

const placeObject = z.object({
  placeId: z.string(),
  state: z.string(),
  city: z.string(),
  address: z.string().optional(),
  geo: z.object({
    lat: z.number(),
    lng: z.number(),
  }),
  formattedAddress: z.string(),
});

const schema = z
  .object({
    origin: placeObject,
    destination: placeObject,
    date: z.date().refine((value) => {
      const date = dayjs(value);

      if (!date.isValid()) {
        return false;
      }

      return !date.isBefore(dayjs(), 'day');
    }),
    time: z.date(),
  })
  .superRefine(({ date: dateString, time: timeString }, context) => {
    const time = dayjs(timeString);
    const date = dayjs(dateString).hour(time.hour()).minute(time.minute());
    const isDateValid = date.isValid() && date.isAfter(dayjs());

    if (!isDateValid) {
      context.addIssue({
        code: z.ZodIssueCode.invalid_date,
        message: `Invalid time`,
        path: ['time'],
      });

      return z.NEVER;
    }
  });

export const TripDepartureDatePage = () => {
  const dispatch = useDispatch();
  const { origin, destination, departure } = useSelector(tripSelector);

  const date = departure ? dayjs(departure).toDate() : undefined;

  const {
    control,
    register,
    formState,
    handleSubmit,
    setValue,
    watch,
    resetField,
  } = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: { origin, destination, date, time: date },
  });
  const { goNext } = useOutletContext<TripOutletContext>();

  const [service, setService] =
    useState<google.maps.places.AutocompleteService>();
  const [
    { origin: originPredictions, destination: destinationPredictions },
    setPredictions,
  ] = useImmer<PredictionsImmer>({
    origin: [],
    destination: [],
  });
  const [inputs, setInputs] = useImmer<InputsImmer>({
    origin: origin?.formattedAddress ?? '',
    destination: destination?.formattedAddress ?? '',
  });

  const [originWatcher, destinationWatcher] = watch(['origin', 'destination']);
  const errors = normalizeFormErrors<keyof Schema>(formState.errors);

  const setPredictionsFor = useCallback(
    (name: PredictionKeys, predictions: Location.PlacePrediction[]) => {
      setPredictions((state) => {
        state[name] = predictions;
      });
    },
    [setPredictions],
  );

  const resetPredictionsFor = (name: PredictionKeys) => {
    setPredictionsFor(name, []);
  };

  const resetValue = (name: PredictionKeys) => {
    resetField(name, { defaultValue: undefined });
  };

  const getPlacePredictions = async (name: PredictionKeys, input: string) => {
    if (!service) {
      /**
       * todo: resolve console
       */
      // eslint-disable-next-line no-console
      console.log('No service');

      return;
    }

    try {
      if (input) {
        const { predictions } = await service.getPlacePredictions({
          input,
          language: 'en',
          region: 'CA',
          types: ['geocode'],
          componentRestrictions: { country: 'CA' },
        });

        setPredictionsFor(
          name,
          formatToCamelCase(predictions) as Location.PlacePrediction[],
        );

        return predictions;
      }
    } catch (error) {
      toastifyError(error);
    }
  };

  const setInput = (name: PredictionKeys, value: string) => {
    setInputs((state) => {
      state[name] = value;
    });
  };

  const curryInputChange =
    (name: PredictionKeys) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = formatAddressInput(event.target.value);

      if (value.length > 1) {
        getPlacePredictions(name, value);
      } else {
        resetPredictionsFor(name);
        resetValue(name);
      }

      setInput(name, value);
    };

  useEffect(() => {
    register('origin');
    register('destination');
  }, [register]);

  useEffect(() => {
    const service = googlemaps.AutocompleteService();

    if (service) {
      setService(service);
    }
  }, []);

  const buttonClassName =
    'bg-hannah text-white rounded-full text-caption-2 flex-1 transition-colors hover:bg-hannah-dark';
  const locationButton = (
    <Button
      className="absolute right-4 top-4 m-auto h-6 w-6 bg-hannah p-0 text-15 text-white"
      slot="icon"
    >
      <Icon icon={faLocationArrow} />
    </Button>
  );

  const handleDateSet = (date: Dayjs) => {
    const formattedDate = date.toDate();

    setValue('date', formattedDate);
  };

  const handleTimeSet = (hour: number) => {
    const formattedTime = dayjs().hour(hour).minute(0).toDate();

    setValue('time', formattedTime);
  };

  const handleNextSubmit = handleSubmit(
    ({ origin, destination, date: dateAsDate, time: timeAsDate }) => {
      const date = dayjs(dateAsDate).format(DATE_FORMAT);
      const time = dayjs(timeAsDate).format('h:mm A');
      const departure = `${date} ${time}`;

      dispatch(setTrip({ origin, destination, departure }));

      goNext();
    },
  );

  const curryPredictionChoose =
    (name: PredictionKeys) =>
    async ({ placeId, description }: Location.PlacePrediction) => {
      resetPredictionsFor(name);
      setInput(name, description);

      try {
        const place = await geocode({ placeId });

        if (place) {
          setValue(name, place);
        }
      } catch (error) {
        /**
         * Conrinue regardless error
         */
      }
    };

  const curryMapPlaceApply = (name: PredictionKeys) => (place: Place) => {
    setInput(name, place.formattedAddress);
    setValue(name, place);
  };

  return (
    <form className="mt-4 flex w-[560px] flex-col gap-9 sm:w-full">
      <div className="relative">
        <Field.Input
          value={inputs.origin}
          error={errors.origin}
          label="Leaving from"
          onChange={curryInputChange('origin')}
        >
          <Modal
            slot="icon"
            content={
              <GoogleMapModal
                {...originWatcher}
                title="Origin map"
                onApply={curryMapPlaceApply('origin')}
              />
            }
          >
            {locationButton}
          </Modal>
        </Field.Input>
        <PlacePredictions
          className="top-10"
          predictions={originPredictions}
          onClick={curryPredictionChoose('origin')}
        />
      </div>
      <div className="flex gap-8">
        <div className="flex w-44 flex-col gap-1.5">
          <Controller
            name="date"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <Field.Container>
                  <InputDatePicker
                    value={value}
                    variant="react-datepicker-departure-date"
                    placeholder="Date"
                    onValueChange={(value) => onChange({ target: { value } })}
                  />
                  <Field.Error>{error?.message}</Field.Error>
                </Field.Container>
              );
            }}
          />
          <div className="flex gap-1.5">
            <Button
              theme
              className={buttonClassName}
              onClick={() => handleDateSet(dayjs())}
            >
              Today
            </Button>
            <Button
              theme
              className={buttonClassName}
              onClick={() => handleDateSet(dayjs().add(1, 'day'))}
            >
              Tomorrow
            </Button>
          </div>
        </div>
        <div className="flex w-44 flex-col gap-1.5">
          <Controller
            name="time"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <Field.Container>
                  <InputDatePicker
                    placeholder="Time"
                    showTimeSelect
                    showTimeSelectOnly
                    minDate={null}
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    value={value}
                    variant="react-datepicker-departure-date"
                    onValueChange={(value) => onChange({ target: { value } })}
                  />
                  <Field.Error>{error?.message}</Field.Error>
                </Field.Container>
              );
            }}
          />
          <div className="flex gap-1.5">
            <Button
              theme
              className={buttonClassName}
              onClick={() => handleTimeSet(8)}
            >
              08:00 AM
            </Button>
            <Button
              theme
              className={buttonClassName}
              onClick={() => handleTimeSet(11)}
            >
              11:00 AM
            </Button>
            <Button
              theme
              className={buttonClassName}
              onClick={() => handleTimeSet(17)}
            >
              05:00 PM
            </Button>
          </div>
        </div>
      </div>
      <div className="relative">
        <Field.Input
          value={inputs.destination}
          onChange={curryInputChange('destination')}
          error={errors.destination}
          label="Going to"
        >
          <Modal
            slot="icon"
            content={
              <GoogleMapModal
                {...destinationWatcher}
                title="Destination map"
                onApply={curryMapPlaceApply('destination')}
              />
            }
          >
            {locationButton}
          </Modal>
        </Field.Input>
        <PlacePredictions
          className="top-10"
          predictions={destinationPredictions}
          onClick={curryPredictionChoose('destination')}
        />
      </div>
      <TripLayout.Handler title="Make a Trip" onHandle={handleNextSubmit} />
    </form>
  );
};

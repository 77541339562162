import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import dayjs from 'dayjs';
import { z } from 'zod';
import { faImageUser } from '@fortawesome/pro-light-svg-icons';
import clsx from 'clsx';

import { ModalProps } from '@typings';
import { PHOTO_MIMETYPES } from '@constants';
import { toast } from '@features';
import { getEmailVerificationToken, getVehicles } from '@services';
import { addUserVehicle, updateProfile } from '@thunks';
import { userVehicleSelector } from '@selectors';
import { useDispatch, useSelector } from '@hooks';
import {
  as,
  cn,
  getFileForSubmit,
  getFileImage,
  noop,
  normalizeFormErrors,
  setFormBackendErrors,
  toastifyError,
  zodErrorMessage,
} from '@utils';
import { birthdayOption } from '@content';

import { Avatar, Button, Field, Icon, Modal, Render } from '@components';

type Vehicles = {
  [key: string]: string[];
};

/**
 * todo: think about moving it to content
 */
const colors = [
  'Black',
  'White',
  'Grey',
  'Silver',
  'Blue',
  'Red',
  'Brown',
  'Gold',
  'Green',
  'Tan',
  'Orange',
];

type Schema = z.infer<typeof schema>;

const schema = z.object({
  firstName: z.string().min(1, zodErrorMessage('First name is required')),
  lastName: z.string(),
  avatar: z
    .union([z.instanceof(FileList), z.string(), z.null()])
    .refine((avatar) => {
      if (!avatar) {
        return false;
      }

      if (avatar instanceof FileList) {
        return !!avatar.length;
      }

      return !!avatar;
    }, zodErrorMessage('Photo is required')),
  birthday: z
    .object({
      day: z
        .string()
        .min(1, zodErrorMessage('Date is required', 'birthday'))
        .max(2, zodErrorMessage('Day is invalid')),
      month: z
        .string()
        .min(1, zodErrorMessage('Month is required'))
        .max(2, zodErrorMessage('Month is invalid')),
      year: z
        .string()
        .min(1, zodErrorMessage('Year is required'))
        .min(4, zodErrorMessage('Year is invalid'))
        .max(4, zodErrorMessage('Year is invalid')),
    })
    .refine(
      ({ day, month, year }) => {
        const date = `${month}.${day}.${year}`;
        const isValid = dayjs(date, 'MM.DD.YYYY', true).isValid();

        return isValid;
      },
      zodErrorMessage('Date of birth is invalid', 'birthday'),
    )
    .refine(
      ({ day, month, year }) => {
        const date = `${month}.${day}.${year}`;
        const birthday = dayjs(date, 'MM.DD.YYYY');
        const now = dayjs();
        const isAdult = now.diff(birthday, 'year') >= 18;

        return isAdult;
      },
      zodErrorMessage('You must be 18 years of age or older', 'birthday'),
    ),
  email: z.string().min(1, zodErrorMessage('Email is required')).email(),
  make: z.string().min(1),
  model: z.string().min(1),
  color: z.string().min(1),
  plate: z
    .string()
    .transform((plate) =>
      plate
        .trim()
        /**
         * Drops multiple spaces in the middle of a string
         */
        .replaceAll(/\s\s+/g, ' ')
        .toUpperCase(),
    )
    .refine((value) => /^[0-9a-zA-Z\- ]+$/.test(value)),
});

type Props = ModalProps & {
  onSuccess?: (token: string | null | undefined) => void;
};

export const UserFillingModal = ({ onSuccess = noop }: Props) => {
  const dispatch = useDispatch();
  const vehicle = useSelector(userVehicleSelector);

  const {
    watch,
    register,
    formState,
    handleSubmit,
    getValues,
    setError,
    setValue,
  } = useForm<Schema>({
    resolver: zodResolver(schema),
  });

  const [loading, setLoading] = useState(false);
  const [vehicles, setVehicles] = useState<Vehicles>({});

  const errors = normalizeFormErrors<keyof Schema>(formState.errors);
  const watchAvatar = watch('avatar');
  const watchMake = watch('make');
  const models = as.a<string[]>(vehicles[watchMake]);
  const avatar = getFileImage(watchAvatar);
  const birthdayErrors = normalizeFormErrors<
    keyof Schema['birthday'] | 'birthday'
  >(
    /**
     * todo: resolve type
     */
    // @ts-ignore
    formState.errors.birthday,
  );

  useEffect(() => {
    const run = async () => {
      try {
        const vehicles = await getVehicles();

        setVehicles(vehicles);

        const make = Object.keys(vehicles)[0];

        setValue('make', make);
      } catch (error) {
        toastifyError(error);
      }
    };

    run();
  }, [vehicle, setValue]);

  useEffect(() => {
    setValue('model', models[0]);
  }, [models, setValue]);

  const handleFormSubmit = handleSubmit(
    async ({
      firstName,
      lastName,
      avatar,
      email,
      birthday: { day, month, year },
      make,
      model,
      color,
      plate,
    }) => {
      try {
        setLoading(true);

        const dateOfBirth = `${month}.${day}.${year}`;

        await Promise.all([
          await dispatch(
            updateProfile({
              email,
              firstName,
              lastName,
              dateOfBirth,
              avatar: getFileForSubmit(avatar),
            }),
          ),
          dispatch(addUserVehicle({ make, model, color, plate })),
        ]);

        const { verify: token } = await getEmailVerificationToken(email);

        toast.success('Your profile has been successfully updated');
        onSuccess(token);

        /**
         * Prevent modal closing
         */
      } catch (error) {
        setFormBackendErrors({
          error,
          getValues,
          setError,
          replace: [['dateOfBirth', 'birthday.birthday']],
        });
      } finally {
        setLoading(false);
      }
    },
  );

  /**
   * Class names for inputs
   */
  const cx = (className?: string, error?: string | boolean): string =>
    clsx('relative focus:z-20', className, { 'z-10': error });

  const makeOption = (value: string) => (
    <option key={value.toLowerCase()}>{value}</option>
  );

  const renderFootnote = (
    title: string,
    ...errors: Array<string | undefined>
  ) => {
    const error = errors.find((error) => error);

    if (error) {
      return <Field.Error>{error}</Field.Error>;
    }

    return <Field.Note>{title}</Field.Note>;
  };

  return (
    <Modal.Content title="Profile" className="w-[640px]">
      <form onSubmit={handleFormSubmit}>
        <div className="flex [&>*]:flex-1">
          <Field.Input
            {...register('firstName')}
            showError={false}
            label="First name"
            className={cx('rounded-r-none capitalize', errors.firstName)}
            error={errors.firstName}
          />
          <Field.Input
            {...register('lastName')}
            showError={false}
            label="Last name"
            className={cx('-ml-px rounded-l-none capitalize', errors.lastName)}
            error={errors.lastName}
          />
        </div>
        {renderFootnote(
          'Make sure it matches the name on your government ID',
          errors.firstName,
          errors.lastName,
        )}
        <div className="mt-6 flex [&>*]:flex-1">
          <Field.Select
            {...register('birthday.year')}
            showError={false}
            label="Year"
            className={cx('rounded-r-none w-full', birthdayErrors.year)}
            options={birthdayOption.years}
            error={birthdayErrors.year}
          />
          <Field.Select
            {...register('birthday.month')}
            showError={false}
            label="Month"
            className={cx('rounded-none -ml-px w-full', birthdayErrors.month)}
            options={birthdayOption.months}
            error={birthdayErrors.month}
          />
          <Field.Select
            {...register('birthday.day')}
            showError={false}
            label="Day"
            className={cx(
              'rounded-l-none -ml-0.5 w-auto w-full',
              birthdayErrors.day,
            )}
            options={birthdayOption.days}
            error={birthdayErrors.day}
          />
        </div>
        {renderFootnote(
          "To continue, you need to be at least 18 years old. Your birthday won't be shared with other people who use Beeple",
          errors.birthday,
          birthdayErrors.day,
          birthdayErrors.month,
          birthdayErrors.year,
          birthdayErrors.birthday,
        )}
        <Field.Input
          {...register('email')}
          label="Email"
          type="email"
          inputMode="email"
          note="We'll email you trip confirmations and receipts"
          className="mt-6"
          error={errors.email}
        />
        <Field.File
          {...register('avatar')}
          accept={PHOTO_MIMETYPES}
          className={cn(
            'group relative mt-4 flex h-[100px] w-full rounded-2xl border bg-[#7c909b]/10 text-secondary transition-colors hover:border-primary',
            { 'border-error': errors.avatar },
          )}
        >
          <Button theme className="pointer-events-none w-full">
            <Render if={avatar}>
              <Avatar
                shape="rectangle"
                src={avatar}
                className="pointer-events-none absolute inset-y-0 left-3 m-auto h-20 w-20"
              />
            </Render>
            <p className="flex items-center justify-center gap-2 text-14 transition-colors group-hover:text-primary">
              <Icon icon={faImageUser} className="text-24" />
              {avatar ? 'Change' : 'Upload'} photo
            </p>
          </Button>
        </Field.File>
        <Field.Error variant="static">{errors.avatar}</Field.Error>
        <div className="mt-4 flex flex-col gap-4">
          <Field.Select
            {...register('make')}
            required
            label="Make"
            error={errors.make}
          >
            {Object.keys(vehicles).map(makeOption)}
          </Field.Select>
          <Field.Select
            {...register('model')}
            required
            label="Model"
            error={errors.model}
          >
            {models.map(makeOption)}
          </Field.Select>
          <Field.Select
            {...register('color')}
            required
            label="Color"
            error={errors.color}
          >
            {colors.map(makeOption)}
          </Field.Select>
          <Field.Input
            {...register('plate')}
            required
            label="Plate"
            note="The licence plate has to be issued and valid in Canada"
            error={errors.plate}
          />
        </div>
        <Button
          loading={loading}
          type="submit"
          variant="hannah"
          className="mt-6 w-full"
        >
          Continue
        </Button>
      </form>
    </Modal.Content>
  );
};

import {
  faAddressCard,
  faSteeringWheel,
} from '@fortawesome/pro-light-svg-icons';

import {
  notificationsSelector,
  userSelector,
  userVehicleSelector,
} from '@selectors';
import { useSelector } from '@hooks';
import { as, getFullName } from '@utils';
import { profileNavigation } from '@content';

import { Button, Icon, Modal, Theme } from '@components';
import {
  ProfileNavigator,
  UpdateProfileModal,
  UpdateVehicleModal,
} from '@components/Ui';

type Item = {
  title: string;
  value: string | null;
};

export const ProfilePage = () => {
  const { lastName, firstName, dateOfBirth, email, phone } =
    useSelector(userSelector);
  const {
    id: vehicleId,
    color,
    make,
    model,
    plate,
  } = useSelector(userVehicleSelector);
  const notifications = useSelector(notificationsSelector);

  const personal: Item[] = [
    { title: 'Phone number', value: phone },
    { title: 'Name', value: getFullName(firstName, lastName) },
    { title: 'Birthday', value: dateOfBirth },
    { title: 'Email', value: email },
  ];

  const car: Item[] = [
    { title: 'Car make', value: make },
    { title: 'Car model', value: model },
    { title: 'Car color', value: color },
    { title: 'Valid Licence Plate', value: plate },
  ];

  const makeNavigator = ({
    notification,
    ...navigator
  }: (typeof profileNavigation)[number]) => {
    /**
     * Setting `null` to hide `0` value
     */
    // @ts-expect-error todo: resolve type
    const notificationsCounts = notifications[notification] || null;

    return (
      <ProfileNavigator
        {...navigator}
        key={navigator.title}
        notificationCount={notificationsCounts}
      />
    );
  };

  const makeTableRow = ({ title, value }: Item) => (
    <tr key={title}>
      <td className="pr-6 text-14 text-secondary">{title}:</td>
      <td className="text-14 font-600">{as(value, '-')}</td>
    </tr>
  );

  const renderTable = (data: Item[]) => {
    return (
      <table className="border-separate border-spacing-4">
        <tbody>{data.map(makeTableRow)}</tbody>
      </table>
    );
  };

  return (
    <div className="flex flex-col gap-4">
      <Theme.Container className="p-5">
        <p className="flex items-center justify-between text-body-1-s">
          Personal information
          <Modal content={<UpdateProfileModal />}>
            <Button
              theme
              className="ml-4 mr-auto flex h-6 w-11 items-center justify-center rounded-[4px] bg-neural-01 p-0 text-10 transition-colors hover:bg-hannah hover:text-white"
            >
              Edit
            </Button>
          </Modal>
          <Icon icon={faAddressCard} className="h-8 w-8 text-24 text-hannah" />
        </p>
        {renderTable(personal)}
        <div className="flex justify-end" />
      </Theme.Container>

      <Theme.Container className="p-5">
        <p className="flex items-center justify-between text-body-1-s">
          Driver’s information
          <Modal content={<UpdateVehicleModal />}>
            <Button
              theme
              className="ml-4 mr-auto flex h-6 w-11 items-center justify-center rounded-[4px] bg-neural-01 p-0 text-10 transition-colors hover:bg-hannah hover:text-white"
            >
              {vehicleId ? 'Edit' : 'Add'}
            </Button>
          </Modal>
          <Icon
            icon={faSteeringWheel}
            className="h-8 w-8 text-24 text-hannah"
          />
        </p>
        {renderTable(car)}
        <div className="flex justify-end" />
      </Theme.Container>

      <div className="grid auto-rows-fr grid-cols-3 gap-x-4 gap-y-3 sm:grid-cols-1">
        {profileNavigation.map(makeNavigator)}
      </div>
    </div>
  );
};

import { createSelector } from 'reselect';

import { State } from '@typings';

export const cacheSelector = (state: State) => state.cache;

export const notificationsSelector = createSelector(
  cacheSelector,
  ({ notifications }) => notifications,
);

import { useEffect, useState } from 'react';

import { localStorage } from '@utils';

import { Button } from '@components/Shared';
import cookiesImage from '@images/cookies.png';

const COOKIES = 'cookies-agreed';

export const Cookies = () => {
  const [cookiesAgreed, setCookiesAgreed] = useState(true);

  useEffect(() => {
    const cookiesAgreed = localStorage.get<boolean>(COOKIES);

    if (!cookiesAgreed) {
      setCookiesAgreed(false);
    }
  }, []);

  if (cookiesAgreed) {
    return null;
  }

  const handleCookiesConfirm = () => {
    localStorage.set<boolean>(COOKIES, true);
    setCookiesAgreed(true);
  };

  return (
    <div className="fixed inset-x-0 bottom-14 z-50 mx-auto flex w-[420px] flex-col gap-8 rounded-3xl bg-white px-14 py-9 shadow-[0px_0px_4px_0px_#DCDCDC] animate-in fade-in-50 slide-in-from-top-2">
      <img src={cookiesImage} alt="Cookies" className="mx-auto w-[75px]" />
      <p className="text-center text-16 font-600 text-primary">
        We use cookies for improving user experience, analytics and marketing
      </p>
      <Button
        variant="hannah"
        className="uppercase"
        onClick={handleCookiesConfirm}
      >
        Ok
      </Button>
    </div>
  );
};

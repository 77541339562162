import { useState } from 'react';
import OtpInput, { AllowedInputTypes, OTPInputProps } from 'react-otp-input';

import { noop } from '@utils';
import { fieldVariants } from '@utils/variants';

type Props = {
  containerClassName?: string;
  defaultValue?: string;
  type?: AllowedInputTypes;
  placeholder?: string;
  length?: number;
  error?: string;
  onChange?: (value: string) => void;
};

/**
 * Controlled component only
 */
export const FieldOtpInput = ({
  defaultValue = '',
  placeholder,
  type = 'number',
  length = 4,
  error,
  onChange = noop,
}: Props) => {
  const [value, setValue] = useState<string>(defaultValue);

  const handleChange = (value: string) => {
    setValue(value);
    onChange(value);
  };

  const handlePaste: OTPInputProps['onPaste'] = (event) => {
    const value = event.clipboardData.getData('text');
    const formattedValue = value.replace(/[^0-9]/g, '').substring(0, length);

    handleChange(formattedValue);
  };

  return (
    <OtpInput
      value={value}
      onPaste={handlePaste}
      onChange={handleChange}
      placeholder={placeholder}
      numInputs={length}
      renderInput={(props) => <input {...props} />}
      inputType={type}
      containerStyle="justify-center gap-1.5"
      inputStyle={fieldVariants({
        label: false,
        error: !!error,
        className: 'basis-12 text-32 px-1',
      })}
    />
  );
};

FieldOtpInput.displayName = 'Field.OtpInput';

/**
 * todo: rename this provider as `TripsContext`
 */
import { createContext, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';

import { DateIsoFormat, Pagination, Trip } from '@typings';
import { DATE_FORMAT } from '@constants';
import { useSearchTrips } from '@hooks/useSearchTrips';
import { noop } from '@utils';

type Nullable<T> = T | null | undefined;

const parseDateParam = (date: string | DateIsoFormat) => {
  if (date === 'tomorrow') {
    return dayjs().add(1, 'day').format(DATE_FORMAT);
  }

  return dayjs(date).format(DATE_FORMAT);
};

export const TripsContext = createContext<{
  loading: boolean;
  isEmpty: boolean;
  isFetched: boolean;
  seats: number;
  sort?: string | null;
  params: {
    seats: number;
    date?: string | null;
    originPlaceId: string | null;
    destinationPlaceId: string | null;
  };
  pagination: Pagination.Payload;
  trips: Trip.Item[];
  setPagination: (pagination: Pagination.Payload) => void;
  getTrips: (
    options: {
      seats: Nullable<number>;
      date?: Nullable<string>;
      originPlaceId: Nullable<string>;
      destinationPlaceId: Nullable<string>;
    },
    params?: {
      page?: Pagination.Params['page'];
      sort?: string | null;
    },
  ) => void;
  onSeatsBook: (tripId: string, booking: Trip.Item['booking']) => void;
}>({
  loading: false,
  isFetched: false,
  isEmpty: false,
  seats: 1,
  trips: [],
  params: {
    seats: 0,
    date: null,
    originPlaceId: null,
    destinationPlaceId: null,
  },
  pagination: { total: 0, page: 1, next: null },
  getTrips: noop,
  setPagination: noop,
  onSeatsBook: noop,
});

type Props = {
  children?: React.ReactNode;
};

export const TripsProvider = ({ children }: Props) => {
  const [searchParams] = useSearchParams();

  const {
    loading,
    isEmpty,
    isFetched,
    pagination,
    trips,
    getTrips,
    setPagination,
    onSeatsBook,
  } = useSearchTrips();

  const seatsParam = searchParams.get('seats');
  const dateParam = searchParams.get('date');
  const originPlaceIdParam = searchParams.get('originPlaceId');
  const destinationPlaceIdParam = searchParams.get('destinationPlaceId');
  const sortParam = searchParams.get('sort');

  const seats = seatsParam ? Number(seatsParam) : 1;
  const params = useMemo(
    () => ({
      seats,
      date: dateParam,
      originPlaceId: originPlaceIdParam,
      destinationPlaceId: destinationPlaceIdParam,
    }),
    [dateParam, seats, originPlaceIdParam, destinationPlaceIdParam],
  );

  useEffect(() => {
    const date = dateParam ? parseDateParam(dateParam) : dateParam;

    getTrips({ ...params, date }, { sort: sortParam });
    /**
     * Fetch happens on intializayion only and
     * it must not trigger `getTrips` on params update
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = useMemo(
    () => ({
      loading,
      isEmpty,
      isFetched,
      seats,
      sort: sortParam,
      params,
      pagination,
      trips,
      setPagination,
      getTrips,
      onSeatsBook,
    }),
    [
      loading,
      isEmpty,
      isFetched,
      seats,
      params,
      sortParam,
      trips,
      pagination,
      getTrips,
      setPagination,
      onSeatsBook,
    ],
  );

  return (
    <TripsContext.Provider value={value}>{children}</TripsContext.Provider>
  );
};

import {
  AddressComponent,
  Location,
  Place,
  PlaceGeocodeResult,
  Primitive,
  Trip,
} from '@typings';
import { googlemaps } from '@features';
import { formatToCamelCase } from '@utils';

const concatenateAddress = (
  street: Primitive,
  address: Primitive,
): string | undefined => {
  if (!address) {
    return undefined;
  }

  if (street) {
    return `${street} ${address}`;
  }

  return address as string;
};

const parsePlaceAddress = (components: AddressComponent[]) =>
  components.reduce(
    (data, { longName, shortName, types }) => {
      if (types.includes('administrative_area_level_1')) {
        data.state = shortName.substring(0, 2);
      }

      if (types.includes('locality')) {
        data.city = longName;
      }

      if (types.includes('route')) {
        data.address = longName;
      }

      if (types.includes('street_number')) {
        data.street = longName;
      }

      return data;
    },
    {
      state: '',
      city: '',
    } as Pick<Trip.Location, 'state' | 'city' | 'address'> & {
      street?: string;
    },
  );

const removeCountryFromAddress = (
  formattedAddress: string,
  addressComponents: AddressComponent[],
) => {
  const countryComponent = addressComponents.find((component) =>
    component.types.includes('country'),
  );

  if (!countryComponent) {
    return formattedAddress;
  }

  const country = countryComponent.longName;
  const addressWithoutCountry = formattedAddress
    .replace(`, ${country}`, '')
    .replace(`${country}, `, '');

  return addressWithoutCountry;
};

type Options = {
  placeId?: Location.PlacePrediction['placeId'];
  geo?: Location.Geo | google.maps.LatLng;
};

export const geocode = async ({
  placeId,
  geo: geoLocation,
}: Options): Promise<Place | undefined> => {
  try {
    const { geocode } = googlemaps.Geocoder()!;
    const request = placeId ? { placeId } : { location: geoLocation };

    const { results } = await geocode({
      ...request,
      region: 'ca',
      language: 'en',
    });
    const place = results[0];

    if (!place) {
      throw new Error('Place geocoding failed');
    }

    const {
      addressComponents,
      formattedAddress,
      geometry: { location },
      placeId: geocodePlaceId,
    } = formatToCamelCase(place) as PlaceGeocodeResult;
    const placeAddress = parsePlaceAddress(addressComponents);

    const formattedAddressWithoutCountry = removeCountryFromAddress(
      formattedAddress,
      addressComponents,
    );
    const geo = {
      lat: location.lat(),
      lng: location.lng(),
    };

    placeAddress.address = concatenateAddress(
      placeAddress.street,
      placeAddress.address,
    );

    return {
      ...placeAddress,
      geo,
      placeId: geocodePlaceId,
      formattedAddress: formattedAddressWithoutCountry,
    };
  } catch (error) {
    /**
     * todo: fix console
     */
    // eslint-disable-next-line no-console
    console.log({ error });
  }
};

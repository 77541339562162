import { useRef, useState } from 'react';
import dayjs from 'dayjs';
import { KeysOfUnion } from 'type-fest';
import {
  faCaretRight,
  faDiamond,
  faUserVneck,
} from '@fortawesome/pro-solid-svg-icons';

import { Location, Trip } from '@typings';
import { PATH } from '@constants';
import { cancelTrip, updateBookingStatus } from '@services';
import { cn, isUserDeleted, noop, p, toastifyError, tv } from '@utils';
import { tripRules } from '@content';

import {
  Avatar,
  Button,
  Icon,
  Link,
  Modal,
  Render,
  Tooltip,
} from '@components';

import { CancelTripModal } from '../Modals';

const variants = tv({
  base: 'flex h-[18px] w-[66px] items-center justify-center rounded-full bg-primary text-10 font-500 text-white capitalize transition-colors sm:h-7 sm:text-12 sm:w-full',
  variants: {
    variant: {
      pending: 'bg-[#EAC916]',
      declined: 'bg-hannah',
      canceled: 'bg-hannah/75',
      cancel: 'bg-hannah hover:bg-hannah-dark',
      completed: 'bg-[#22AF27]/50',
      chat: 'bg-primary hover:bg-primary-dark',
      'chat-disabled': 'bg-primary/25',
      requests: 'bg-primary',
      approve: 'bg-[#22AF27]',
      default: 'bg-secondary',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

type Props = Trip.DriverItem & {
  onBookingChange?: (
    tripId: string,
    booking: Pick<Trip.PassengerItem, 'id' | 'passengers' | 'price' | 'status'>,
  ) => void;
  onTripCancel?: (tripId: string, requests: number) => void;
};

export const DriverTripCard = ({
  id: tripId,
  requests,
  seats,
  freeSeats,
  departure,
  arrival,
  price,
  destination,
  origin,
  vehicle: { make, model, color },
  rules,
  status,
  bookings,
  onBookingChange = noop,
  onTripCancel = noop,
}: Props) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [isCollapsed, setCollapse] = useState(true);

  /**
   * Includes booking request by passenger
   */
  const isRequested = bookings.length > 0;
  const totalPrice = price * seats;
  const maxHeight = isCollapsed ? 0 : contentRef.current?.scrollHeight;
  const isCompleted = status === 'completed';
  const isPending = status === 'pending';
  const isCanceled = status === 'canceled';

  const handleToggle = () => {
    if (!isRequested) {
      return;
    }

    setCollapse((state) => !state);
  };

  const handleStatusUpdate = async (
    tripId: string,
    bookingId: string,
    status: string,
  ) => {
    try {
      const booking = await updateBookingStatus({ tripId, bookingId, status });

      /**
       * `cardId` is made "driver trip card"
       */
      onBookingChange(tripId, booking);
    } catch (error) {
      toastifyError(error);
    }
  };

  const handleTripCancel = async () => {
    try {
      await cancelTrip({ tripId });

      onTripCancel(tripId, requests);
    } catch (error) {
      toastifyError(error);
    }
  };

  const getFormattedPlace = (
    { city, state, address }: Location.Location,
    isDetailed?: boolean,
  ) => {
    const place = `${city}, ${state}`;

    return isDetailed && address ? `${address}, ${place}` : place;
  };

  const makeRule = ([rule, value]: [string, string]) => {
    const currentRule = tripRules[
      rule as KeysOfUnion<typeof tripRules>
    ]?.rules.find((rule) => rule.value === value);

    if (!currentRule) {
      return null;
    }

    const { icon, title } = currentRule;

    return (
      <Tooltip key={rule}>
        <p slot="trigger" className="text-18 text-hannah">
          <Icon key={rule} icon={icon} className="h-5 w-5" />
        </p>
        <p>{title}</p>
      </Tooltip>
    );
  };

  const makeBooking = ({
    id: bookingId,
    passengers,
    status,
    trip: { id: bookingTripId, arrival, departure, origin, destination },
    user: { id: userId, firstName, avatar, deletedAt },
  }: Trip.DriverItem['bookings'][number]) => {
    const isPending = status === 'pending';
    const isCanceled = status === 'canceled';
    const isDeclined = status === 'declined';
    const isApproved = status === 'approved';
    const isCompleted = status === 'completed';

    return (
      <div
        key={bookingId}
        className={cn(
          'group relative flex w-full items-center gap-2 rounded-3xl bg-[#7C909B]/5 py-2 pl-4 pr-7 text-eva shadow-[0px_0px_8px_0px_#F4F4F4] md:p-4 sm:flex-wrap sm:justify-center',
          {
            'opacity-50 text-secondary': isDeclined,
            'bg-eva-light/25': isPending,
          },
        )}
      >
        <Link
          theme
          disabled={isUserDeleted(deletedAt)}
          to={p(PATH.USER, userId)}
          className="group/link flex shrink-0 basis-14 flex-col items-center justify-center gap-1.5 md:basis-10 sm:order-1 sm:basis-auto"
        >
          <Avatar
            src={avatar}
            className="h-9 w-9 text-18 transition-colors sm:h-14 sm:w-14"
          />
          <p className="text-8 font-500 transition-colors group-hover/link:text-hannah sm:text-12">
            {firstName}
          </p>
        </Link>
        <div className="flex-1 px-4 sm:order-2 sm:w-full sm:flex-none">
          <div className="flex flex-1 items-center text-12 font-500">
            <p>{origin.city}</p>
            <div className="mx-3 flex flex-1 items-center text-secondary-light">
              <Icon icon={faDiamond} className="contents shrink-0 text-5" />
              <div className="h-px flex-1 border-b border-dashed border-secondary-light" />
              <Icon icon={faCaretRight} className="contents shrink-0 text-10" />
            </div>
            <p>{destination.city}</p>
          </div>
          <p className="flex justify-between text-7 text-secondary">
            <span>
              <b>{dayjs(departure).format('MMM D, YYYY')}</b> /{' '}
              {dayjs(departure).format('h:mm A')}
            </span>
            <span>
              <b>{dayjs(arrival).format('MMM D, YYYY')}</b> /{' '}
              {dayjs(arrival).format('h:mm A')}
            </span>
          </p>
          <div className="mt-1.5 flex items-center justify-between">
            <div />
            <p className="flex items-center gap-1 text-13 font-500 leading-0 text-primary">
              <Icon icon={faUserVneck} className="h-4 w-4 text-12" /> x&nbsp;
              {passengers}
            </p>
          </div>
        </div>
        <div className="flex basis-32 flex-col items-end md:basis-24 sm:order-1 sm:items-center">
          <p className="font-500 leading-none">
            <span className="space-after text-13">CA$</span>
            <span className="text-19">{price * passengers}</span>
            <span className="text-11">.00</span>
          </p>
          <p className="text-7 font-500 text-secondary">
            (for {passengers} {passengers > 1 ? 'passengers' : 'passenger'})
          </p>
        </div>
        <div className="flex basis-28 flex-col items-end gap-1.5 md:basis-24 sm:order-3 sm:mt-4 sm:w-full sm:basis-auto">
          <Render if={isCompleted}>
            <p className={variants({ variant: 'completed' })}>Completed</p>
          </Render>
          <Render if={isCanceled}>
            <p className={variants({ variant: 'canceled' })}>Canceled</p>
          </Render>
          <Render if={isApproved}>
            <p className={variants({ variant: 'completed' })}>Approved</p>
          </Render>
          <Render if={isDeclined}>
            <p className={variants({ variant: 'declined' })}>Declined</p>
          </Render>
          <Render if={isPending}>
            <Button
              theme
              className={variants({ variant: 'approve' })}
              onClick={() =>
                handleStatusUpdate(bookingTripId, bookingId, 'approved')
              }
            >
              Approve
            </Button>
          </Render>
          <Render if={!isDeclined && !isCanceled}>
            <Link
              theme
              className={variants({ variant: 'chat' })}
              to={`${PATH.CHAT}/${bookingTripId}/${userId}`}
            >
              Chat
            </Link>
          </Render>
          <Render if={isCanceled}>
            <p className={variants({ variant: 'chat-disabled' })}>Chat</p>
          </Render>
          <Render if={isPending}>
            <Button
              theme
              className={variants({ variant: 'default' })}
              onClick={() =>
                handleStatusUpdate(bookingTripId, bookingId, 'declined')
              }
            >
              Decline
            </Button>
          </Render>
          <Render if={isApproved}>
            <Modal
              content={
                <CancelTripModal
                  isBooking
                  onCancel={() =>
                    handleStatusUpdate(bookingTripId, bookingId, 'canceled')
                  }
                />
              }
            >
              <Button theme className={variants({ variant: 'cancel' })}>
                Cancel
              </Button>
            </Modal>
          </Render>
        </div>
      </div>
    );
  };

  return (
    <div
      className={cn(
        'overflow-hidden rounded-3xl border border-transparent bg-white shadow-[2px_2px_12px_0px_rgba(0,0,0,0.10)] transition-all',
        {
          'hover:shadow-[0px_0px_8px_0px_rgba(0,0,0,0.2)]': isRequested,
          'opacity-50': !isPending,
        },
      )}
    >
      <Button
        theme
        onClick={handleToggle}
        className={cn(
          'group flex w-full flex-1 items-center gap-3 p-2 pl-4 pr-7 md:p-4 sm:flex-wrap sm:justify-center',
          { 'cursor-default': !isRequested },
        )}
      >
        <div className="flex basis-9 flex-col items-center justify-center sm:order-1 sm:basis-auto">
          <p className="text-24 font-600 text-hannah">{freeSeats}</p>
          <p className="text-8 font-700 text-primary">Available seats</p>
        </div>
        <div className="flex-1 self-stretch px-3 sm:order-2 sm:w-full sm:flex-none sm:px-0">
          <div className="flex flex-1 items-center text-12 font-500 text-eva">
            <p>{getFormattedPlace(origin)}</p>
            <div className="mx-3 flex flex-1 items-center text-secondary-light">
              <Icon icon={faDiamond} className="contents shrink-0 text-5" />
              <div className="h-px flex-1 border-b border-dashed border-secondary-light" />
              <Icon icon={faCaretRight} className="contents shrink-0 text-10" />
            </div>
            <p>{getFormattedPlace(destination)}</p>
          </div>
          <p className="flex justify-between text-7 text-secondary">
            <span>
              <b>{dayjs(departure).format('MMM D, YYYY')}</b> /{' '}
              {dayjs(departure).format('h:mm A')}
            </span>
            <span>
              <b>{dayjs(arrival).format('MMM D, YYYY')}</b> /{' '}
              {dayjs(arrival).format('h:mm A')}
            </span>
          </p>
          <div className="mt-1.5 flex items-center justify-between">
            <div className="flex gap-2">
              <Render if={rules}>{Object.entries(rules).map(makeRule)}</Render>
            </div>
            <p className="flex items-center gap-1 text-13 font-500 leading-0 text-hannah">
              <Icon icon={faUserVneck} className="h-4 w-4 text-12" /> x {seats}
            </p>
          </div>
          <p className="mt-2 text-left text-10 text-secondary">
            {make} {model} ({color})
          </p>
        </div>
        <div className="flex basis-32 flex-col items-end md:basis-24  sm:order-1 sm:basis-32 sm:items-center">
          <p className="font-500 text-eva">
            <span className="space-after text-15">CA$</span>
            <span className="text-20">{totalPrice}</span>
            <span className="text-10">.00</span>
          </p>
          <p className="text-7 font-500 text-secondary">
            (for {seats} {seats > 1 ? 'passengers' : 'passenger'})
          </p>
        </div>
        <div className="flex basis-28 flex-col items-end gap-1.5 md:basis-20 sm:order-3 sm:w-full sm:basis-auto sm:[&>*]:w-full">
          <Render if={isPending}>
            <p
              className={variants({
                variant: 'requests',
                className: 'relative',
              })}
            >
              Requests
              <Render if={requests > 0}>
                <span className="absolute right-0 top-0 flex h-4 w-4 -translate-y-1/2 translate-x-1/2 items-center justify-center rounded-full bg-[#F31212] text-11 font-700 text-white">
                  {requests}
                </span>
              </Render>
            </p>
          </Render>
          <Render if={isCompleted}>
            <p className={variants({ variant: 'completed' })}>Completed</p>
          </Render>
          <Render if={isCanceled}>
            <p className={variants({ variant: 'canceled' })}>Canceled</p>
          </Render>
          <Render if={!isCanceled && !isCompleted}>
            <Modal content={<CancelTripModal onCancel={handleTripCancel} />}>
              <Button
                theme
                stopPropagation
                className={variants({ variant: 'cancel' })}
              >
                Cancel
              </Button>
            </Modal>
          </Render>
        </div>
      </Button>
      <div
        ref={contentRef}
        style={{ maxHeight }}
        className="overflow-hidden transition-all duration-500"
      >
        {/* Collapse content, div is necessary */}
        <div className="flex flex-col gap-2 border-t border-[#F5FBFF] p-6 md:px-2">
          {bookings.map(makeBooking)}
        </div>
      </div>
    </div>
  );
};

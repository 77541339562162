import { ButtonHTMLAttributes, forwardRef } from 'react';
import clsx from 'clsx';

import { cn, VariantProps } from '@utils';
import { buttonVariants } from '@utils/variants';

import { ChaseSpinner } from './ChaseSpinner';

type Props = VariantProps<typeof buttonVariants> &
  ButtonHTMLAttributes<HTMLButtonElement> & {
    theme?: boolean;
    stopPropagation?: boolean;
    loading?: boolean;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => unknown;
  };

export const Button = forwardRef<HTMLButtonElement, Props>(
  (
    {
      children,
      theme,
      stopPropagation = true,
      loading = false,
      disabled = false,
      className,
      variant,
      type = 'button',
      onClick,
      ...props
    },
    ref,
  ) => {
    const isLoading = !theme && loading;

    const disabledClassName = clsx({
      'pointer-events-none': loading || disabled,
    });
    const classNames = theme
      ? clsx(className, disabledClassName)
      : cn(buttonVariants({ variant, className }), disabledClassName, {
          'text-transparent': loading,
        });

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      if (stopPropagation) {
        event.stopPropagation();
      }

      if (typeof onClick === 'function') {
        onClick(event);
      }
    };

    const getLoaderColor = () => {
      switch (variant) {
        case 'ghost':
          return 'primary';
        case 'ghost-error':
          return 'error';
        default:
          return 'white';
      }
    };

    return (
      <button
        {...props}
        // eslint-disable-next-line react/button-has-type
        type={type}
        disabled={disabled}
        className={classNames}
        onClick={handleClick}
        ref={ref}
      >
        {isLoading && (
          <ChaseSpinner
            className="absolute inset-0 m-auto transition-all"
            color={getLoaderColor()}
          />
        )}
        {children}
      </button>
    );
  },
);

Button.displayName = 'Button';

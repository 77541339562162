import { useEffect, useState } from 'react';

import { initialize } from '@thunks';
import { useDispatch } from '@hooks';

export const useInitialization = () => {
  const dispatch = useDispatch();
  const [isInitialized, setInitialization] = useState(false);
  const [isError, setError] = useState(false);

  useEffect(() => {
    const run = async () => {
      try {
        /**
         * Initializing `config` and `user`
         */
        await dispatch(initialize());
      } catch (error) {
        setError(true);
      } finally {
        setInitialization(true);
      }
    };

    run();
  }, [dispatch]);

  return { isInitialized, isError };
};

export const PACKAGE_VERSION = process.env.REACT_APP_PACKAGE_VERSION ?? '';
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN ?? '';
export const SERVER_URL = process.env.REACT_APP_SERVER_URL ?? '';

export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
export const IS_PRODUCTION = ENVIRONMENT === 'production';
export const IS_STAGING = ENVIRONMENT === 'staging';
export const IS_DEVELOPMENT = ENVIRONMENT === 'development';
export const IS_LOCAL = !IS_PRODUCTION && !IS_STAGING;

export const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;

export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
export const GOOGLE_MAPS_MAP_ID = process.env.REACT_APP_GOOGLE_MAPS_MAP_ID;

export const SERVER_API_URL = process.env.REACT_APP_SERVER_API_URL ?? '';

export const SUPPORT_EMAIL = 'support@beeple.ca';

import dayjs from 'dayjs';
import {
  faCircleCheck,
  faCircleXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { faPenToSquare } from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';

import { isEmailVerifiedSelector, userSelector } from '@selectors';
import { useSelector } from '@hooks';
import { as, getFullName } from '@utils';

import { Avatar, Button, Icon, Modal, Render, Theme } from '@components';
import {
  EmailVerificationModal,
  UpdateProfileAvatarModal,
  UpdateProfileModal,
} from '@components/Ui';

type Item = {
  title: string;
  value: string | number | null;
};

export const UserProfile = () => {
  const { firstName, lastName, avatar, createdAt, trips, bookings } =
    useSelector(userSelector);
  const isEmailVerified = useSelector(isEmailVerifiedSelector);

  const dateOfSignup = dayjs(createdAt);
  /**
   * We cannot update avatar if no profile is full,
   * because `POST updateProfile` requires full profile
   */
  const ModalContent = firstName
    ? UpdateProfileAvatarModal
    : UpdateProfileModal;

  const personal: Item[] = [
    {
      title: 'Date of registry',
      value: `${dateOfSignup.format('MMM DD, YYYY')} (${dateOfSignup.fromNow(
        true,
      )})`,
    },
    { title: 'Trips as a passenger', value: bookings },
    { title: 'Trips as a driver', value: trips },
  ];

  const makeItem = (text: string, isChecked: boolean = false) => {
    const icon = isChecked ? faCircleCheck : faCircleXmark;
    const color = isChecked ? 'text-[#22AF27]' : 'text-[#EAC916]';

    return (
      <div key={text} className="flex items-center gap-1.5">
        <Icon icon={icon} className={clsx('h-5 w-5 text-16', color)} />
        <p className="text-label-1">{text}</p>
      </div>
    );
  };

  const makeEntry = ({ title, value }: Item) => {
    return (
      <div key={title} className="flex w-full justify-between gap-2">
        <p className="whitespace-nowrap text-12 text-secondary">{title}:</p>
        <p className="text-right text-12 font-600">{as(value, '-')}</p>
      </div>
    );
  };

  return (
    <div className="flex basis-[302px] flex-col items-center gap-4 md:w-full">
      <div className="group relative">
        <Avatar
          src={avatar}
          className="h-32 w-32 text-48 text-hannah"
          iconClassName="w-28"
        />
        <Modal content={<ModalContent />}>
          <Button
            theme
            className="absolute inset-0 flex items-center justify-center rounded-full bg-primary/50 text-40 text-white opacity-0 transition-opacity group-hover:opacity-50"
          >
            <Icon icon={faPenToSquare} />
          </Button>
        </Modal>
      </div>
      <p className="text-h6">{getFullName(firstName, lastName, 'Guest')}</p>
      <Theme.Container className="flex w-full flex-col gap-3 p-4">
        <p className="mb-3 text-title-2">Verified information</p>
        {makeItem('Phone number', true)}
        <Render if={isEmailVerified}>{makeItem('Email', true)}</Render>
        <Render if={!isEmailVerified}>
          <div className="my-2 h-px bg-neural-01" />
          <Modal content={<EmailVerificationModal />}>
            <Button
              theme
              className="group flex cursor-pointer items-center justify-between"
            >
              {makeItem('Email', false)}
              <p className="text-10 font-600 text-primary transition-colors group-hover:text-[#22AF27]">
                Verify
              </p>
            </Button>
          </Modal>
        </Render>
      </Theme.Container>
      <Theme.Container className="flex w-full flex-col gap-3 p-4">
        {personal.map(makeEntry)}
      </Theme.Container>
    </div>
  );
};

import { PACKAGE_VERSION, PATH } from '@constants';
import { cn, getSlots } from '@utils';

import { Link, Render, Slot, Theme } from '@components';
import { Cookies, Logo } from '@components/Ui';

import { HeaderLayout } from './HeaderLayout';

type Props = {
  headerVariant?: 'with-border' | 'without-border';
  withFooter?: boolean;
  className?: string;
  children?: React.ReactNode;
};

export const Layout = ({
  className,
  headerVariant,
  withFooter = true,
  children,
}: Props) => {
  const { wrapper, content } = getSlots<'wrapper'>(children);

  return (
    /**
     * Flex column is for attach footer to bottom
     */
    <div className={cn('flex w-full flex-1 flex-col', className)}>
      <HeaderLayout variant={headerVariant} />
      <Slot slot={wrapper}>
        <Theme.Wrapper className="flex-1">{content}</Theme.Wrapper>
      </Slot>
      <Render if={withFooter}>
        <Theme.Wrapper className="flex flex-initial items-center justify-between py-6">
          <div className="flex items-center gap-1.5">
            <Logo className="h-5" />
            <p className="text-9 text-secondary">© 2024</p>
            <Render if={PACKAGE_VERSION}>
              <p className="text-9 text-secondary">· v{PACKAGE_VERSION}</p>
            </Render>
          </div>
          <div>
            <Link
              theme
              className="text-9 text-secondary transition-colors hover:text-hannah"
              to={PATH.TERMS_AND_CONDITIONS}
            >
              Terms & Conditions
            </Link>
          </div>
        </Theme.Wrapper>
      </Render>
      <Cookies />
    </div>
  );
};

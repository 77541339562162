import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/**
 * Nullable for unauthicated user
 */
export type NotificationsState = {
  trips: number;
  messages: number;
};

const initialState = {
  messages: 0,
  trips: 0,
};

export const notificationsSlice = createSlice({
  name: 'user-notifications',
  initialState,
  reducers: {
    setNotifications: (
      state,
      action: PayloadAction<Partial<NotificationsState>>,
    ) => {
      const fieldsToChange = action.payload;

      return { ...state, ...fieldsToChange };
    },
    decrementTripsNotifications: (
      state,
      action: PayloadAction<number | undefined>,
    ) => {
      const trips = action.payload ?? 1;

      state.trips -= trips;
    },
    resetNotifications: () => initialState,
  },
});

export const {
  setNotifications,
  decrementTripsNotifications,
  resetNotifications,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;

import { useNavigate } from 'react-router-dom';
import { faChevronCircleLeft } from '@fortawesome/pro-solid-svg-icons';

import { AnyFunction } from '@typings';

import { Button, Icon, Link } from '@components';

type Props = {
  className?: string;
  to?: string;
  onBack?: AnyFunction;
};

export const BackButton = ({ className, to, onBack }: Props) => {
  const navigate = useNavigate();

  const handleBack = () => navigate(-1);

  const icon = (
    <Icon
      icon={faChevronCircleLeft}
      className="text-20 text-[#7C909B] transition-colors hover:text-primary"
    />
  );

  if (to) {
    return (
      <Link theme className={className} to={to}>
        {icon}
      </Link>
    );
  }

  return (
    <Button theme className={className} onClick={onBack ?? handleBack}>
      {icon}
    </Button>
  );
};

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';

import { cn } from '@utils';

type Props = Omit<FontAwesomeIconProps, 'icon'> & {
  icon?: IconProp | null;
  className?: string;
  iconClassName?: string;
};

export const Icon = ({ className, iconClassName, icon, ...props }: Props) => {
  if (!icon) {
    return null;
  }

  return (
    <span
      className={cn(
        'flex h-6 w-6 shrink-0 items-center justify-center',
        className,
      )}
    >
      <FontAwesomeIcon {...props} icon={icon} className={iconClassName} />
    </span>
  );
};

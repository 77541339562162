import { useImmer } from 'use-immer';

import { AnyFunction, ModalProps } from '@typings';
import { noop } from '@utils';

import { Modal } from '@components';

import { AuthCodeModalContent } from './AuthCodeModalContent';
import { AuthPhoneModalContent } from './AuthPhoneModalContent';

enum Stage {
  Phone = 'phone',
  Code = 'code',
}

type State = {
  stage: Stage;
  countryCode?: string;
  phone?: string;
  token?: string;
};

type Props = ModalProps & {
  onSuccess?: AnyFunction;
};

export const AuthModal = ({ onSuccess = noop, closeModal = noop }: Props) => {
  const [{ stage, phone, token, countryCode }, setState] = useImmer<State>({
    stage: Stage.Phone,
  });

  const setStage = (stage: Stage) => {
    setState((state) => {
      state.stage = stage;
    });
  };

  const handlePhoneStageComplete = ({
    phone,
    token,
    countryCode,
  }: Pick<State, 'phone' | 'countryCode' | 'token'>) => {
    setState({
      phone,
      token,
      countryCode,
      stage: Stage.Code,
    });
  };

  const handleAuthSuccess = () => {
    closeModal();
    onSuccess();
  };

  const renderContent = () => {
    if (stage === Stage.Phone) {
      return (
        <AuthPhoneModalContent
          countryCode={countryCode}
          phone={phone}
          onSuccess={handlePhoneStageComplete}
        />
      );
    }

    /**
     * `phone` and `token` exist on this stage
     */
    return (
      <AuthCodeModalContent
        countryCode={countryCode as string}
        phone={phone as string}
        token={token as string}
        onBack={() => setStage(Stage.Phone)}
        onSuccess={handleAuthSuccess}
      />
    );
  };

  return (
    <Modal.Content title="Login / Signup" className="w-[480px]">
      {renderContent()}
    </Modal.Content>
  );
};

import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as ScrollArea from '@radix-ui/react-scroll-area';
import dayjs from 'dayjs';
import { faPaperPlane } from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';

import { AnyFunction, Chat as ChatType } from '@typings';
import { sendChatMessage } from '@services';
import { readChatMessages } from '@thunks';
import { userSelector } from '@selectors';
import { useDispatch, useSelector } from '@hooks';
import { as, noop, toastifyError, tv } from '@utils';

import { Button, ChaseSpinner, Icon, Render } from '@components/Shared';

const variants = tv({
  base: 'max-w-[265px] rounded-2xl pt-2.5 pb-1.5 pl-4 pr-5',
  variants: {
    receiver: {
      true: 'bg-[#B4DEF5] rounded-ss-none self-start',
      false: 'bg-neural-02 rounded-ee-none self-end',
    },
  },
});

type Schema = {
  message: string;
};

type Props = {
  chat?: ChatType.Model | null;
  onMessageSent?: (message: ChatType.Message) => void;
  onMessagesRead?: AnyFunction;
};

export const Chat = ({
  chat,
  onMessageSent = noop,
  onMessagesRead = noop,
}: Props) => {
  const dispatch = useDispatch();
  const { phone, email } = useSelector(userSelector);

  const { register, handleSubmit, reset } = useForm<Schema>();

  const viewportRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);

  const { messages = [], user, trip, unread } = as.o<ChatType.Model>(chat);
  const { id: receiverId } = as.o<ChatType.User>(user);
  const { id: tripId } = as.o<ChatType.Trip>(trip);

  useEffect(() => {
    if (viewportRef.current) {
      viewportRef.current.scrollTop = viewportRef.current.scrollHeight;
    }
  }, []);

  useEffect(() => {
    const readMessages = async () => {
      try {
        await dispatch(readChatMessages({ receiverId, tripId }));

        onMessagesRead();
      } catch (error) {
        /**
         * Continue regardless error
         */
      }
    };

    if (unread) {
      readMessages();
    }
    /**
     * `onMessagesRead` is not required
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receiverId, tripId, unread]);

  const handleMessageSend = async (message: string | null) => {
    if (!tripId || !message) {
      return;
    }

    try {
      setLoading(true);

      const newMessage = await sendChatMessage({ tripId, receiverId, message });

      onMessageSent(newMessage);
      reset();
    } catch (error) {
      toastifyError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleMessageSubmit = handleSubmit(({ message }) =>
    handleMessageSend(message),
  );

  const makeMessage = ({ createdAt, message, senderId }: ChatType.Message) => {
    const isReceiver = senderId === receiverId;

    return (
      <div key={createdAt} className={variants({ receiver: isReceiver })}>
        <p className="break-words text-primary">{message}</p>
        <p className="mt-1.5 text-right text-10">
          {dayjs(createdAt).format('DD MMM, h:mm A')}
        </p>
      </div>
    );
  };

  const renderMessagesContent = () => {
    if (messages.length) {
      return messages.map(makeMessage);
    }

    return (
      <p className="mt-auto text-center text-10 font-500 text-secondary">
        Never visit links sent by drivers or passengers to transfer money. We
        may moderate messages
      </p>
    );
  };

  return (
    <div className="flex max-h-[75vh] flex-1 flex-col rounded-2xl bg-background">
      <ScrollArea.Root className="flex h-auto flex-1 overflow-hidden px-3">
        <ScrollArea.Viewport
          className="h-full w-full items-end rounded md:h-[320px]"
          ref={viewportRef}
        >
          <div className="flex flex-1 flex-col justify-end gap-5 overflow-auto py-5">
            {renderMessagesContent()}
          </div>
        </ScrollArea.Viewport>
        <ScrollArea.Scrollbar
          className="duration-[160ms] flex touch-none select-none bg-white p-0.5 transition-colors ease-out hover:bg-background data-[orientation=horizontal]:h-2.5 data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col"
          orientation="vertical"
        >
          <ScrollArea.Thumb className="relative flex-1 rounded-[10px] bg-eva-light before:absolute before:left-1/2 before:top-1/2 before:h-full before:min-h-[44px] before:w-full before:min-w-[44px] before:-translate-x-1/2 before:-translate-y-1/2 before:content-['']" />
        </ScrollArea.Scrollbar>
      </ScrollArea.Root>
      <div className="sticky bottom-0 bg-background p-3">
        <div className="my-2 flex gap-2 px-3">
          <Button
            theme
            className="h-4 rounded-s bg-neural-01 px-1 text-10 font-500 text-secondary"
            onClick={() => handleMessageSend(phone)}
          >
            Send my Phone number
          </Button>
          <Button
            theme
            className="h-4 rounded-s bg-neural-01 px-1 text-10 font-500 text-secondary"
            onClick={() => handleMessageSend(email)}
          >
            Send my Email
          </Button>
        </div>
        <form
          noValidate
          className="flex h-8 w-full rounded-3xl border border-secondary bg-background"
          onSubmit={handleMessageSubmit}
        >
          <input
            {...register('message')}
            placeholder="Message"
            autoComplete="off"
            className="h-full w-full flex-1 appearance-none bg-transparent pl-4 pr-2 text-10 text-primary placeholder:text-secondary"
          />
          <Button
            theme
            type="submit"
            className={clsx(
              'h-full px-2 text-16 text-secondary transition-colors hover:text-primary',
              { 'pointer-events-none': loading },
            )}
          >
            <Render if={loading}>
              <ChaseSpinner color="primary" className="h-4 w-4" />
            </Render>
            <Render if={!loading}>
              <Icon icon={faPaperPlane} />
            </Render>
          </Button>
        </form>
      </div>
    </div>
  );
};

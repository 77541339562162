import { isNumber, isString, tv, VariantProps } from '@utils';

import { Transition } from '@components';

const variants = tv({
  base: 'w-full text-error text-label-1 pl-2',
  variants: {
    variant: {
      absolute: 'absolute inset-x-0 bottom-0 truncate',
      static: 'static mt-1',
    },
  },
  defaultVariants: {
    variant: 'static',
  },
});

type Props = VariantProps<typeof variants> & {
  className?: string;
  children?: React.ReactNode;
};

export const FieldError = ({ className, variant, children }: Props) => {
  const title =
    isString(children) || isNumber(children) ? (children as string) : undefined;

  return (
    <Transition mountOnEnter unmountOnExit in={!!children} className="fade">
      <p className={variants({ variant, className })} title={title}>
        {children}
      </p>
    </Transition>
  );
};

FieldError.displayName = 'Field.Error';

import { cn } from '@utils';

import { Modal, Render } from '@components';

type Props = {
  className?: string;
  title?: string;
  children?: React.ReactNode;
};

export const ModalContent = ({ className, title, children }: Props) => {
  return (
    <div
      className={cn(
        'max-w-full overflow-auto rounded-2xl bg-white p-6 shadow-[0px_12px_33px_0px_rgba(62,73,84,0.08)] md:p-4',
        className,
      )}
    >
      <Render if={title}>
        <h2 className="text-title-1">{title}</h2>
        <Modal.Divider className="my-4" />
      </Render>
      {children}
    </div>
  );
};

import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { Icon, Link, Render, Theme } from '@components';

type Props = {
  icon: IconProp;
  title: string;
  description?: string;
  path?: string;
  notificationCount?: number;
};

export const ProfileNavigator = ({
  icon,
  title,
  description,
  path = '',
  notificationCount,
}: Props) => {
  return (
    <Link
      theme
      className="text-start transition-transform duration-300 hover:-translate-y-1"
      to={path}
    >
      <Theme.Container className="h-full min-h-[90px] px-4 py-3">
        <header className="mb-2.5 flex items-center justify-between">
          <Icon icon={icon} className="h-4 w-4 text-18 text-hannah" />
          <Render if={notificationCount}>
            <span className="flex h-4 w-4 items-center justify-center rounded-full bg-[#F31212] text-14 font-700 text-white">
              {notificationCount}
            </span>
          </Render>
        </header>
        <p className="text-14 font-600">{title}</p>
        <Render if={description}>
          <p className="text-caption-2">{description}</p>
        </Render>
      </Theme.Container>
    </Link>
  );
};

import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { StringKeyOf } from 'type-fest';
import { useImmer } from 'use-immer';
import {
  faCalendarDays,
  faCircleSmall,
  faMagnifyingGlass,
  faMinusCircle,
  faPlusCircle,
  faUser,
} from '@fortawesome/pro-regular-svg-icons';
import clsx from 'clsx';

import { DateIsoFormat, Location } from '@typings';
import { DATE_FORMAT, PATH } from '@constants';
import { googlemaps } from '@features';
import {
  cn,
  formatAddressInput,
  formatToCamelCase,
  geocode,
  toastifyError,
  tv,
} from '@utils';

import { TripsContext } from '@components/TripsProvider';
import { InputDatePicker, PlacePredictions } from '@components/Ui';

import { Button, ChaseSpinner, Icon, Render } from './Shared';

const parseDateParam = (date: string | DateIsoFormat) => {
  if (date === 'tomorrow') {
    return dayjs().add(1, 'day').toDate();
  }

  return dayjs(date).toDate();
};

const variants = tv({
  slots: {
    container:
      'flex h-[68px] items-center gap-3 rounded-full px-4 py-1 shadow-[2px_2px_8px_0px_#E9E9E9] md:flex-col md:h-auto md:rounded-2xl md:py-4 md:gap-1',
    input:
      'appearance-none text-18 font-500 pl-7 bg-transparent w-full md:py-4',
    divider: 'h-8 w-px shrink-0 md:h-px md:w-full',
    icon: 'pointer-events-none absolute left-0 m-auto text-20',
    inputDatePicker: 'md:py-4',
    seatsButton: 'text-20',
    submitButton:
      'flex h-11 w-11 shrink-0 items-center justify-center rounded-full text-24 transition-colors md:w-full md:rounded-2xl',
  },
  variants: {
    variant: {
      white: {
        container: 'bg-white',
        input: 'text-secondary placeholder:text-secondary',
        divider: 'bg-hannah',
        icon: 'text-secondary',
        inputDatePicker: 'text-secondary',
        seatsButton: 'text-secondary',
        submitButton: 'bg-hannah text-white',
      },
      hannah: {
        container: 'bg-hannah',
        input: 'text-white placeholder:text-white',
        divider: 'bg-white',
        icon: 'text-white',
        inputDatePicker: 'text-white',
        seatsButton: 'text-white',
        submitButton: 'bg-white text-hannah',
      },
    },
  },
  defaultVariants: {
    variant: 'hannah',
  },
});

type PredictionsImmer = {
  origin: Location.PlacePrediction[];
  destination: Location.PlacePrediction[];
};

type PredictionKeys = StringKeyOf<PredictionsImmer>;

type InputsImmer = {
  origin: string;
  destination: string;
};

type Props = {
  /**
   * It defines is component able to submit data on button click
   */
  isOwn?: boolean;
  variant?: 'hannah' | 'white';
};

export const SearchTrip = ({ isOwn, variant = 'hannah' }: Props) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { loading, getTrips } = useContext(TripsContext);

  const seatsParam = searchParams.get('seats');
  const dateParam = searchParams.get('date') ?? '';
  const originPlaceId = searchParams.get('originPlaceId') ?? '';
  const destinationPlaceId = searchParams.get('destinationPlaceId') ?? '';
  const sortParam = searchParams.get('sort') ?? '';
  const initialDate = dateParam ? parseDateParam(dateParam) : null;

  const [seats, setSeats] = useState(seatsParam ? Number(seatsParam) : 1);
  const [date, setDate] = useState<Date | null>(initialDate);
  const [service, setService] =
    useState<google.maps.places.AutocompleteService>();
  const [inputs, setInputs] = useImmer<InputsImmer>({
    origin: '',
    destination: '',
  });
  const [places, setPlaces] = useImmer<InputsImmer>({
    origin: '',
    destination: '',
  });
  const [
    { origin: originPredictions, destination: destinationPredictions },
    setPredictions,
  ] = useImmer<PredictionsImmer>({
    origin: [],
    destination: [],
  });

  const slots = variants({ variant });
  const inputClassName = slots.input();
  const divider = <div className={slots.divider()} />;

  const setInput = useCallback(
    (name: PredictionKeys, value: string) => {
      setInputs((state) => {
        state[name] = formatAddressInput(value);
      });
    },
    [setInputs],
  );

  const setPlace = useCallback(
    (name: PredictionKeys, value: string) => {
      setPlaces((state) => {
        state[name] = value;
      });
    },
    [setPlaces],
  );

  const geocodePlace = useCallback(
    async (name: PredictionKeys, placeId: string) => {
      try {
        if (!placeId) {
          return;
        }

        const place = await geocode({ placeId });

        if (place) {
          setInput(name, `${place.city}, ${place.state}`);
          setPlace(name, place.placeId);
        }
      } catch (error) {
        toastifyError(error);
      }
    },
    [setInput, setPlace],
  );

  useEffect(() => {
    geocodePlace('origin', originPlaceId);
    geocodePlace('destination', destinationPlaceId);
  }, [originPlaceId, destinationPlaceId, geocodePlace]);

  useEffect(() => {
    const service = googlemaps.AutocompleteService();

    if (service) {
      setService(service);
    }
  }, []);

  const setPredictionsFor = useCallback(
    (name: PredictionKeys, predictions: Location.PlacePrediction[]) => {
      setPredictions((state) => {
        state[name] = predictions;
      });
    },
    [setPredictions],
  );

  const getPlacePredictions = async (name: PredictionKeys, input: string) => {
    if (!service) {
      /**
       * todo: resolve console
       */
      // eslint-disable-next-line no-console
      console.log('No service');

      return;
    }

    try {
      if (input) {
        const { predictions } = await service.getPlacePredictions({
          input,
          language: 'en',
          region: 'CA',
          types: ['(cities)'],
          componentRestrictions: { country: 'CA' },
        });

        setPredictionsFor(
          name,
          formatToCamelCase(predictions) as Location.PlacePrediction[],
        );

        return predictions;
      }
    } catch (error) {
      toastifyError(error);
    }
  };

  const resetPredictionsFor = (name: PredictionKeys) => {
    setPredictionsFor(name, []);
  };

  const resetValue = (name: PredictionKeys) => {
    setInput(name, '');
  };

  const curryPredictionChoose =
    (name: PredictionKeys) =>
    ({ description, placeId }: Location.PlacePrediction) => {
      resetPredictionsFor(name);
      setInput(name, description);
      setPlace(name, placeId);
    };

  const curryInputChange =
    (name: PredictionKeys) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      if (value.length > 1) {
        getPlacePredictions(name, value);
      } else {
        resetPredictionsFor(name);
        resetValue(name);
      }

      setInput(name, value);
    };

  const handleSeatsChange = (diff: number) => {
    if (diff < 0 && seats === 1) {
      return;
    }

    if (diff > 0 && seats === 7) {
      return;
    }

    setSeats((seats) => seats + diff);
  };

  const handleSubmit = () => {
    const originPlaceId = places.origin;
    const destinationPlaceId = places.destination;
    const dateDayjs = dayjs(date);
    const formattedDate = dateDayjs.format(DATE_FORMAT);
    const params = {
      originPlaceId,
      destinationPlaceId,
      seats,
      sort: sortParam,
      ...(date && { date: formattedDate }),
    };
    // @ts-expect-error setting seats as number
    const searchParams = new URLSearchParams(params);

    if (
      !originPlaceId ||
      !destinationPlaceId ||
      !seats ||
      (date && !dateDayjs.isValid())
    ) {
      return;
    }

    if (isOwn) {
      getTrips(params);
    }

    navigate(`${PATH.SEARCH}?${searchParams.toString()}`);
  };

  return (
    <div className={slots.container()}>
      <div className="relative w-full flex-[2]">
        <Icon
          icon={faCircleSmall}
          className={cn('bottom-0 top-px mt-px', slots.icon())}
        />
        <input
          value={inputs.origin}
          placeholder="From"
          className={inputClassName}
          onChange={curryInputChange('origin')}
        />
        <PlacePredictions
          predictions={originPredictions}
          onClick={curryPredictionChoose('origin')}
        />
      </div>
      {divider}
      <div className="relative w-full flex-[2]">
        <Icon
          icon={faCircleSmall}
          className={cn('bottom-0 top-px mt-px', slots.icon())}
        />
        <input
          value={inputs.destination}
          placeholder="To"
          className={inputClassName}
          onChange={curryInputChange('destination')}
        />
        <PlacePredictions
          predictions={destinationPredictions}
          onClick={curryPredictionChoose('destination')}
        />
      </div>
      {divider}
      <div className="relative flex w-full flex-1 items-center">
        <Icon
          icon={faCalendarDays}
          className={cn('inset-y-0 mr-1.5', slots.icon())}
        />
        <InputDatePicker
          className={slots.inputDatePicker()}
          placeholder="Date (optional)"
          value={date}
          onValueChange={setDate}
        />
      </div>
      {divider}
      <div className="relative flex w-full flex-1 items-center gap-1.5">
        <Icon icon={faUser} className={cn(slots.icon(), '-mr-7')} />
        <p className={cn(inputClassName, 'w-auto')}>{seats}</p>
        <Button
          theme
          className={clsx('ml-auto md:ml-6', slots.seatsButton())}
          onClick={() => handleSeatsChange(-1)}
        >
          <Icon icon={faMinusCircle} />
        </Button>
        <Button
          theme
          className={clsx('mr-auto', slots.seatsButton())}
          onClick={() => handleSeatsChange(1)}
        >
          <Icon icon={faPlusCircle} />
        </Button>
      </div>
      <Button
        theme
        loading={loading}
        className={slots.submitButton()}
        onClick={handleSubmit}
      >
        <Render if={loading}>
          <ChaseSpinner color={variant} />
        </Render>
        <Render if={!loading}>
          <Icon icon={faMagnifyingGlass} className="h-full w-full" />
        </Render>
      </Button>
    </div>
  );
};

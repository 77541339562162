import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  faChevronDown,
  faMinus,
  faPlus,
} from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';

import { Vehicle } from '@typings';
import { PATH } from '@constants';
import { toast } from '@features';
import { createTrip } from '@services';
import { resetTrip, setTripDetails } from '@slices';
import { tripSelector, userVehicleSelector } from '@selectors';
import { useDispatch, useSelector } from '@hooks';

import { Button, Icon, Link, Modal, Render } from '@components';
import { TripLayout } from '@components/Layouts';
import { TripWaypointPricesModal } from '@components/Ui';

const OFFSET = 5;
const adjustPrice = (price: number, coefficient: number) =>
  Math.round((price * coefficient) / OFFSET) * OFFSET;

export const TripTariffsPage = () => {
  const dispatch = useDispatch();
  const {
    commentary: comment,
    rules,
    seats,
    origin,
    destination,
    polyline,
    details: { price, recommendedPrice, departure, arrival, waypoints },
  } = useSelector(tripSelector);
  const vehicle = useSelector(userVehicleSelector);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const isMinimalPrice = price <= OFFSET;
  const isMaximalPrice = price >= adjustPrice(recommendedPrice, 1.25);
  const lowerRecommendedPrice = adjustPrice(recommendedPrice, 0.9);
  const upperRecommendedPrice = adjustPrice(recommendedPrice, 1.1);

  const getRecommendedPriceColor = () => {
    if (lowerRecommendedPrice <= price && price <= upperRecommendedPrice) {
      return 'text-[#22AF27]';
    }

    if (
      adjustPrice(recommendedPrice, 0.8) <= price &&
      price <= adjustPrice(recommendedPrice, 1.2)
    ) {
      return 'text-[#F3A712]';
    }

    return 'text-hannah';
  };

  const handlePriceChange = (diff: number) => {
    const offset = price % OFFSET;
    const concatination = diff > 0 ? OFFSET - offset : offset * -1;
    const isMultipleOf = offset === 0;

    const newPrice = isMultipleOf ? price + diff : price + concatination;

    dispatch(setTripDetails({ price: newPrice }));
  };

  const handleTripSubmit = async (vehicleModel?: Vehicle) => {
    try {
      setLoading(true);

      const vehicleId = vehicleModel?.id ?? vehicle?.id;

      const payload = {
        arrival,
        comment,
        departure,
        destination: destination!,
        origin: origin!,
        price,
        rules,
        seats,
        waypoints,
        polyline: polyline!,
        vehicleId: vehicleId!,
      };

      await createTrip(payload);

      toast.success('Your trip has been published 🎉');
      navigate(PATH.DRIVER_TRIPS);
      dispatch(resetTrip());
    } catch (error) {
      toast.error((error as Error)?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <TripLayout.Handler
        title="Set the price (per seat)"
        loading={loading}
        onHandle={handleTripSubmit}
      />
      <div className="flex w-full max-w-[360px] flex-col items-center">
        <div className="mt-24 flex w-full items-start justify-center gap-3 md:mt-6">
          <Button
            theme
            className="mt-3 rounded-full bg-[#3F4E61] text-[18px] text-white transition-colors hover:bg-black disabled:bg-neural-03"
            disabled={isMinimalPrice}
            onClick={() => handlePriceChange(OFFSET * -1)}
          >
            <Icon className="h-8 w-8" icon={faMinus} />
          </Button>
          <div className="flex flex-1 flex-col gap-1">
            <div className="relative flex flex-1 justify-center rounded-2xl border-2 border-primary sm:w-full">
              <p className="relative text-center text-h3 text-eva">
                {price}
                <span className="absolute bottom-0 left-full text-h5">.00</span>
              </p>
              <p className="absolute bottom-0 left-2 text-h5 font-500 text-[#7C909B]">
                CA$
              </p>
            </div>
            <p
              className={clsx(
                'text-center text-caption font-600',
                getRecommendedPriceColor(),
              )}
            >
              Recommended price: CA$ {lowerRecommendedPrice} -{' '}
              {upperRecommendedPrice}
            </p>
          </div>
          <Button
            theme
            disabled={isMaximalPrice}
            className="mt-3 rounded-full bg-[#3F4E61] text-[18px] text-white transition-colors hover:bg-black disabled:bg-neural-03"
            onClick={() => handlePriceChange(OFFSET)}
          >
            <Icon className="h-8 w-8" icon={faPlus} />
          </Button>
        </div>
        <p className="mt-5 text-headline font-400 text-secondary">
          Total for all your seats ({seats})
        </p>
        <p className="mt-1.5 text-headline font-500 text-secondary">
          CA$ <span className="text-h4 font-600">{price * seats}</span>
          <span className="text-caption">.00</span>
        </p>
        <Render if={waypoints}>
          <Modal content={<TripWaypointPricesModal />}>
            <Button
              theme
              className="mt-8 flex h-10 w-full items-center justify-between rounded-2xl px-2 pl-6 pr-3 font-400 text-secondary shadow-[0px_0px_5px_0px_#F3F3F3] transition-shadow hover:shadow-[0px_0px_5px_0px_#E1E1E5]"
            >
              Edit the waypoints price
              <Icon icon={faChevronDown} />
            </Button>
          </Modal>
        </Render>
        <p className="mt-6 w-full max-w-[346px] text-caption text-secondary">
          Before publishing, Beeple will add a service fee to your price. After
          passengers pay the service fee, they are available to text or call you
          to discuss the trip details. Read the&nbsp;
          <Link
            theme
            external
            blank
            to={PATH.TERMS_AND_CONDITIONS}
            className="underline"
          >
            Terms & Conditions
          </Link>
        </p>
      </div>
    </>
  );
};

import { useCallback, useEffect, useRef, useState } from 'react';

import { Location, ModalProps, Place } from '@typings';
import { GOOGLE_MAPS_MAP_ID } from '@constants';
import { googlemaps } from '@features';
import { configSelector } from '@selectors';
import { useSelector } from '@hooks';
import { formatToCamelCase, geocode, noop, toastifyError } from '@utils';

import { Button, Field, Modal, Render } from '@components';
import { PlacePredictions } from '@components/Ui';

type Props = ModalProps & {
  /**
   * Prevents modal interactive
   */
  viewMode?: boolean;
  polyline?: string | null;
  placeId?: string;
  decodeLocation?: boolean;
  title?: string;
  geo?: Location.Geo;
  onApply?: (place: Place) => void;
};

export const GoogleMapModal = ({
  viewMode = false,
  decodeLocation = true,
  polyline,
  placeId,
  title,
  geo,
  closeModal = noop,
  onApply = noop,
}: Props) => {
  const { geo: configGeo } = useSelector(configSelector);

  const mapRef = useRef<HTMLDivElement>(null);
  const markerRef = useRef<google.maps.marker.AdvancedMarkerElement>();
  const [loading, setLoading] = useState(false);
  const [map, setMap] = useState<google.maps.Map>();
  const [place, setPlace] = useState<Place | null>(null);
  const [input, setInput] = useState('');
  const [service, setService] =
    useState<google.maps.places.AutocompleteService>();
  const [placePredictions, setPlacePredictions] = useState<
    Location.PlacePrediction[]
  >([]);

  const createMarker = useCallback(
    (
      position: google.maps.LatLngLiteral | google.maps.LatLng | null,
      map: google.maps.Map | null = null,
    ) => {
      const marker = new google.maps.marker.AdvancedMarkerElement({
        position,
        map,
      });

      markerRef.current = marker;
    },
    [],
  );

  useEffect(() => {
    const service = googlemaps.AutocompleteService();

    if (service) {
      setService(service);
    }
  }, []);

  useEffect(() => {
    /**
     * Calgary
     */
    const myLatlng = new google.maps.LatLng(configGeo);
    const mapOptions: google.maps.MapOptions = {
      zoom: 10,
      center: myLatlng,
      disableDefaultUI: true,
      mapId: GOOGLE_MAPS_MAP_ID,
      clickableIcons: false,
      gestureHandling: 'greedy',
    };
    const map = googlemaps.Map(mapRef.current!, mapOptions);

    const listener = !viewMode
      ? map?.addListener(
          'click',
          async ({ latLng: geo }: google.maps.MapMouseEvent) => {
            if (markerRef.current) {
              markerRef.current.map = null;
            }

            if (geo) {
              map.setCenter(geo);

              try {
                const place = await geocode({ geo });

                if (place) {
                  setPlace(place);
                  setInput(place.formattedAddress);
                }
              } catch (error) {
                toastifyError(error);
              }
            }

            createMarker(geo, map);
          },
        )
      : null;

    setMap(map);

    return () => {
      listener?.remove();
    };
    /**
     * No need to track `configGeo` change
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewMode, createMarker]);

  useEffect(() => {
    if (geo && map) {
      createMarker(geo, map);
      map.setCenter(geo);
      map.setZoom(12);
    }
    /**
     * Set location marker on init only
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, createMarker]);

  useEffect(() => {
    if (map && polyline) {
      try {
        const directionsRenderer = new google.maps.DirectionsRenderer();

        directionsRenderer.setMap(map!);

        // eslint-disable-next-line no-new
        new google.maps.Polyline({
          path: google.maps.geometry.encoding.decodePath(polyline!),
          map,
          strokeColor: '#0A8ED6',
          strokeWeight: 6,
          geodesic: true,
          strokeOpacity: 0.75,
          clickable: false,
        });
      } catch (error) {
        toastifyError(error);
      }
    }
  }, [map, polyline]);

  useEffect(() => {
    const zoomLevel = viewMode ? 10 : 14;

    const run = async () => {
      try {
        const place = await geocode({ placeId, geo });

        if (place) {
          const { geo } = place;

          map?.setCenter(geo);
          map?.setZoom(zoomLevel);

          setPlace(place);
          setInput(place.formattedAddress);
        }
      } catch (error) {
        toastifyError(error);
      }
    };

    if (decodeLocation && (placeId || geo)) {
      run();
    }
  }, [viewMode, decodeLocation, placeId, geo, map, createMarker]);

  const isSamePlace = () => {
    if (!place || !geo) {
      return false;
    }

    if (placeId === place?.placeId) {
      return true;
    }

    if (geo.lat === place?.geo.lat && geo.lng === place.geo.lng) {
      return true;
    }

    return false;
  };

  const getPlacePredictions = async (input: string) => {
    if (!service) {
      /**
       * todo: resolve console
       */
      // eslint-disable-next-line no-console
      console.log('No service');

      return;
    }

    try {
      if (input) {
        const { predictions } = await service.getPlacePredictions({
          input,
          language: 'en',
          region: 'CA',
          types: ['geocode'],
          componentRestrictions: { country: 'CA' },
        });

        setPlacePredictions(
          formatToCamelCase(predictions) as Location.PlacePrediction[],
        );

        return predictions;
      }
    } catch (error) {
      toastifyError(error);
    }
  };

  const resetPlacePredictions = () => {
    setPlacePredictions([]);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setInput(value);
    getPlacePredictions(value);
  };

  const handlePlacePredictionChoose = async ({
    placeId,
    description,
  }: Location.PlacePrediction) => {
    try {
      setLoading(true);
      setInput(description);
      resetPlacePredictions();

      const place = await geocode({ placeId });

      if (place) {
        setPlace(place);
      }
    } catch (error) {
      /**
       * Conrinue regardless error
       */
    } finally {
      setLoading(false);
    }
  };

  const handleApply = () => {
    if (isSamePlace()) {
      closeModal();

      return;
    }

    if (!place) {
      closeModal();

      return;
    }

    onApply(place);
    closeModal();
  };

  return (
    <Modal.Content
      title={title}
      className="flex h-[calc(100vh-48px)] w-[calc(100vw-48px)] flex-col gap-2"
    >
      <div className="mb-2 flex gap-3">
        <div className="relative basis-[560px]">
          <Field.Input
            disabled={viewMode}
            label="Address"
            className="border-eva"
            value={input}
            onChange={handleInputChange}
          />
          <PlacePredictions
            predictions={placePredictions}
            onClick={handlePlacePredictionChoose}
          />
        </div>
        <Render if={!viewMode}>
          <Button
            loading={loading}
            disabled={!place}
            variant="hannah"
            className="h-auto px-10"
            onClick={handleApply}
          >
            Apply
          </Button>
        </Render>
        <Render if={viewMode}>
          <Button variant="ghost" className="h-auto px-10" onClick={closeModal}>
            Close
          </Button>
        </Render>
      </div>
      <div ref={mapRef} className="flex-1 rounded-xl" />
    </Modal.Content>
  );
};

import { cn, tv, VariantProps } from '@utils';

const variants = tv({
  base: 'absolute left-0 top-0 h-full w-full before:block before:h-1/4 before:w-1/4 before:rounded-full before:bg-white',
  variants: {
    color: {
      white: 'before:bg-white',
      hannah: 'before:bg-hannah',
      eva: 'before:bg-eva',
      error: 'before:bg-error',
      primary: 'before:bg-primary',
    },
  },
  defaultVariants: {
    color: 'white',
  },
});

type Props = VariantProps<typeof variants> & {
  className?: string;
};

export const ChaseSpinner = ({ className, color }: Props) => {
  const chaseDotClassName = cn(variants({ color }));

  return (
    <div className={cn(`chase-spinner relative h-6 w-6`, className)}>
      <div className={chaseDotClassName} />
      <div className={chaseDotClassName} />
      <div className={chaseDotClassName} />
      <div className={chaseDotClassName} />
      <div className={chaseDotClassName} />
      <div className={chaseDotClassName} />
    </div>
  );
};

import { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { KeysOfUnion } from 'type-fest';
import { useImmer } from 'use-immer';
import {
  faCaretRight,
  faDiamond,
  faUserVneck,
} from '@fortawesome/pro-solid-svg-icons';

import { Trip } from '@typings';
import { PATH } from '@constants';
import { getBookings, updateBookingStatus } from '@services';
import { usePagination } from '@hooks';
import { cn, isUserDeleted, p, toastifyError, tv } from '@utils';
import { tripRules } from '@content';

import {
  Avatar,
  Button,
  ChaseSpinner,
  Icon,
  Link,
  Modal,
  Render,
  Tooltip,
} from '@components';
import { CancelTripModal } from '@components/Ui';

const variants = tv({
  base: 'flex h-[18px] w-[66px] items-center justify-center rounded-full bg-primary text-10 font-500 text-white capitalize sm:h-6',
  variants: {
    variant: {
      pending: 'bg-[#EAC916]',
      declined: 'bg-hannah',
      canceled: 'bg-hannah',
      cancel: 'bg-hannah transition-colors hover:bg-hannah-dark',
      completed: 'bg-[#22AF27]/50',
      chat: 'bg-primary transition-colors hover:bg-primary-dark',
      default: 'bg-secondary',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

export const PassengerTripsPage = () => {
  const [loading, setLoading] = useState(false);
  const [isFetched, setFetched] = useState(false);
  const [trips, setTrips] = useImmer<Trip.PassengerItem[]>([]);
  const {
    pagination: { next, total },
    setPagination,
  } = usePagination();

  const fetchBookings = useCallback(
    async (page?: number | null) => {
      try {
        setLoading(true);

        const { items: trips, ...pagination } = await getBookings(page);

        setTrips((state) => state.concat(trips));
        setPagination(pagination);
      } catch (error) {
        toastifyError(error);
      } finally {
        setLoading(false);
        setFetched(true);
      }
    },
    [setTrips, setPagination],
  );

  useEffect(() => {
    fetchBookings();
  }, [fetchBookings]);

  const handleBookingCancel = async (tripId: string, bookingId: string) => {
    try {
      const CANCELED_STATUS = 'canceled';

      await updateBookingStatus({
        tripId,
        bookingId,
        status: CANCELED_STATUS,
      });

      setTrips((trips) => {
        const trip = trips.find(({ id }) => id === bookingId);

        if (trip) {
          trip.status = CANCELED_STATUS;
        }
      });
    } catch (error) {
      toastifyError(error);
    }
  };

  const makeRule = ([rule, value]: [string, string]) => {
    const currentRule = tripRules[
      rule as KeysOfUnion<typeof tripRules>
    ]?.rules.find((rule) => rule.value === value);

    if (!currentRule) {
      return null;
    }

    const { icon, title } = currentRule;

    return (
      <Tooltip key={rule}>
        <p slot="trigger" className="h-4 w-4 text-12 text-primary">
          <Icon key={rule} icon={icon} />
        </p>
        <p>{title}</p>
      </Tooltip>
    );
  };

  const makeItem = ({
    id: bookingId,
    passengers,
    status,
    trip: {
      id: tripId,
      arrival,
      rules,
      departure,
      origin,
      destination,
      driver: { id: driverId, firstName, avatar, deletedAt },
      vehicle: { make, model, color },
    },
  }: Trip.PassengerItem) => {
    const isCompleted = status === 'completed';
    const isPending = status === 'pending';
    const isCanceled = status === 'canceled';
    const isDeclined = status === 'declined';
    const isApproved = status === 'approved';

    return (
      <div
        key={bookingId}
        className={cn(
          'group relative flex w-full items-center gap-2 rounded-3xl bg-white py-2 pl-4 pr-7 text-eva shadow-[0px_0px_8px_0px_#F4F4F4] sm:flex-col sm:p-4',
          {
            'text-secondary': !isPending,
            'opacity-50': isDeclined || isCanceled,
          },
        )}
      >
        <Link
          theme
          disabled={isUserDeleted(deletedAt)}
          to={p(PATH.USER, driverId)}
          className="group/link flex flex-col items-center justify-center gap-1.5"
        >
          <Avatar
            src={avatar}
            className="h-9 w-9 text-18 transition-colors sm:h-16 sm:w-16"
          />
          <p className="text-8 font-500 transition-colors group-hover/link:text-hannah sm:text-12">
            {firstName}
          </p>
        </Link>
        <div className="flex-1 px-4 sm:w-full sm:px-2">
          <div className="flex flex-1 items-center text-12 font-500">
            <p>{origin.city}</p>
            <div className="mx-3 flex flex-1 items-center text-secondary-light">
              <Icon icon={faDiamond} className="contents shrink-0 text-5" />
              <div className="h-px flex-1 border-b border-dashed border-secondary-light" />
              <Icon icon={faCaretRight} className="contents shrink-0 text-10" />
            </div>
            <p>{destination.city}</p>
          </div>
          <p className="flex justify-between text-7 text-secondary">
            <span>
              <b>{dayjs(departure).format('MMM D, YYYY')}</b> /{' '}
              {dayjs(departure).format('h:mm A')}
            </span>
            <span>
              <b>{dayjs(arrival).format('MMM D, YYYY')}</b> /{' '}
              {dayjs(arrival).format('h:mm A')}
            </span>
          </p>
          <div className="mt-1.5 flex items-center justify-between">
            <div className="flex gap-1">
              <Render if={rules}>{Object.entries(rules).map(makeRule)}</Render>
            </div>
            <p className="flex items-center gap-1 text-13 font-500 leading-0 text-hannah">
              <Icon icon={faUserVneck} className="h-4 w-4 text-12" /> x{' '}
              {passengers}
            </p>
          </div>
          <p className="mt-2 text-10 text-secondary">
            {make} {model} ({color})
          </p>
        </div>
        <div className="flex flex-col gap-1.5 sm:w-full sm:[&>*]:w-full">
          <Render if={isPending}>
            <p className={variants({ variant: 'pending' })}>Pending</p>
          </Render>
          <Render if={isCompleted}>
            <p className={variants({ variant: 'completed' })}>Completed</p>
          </Render>
          <Render if={isApproved}>
            <p className={variants({ variant: 'completed' })}>Approved</p>
          </Render>
          <Render if={isPending || isApproved}>
            <Link
              theme
              className={variants({ variant: 'chat' })}
              to={`${PATH.CHAT}/${tripId}/${driverId}`}
            >
              Chat
            </Link>
          </Render>
          <Render if={isPending || isApproved}>
            <Modal
              content={
                <CancelTripModal
                  onCancel={() => handleBookingCancel(tripId, bookingId)}
                />
              }
            >
              <Button
                theme
                stopPropagation
                className={variants({ variant: 'cancel' })}
              >
                Cancel
              </Button>
            </Modal>
          </Render>
          <Render if={isCanceled}>
            <p className={variants({ variant: 'canceled' })}>Canceled</p>
          </Render>
          <Render if={isDeclined}>
            <p className={variants({ variant: 'declined' })}>Declined</p>
          </Render>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    if (!isFetched) {
      return (
        <div className="mt-20 flex justify-center">
          <ChaseSpinner color="eva" className="h-10 w-10" />
        </div>
      );
    }

    if (isFetched && trips.length === 0) {
      return (
        <div className="flex flex-col items-center gap-4">
          <p className="mt-20 text-center text-24 font-600 text-eva">
            No trips found
          </p>
          <Link variant="eva" className="px-10" to={PATH.HOME}>
            Search trips
          </Link>
        </div>
      );
    }

    return (
      <>
        <p className="text-14 font-600 text-eva">{total} trips</p>
        {trips.map(makeItem)}
        <Render if={next}>
          <div className="mt-6 text-center">
            <Button loading={loading} onClick={() => fetchBookings(next)}>
              Load more
            </Button>
          </div>
        </Render>
      </>
    );
  };

  return <div className="flex flex-col gap-2">{renderContent()}</div>;
};

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useImmer } from 'use-immer';
import {
  faCaretRight,
  faDiamond,
  faUserVneck,
} from '@fortawesome/pro-solid-svg-icons';

import { Chat } from '@typings';
import { PATH } from '@constants';
import { getChats } from '@services';
import { cn, isUserDeleted, p } from '@utils';

import {
  Avatar,
  Button,
  ChaseSpinner,
  Icon,
  Link,
  Render,
  Theme,
} from '@components';
import { Layout } from '@components/Layouts';
import { BackButton, Chat as ChatComponent } from '@components/Ui';

export const Item = () => <div className="h-11 shadow-[2_2_12_0px_#BBBBC1]" />;

export const ChatPage = () => {
  const navigate = useNavigate();
  const { userId: userIdParam, tripId: tripIdParam } = useParams();

  const [loading, setLoading] = useState(true);
  const [chats, setChats] = useImmer<Chat.Model[]>([]);

  const isChatActive = useCallback(
    (tripId: string, userId: string) =>
      userId === userIdParam && tripId === tripIdParam,
    [userIdParam, tripIdParam],
  );

  const getActiveChat = useCallback(
    (chats: Chat.Model[]) => {
      if (!userIdParam || !tripIdParam) {
        return null;
      }

      return chats.find(({ trip: { id: tripId }, user: { id: userId } }) =>
        isChatActive(tripId, userId),
      );
    },
    [tripIdParam, userIdParam, isChatActive],
  );

  const activeChat = useMemo(() => {
    return getActiveChat(chats);
  }, [chats, getActiveChat]);

  useEffect(() => {
    const INTERVAL = 60_000;

    const run = async () => {
      try {
        const chats = await getChats();

        setLoading(false);
        setChats(chats);
      } catch (error) {
        /**
         * Continue regardless error
         */
      }
    };

    run();

    const intervalId = setInterval(run, INTERVAL);

    return () => {
      clearInterval(intervalId);
    };
  }, [setChats]);

  const handleMessageSent = (message: Chat.Message) => {
    setChats((chats) => {
      const chat = getActiveChat(chats);

      if (!chat) {
        return chats;
      }

      chat.messages.push(message);
    });
  };

  const handleMessagesRead = () => {
    setChats((chats) => {
      const chat = getActiveChat(chats);

      if (!chat) {
        return chats;
      }

      chat.unread = 0;
    });
  };

  const navigateToChat = (tripId: string, userId: string) => {
    navigate(`${PATH.CHAT}/${tripId}/${userId}`, { replace: true });
  };

  const renderSeats = (seats: number) => {
    const className =
      'flex basis-12 justify-center text-primary text-16 items-center font-600 shrink-0';

    if (seats > 2) {
      return (
        <p className={className}>
          <Icon icon={faUserVneck} className="h-5 w-5" /> ({seats})
        </p>
      );
    }

    return (
      <p className={className}>
        <Icon icon={faUserVneck} className="h-5 w-5" />
        <Render if={seats === 2}>
          <Icon icon={faUserVneck} className="h-5 w-5" />
        </Render>
      </p>
    );
  };

  const makeChat = ({
    unread,
    booking: { passengers },
    user: { id: userId, firstName, lastName, avatar, deletedAt },
    trip: { id: tripId, origin, destination, departure, arrival },
  }: Chat.Model) => {
    const key = `${tripId}/${userId}`;
    const isActive = isChatActive(tripId, userId);

    return (
      <Button
        theme
        key={key}
        className={cn(
          'group relative flex w-full items-center gap-2 rounded-full bg-white px-2 py-1.5 pr-4 shadow-[0px_0px_8px_0px_#F4F4F4] transition-all hover:shadow-[0px_0px_8px_0px_#e0e0e0] sm:flex-col sm:rounded-2xl sm:py-4',
          { 'shadow-[0px_0px_8px_0px_#e0e0e0]': isActive },
        )}
        onClick={() => navigateToChat(tripId, userId)}
      >
        <Render if={unread > 0}>
          <p className="pointer-events-none absolute right-0 top-0 flex h-5 w-5 items-center justify-center rounded-full border-2 border-white bg-[#F31212] text-11 font-700 text-white">
            {unread}
          </p>
        </Render>
        <div className="sm:flex sm:items-center sm:gap-4">
          {/* To let Link works within group Button */}
          <Button theme disabled={isUserDeleted(deletedAt)}>
            <Link
              theme
              disabled={isUserDeleted(deletedAt)}
              className="group/link flex shrink grow-0 items-center gap-2"
              to={p(PATH.USER, userId)}
            >
              <Avatar
                src={avatar}
                className="h-11 w-11 text-18 transition-colors"
              />
              <div className="w-16 min-w-0 shrink-0 text-eva transition-colors group-hover/link:text-hannah">
                <p className="truncate text-left text-12">{firstName}</p>
                <Render if={lastName}>
                  <p className="truncate text-left text-12">{lastName}</p>
                </Render>
              </div>
            </Link>
          </Button>
          <div className="hidden sm:block">{renderSeats(passengers)}</div>
        </div>
        <div className="-ml-2 min-w-0 flex-1 self-stretch px-2">
          <div className="flex flex-1 items-center text-16 font-500 text-eva">
            <p className="truncate">{origin.city}</p>
            <div className="mx-3 flex min-w-[64px] flex-1 items-center text-secondary-light">
              <Icon icon={faDiamond} className="contents shrink-0 text-5" />
              <div className="h-px flex-1 border-b border-dashed border-secondary-light" />
              <Icon icon={faCaretRight} className="contents shrink-0 text-10" />
            </div>
            <p className="truncate">{destination.city}</p>
          </div>
          <p className="flex justify-between text-10 text-secondary">
            <span>
              <b>{dayjs(departure).format('MMM D, YYYY')}</b> /{' '}
              {dayjs(departure).format('hh:mm A')}
            </span>
            <span>
              <b>{dayjs(arrival).format('MMM D, YYYY')}</b> /{' '}
              {dayjs(arrival).format('hh:mm A')}
            </span>
          </p>
        </div>
        <div className="sm:hidden">{renderSeats(passengers)}</div>
        <p
          className={cn(
            'flex shrink-0 items-center justify-center rounded-full border border-primary px-5 text-16 font-500 text-primary transition-colors group-hover:bg-primary group-hover:text-white sm:mt-4 sm:w-full sm:py-2',
            { 'border-hannah text-white !bg-hannah': isActive },
          )}
        >
          Chat
        </p>
      </Button>
    );
  };

  const renderContent = () => {
    if (loading) {
      return (
        <div className="mt-24 flex w-full flex-1 items-center justify-center">
          <ChaseSpinner color="primary" className="h-10 w-10" />
        </div>
      );
    }

    return (
      <div className="flex flex-1 gap-5 md:flex-col">
        <div className="flex min-w-0 flex-1 flex-col justify-start gap-2 md:-m-1.5 md:max-h-[240px] md:flex-initial md:overflow-auto md:p-1.5">
          {chats.map(makeChat)}
        </div>
        <ChatComponent
          chat={activeChat}
          onMessageSent={handleMessageSent}
          onMessagesRead={handleMessagesRead}
        />
      </div>
    );
  };

  return (
    <Layout className="flex flex-1 flex-col">
      <Theme.Wrapper slot="wrapper" className="flex flex-1 flex-col gap-5 py-6">
        <header className="flex w-full items-center gap-3">
          <BackButton />
          <p className="text-18 font-600 text-eva">Messages</p>
        </header>
        {renderContent()}
      </Theme.Wrapper>
    </Layout>
  );
};

import { useCallback, useEffect, useRef, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { faCircleSmall } from '@fortawesome/pro-regular-svg-icons';
import { faCircleSmall as faCircleSmallSolid } from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';

import { TripOutletContext } from '@typings';
import { GOOGLE_MAPS_MAP_ID } from '@constants';
import { googlemaps, toast } from '@features';
import { getTripWaypoints } from '@services';
import { setTrip } from '@slices';
import { configSelector, tripSelector } from '@selectors';
import { useDispatch, useSelector } from '@hooks';
import { geocode, toastifyError } from '@utils';

import { Button, Icon } from '@components';
import { TripLayout } from '@components/Layouts';

type LegsReducer = {
  distance: number;
  duration: number;
};

type Legs = {
  distance: string;
  duration: string;
};

const convertMetersToKilometersString = (meters: number) =>
  `${Math.round(meters / 1000)} km`;

const convertSecondsToTimeString = (seconds: number) => {
  const days = Math.floor(seconds / (24 * 3600));
  seconds %= 24 * 3600;
  const hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  const minutes = Math.floor(seconds / 60);
  seconds %= 60;

  const daysString = days > 0 ? `${days}d ` : '';
  const hoursString = hours > 0 ? `${hours}h ` : '';
  const minutesString = minutes > 0 ? `${minutes}m ` : '';

  return (daysString + hoursString + minutesString).trim();
};

type Route = {
  polyline: string;
  summary: string;
  distance: string;
  duration: string;
};

export const TripRoutesPage = () => {
  const dispatch = useDispatch();
  const {
    origin,
    destination,
    polyline: tripPolyline,
  } = useSelector(tripSelector);
  const { geo: configGeo } = useSelector(configSelector);

  const { goNext } = useOutletContext<TripOutletContext>();

  const mapRef = useRef<HTMLDivElement>(null);
  const polylineRef = useRef<{ [key: string]: google.maps.Polyline }>({});
  const [routes, setRoutes] = useState<Route[]>([]);
  const [map, setMap] = useState<google.maps.Map>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const myLatlng = new google.maps.LatLng(configGeo);
    const mapOptions: google.maps.MapOptions = {
      zoom: 15,
      center: myLatlng,
      disableDefaultUI: true,
      mapId: GOOGLE_MAPS_MAP_ID,
    };
    const map = googlemaps.Map(mapRef.current!, mapOptions);

    setMap(map);
    /**
     * No need to track `configGeo`
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setPolylineColor = useCallback(
    (polyline: google.maps.Polyline | undefined) => {
      Object.values(polylineRef.current).forEach((polyline) => {
        polyline.setOptions({
          strokeColor: '#BBBBC1',
          zIndex: 1,
          strokeOpacity: 0.75,
        });
      });

      polyline?.setOptions({
        strokeColor: '#0A8ED6',
        zIndex: 10,
        strokeOpacity: 1,
      });
    },
    [],
  );

  const handleRouteChange = useCallback(
    ({ summary, polyline }: { summary: string; polyline: string }) => {
      const polylineElement = polylineRef.current[summary];

      setPolylineColor(polylineElement);

      dispatch(setTrip({ summary, polyline }));
    },
    [dispatch, setPolylineColor],
  );

  const calculateRouteLegs = (legs: google.maps.DirectionsLeg[]): Legs => {
    const { distance, duration } = legs.reduce(
      (accumulator, { distance, duration }) => {
        accumulator.distance += distance?.value ?? 0;
        accumulator.duration += duration?.value ?? 0;

        return accumulator;
      },
      {
        distance: 0,
        duration: 0,
      } as LegsReducer,
    );

    return {
      distance: convertMetersToKilometersString(distance),
      duration: convertSecondsToTimeString(duration),
    };
  };

  useEffect(() => {
    const run = async () => {
      try {
        const directionsRenderer = new google.maps.DirectionsRenderer();
        const { route } = new google.maps.DirectionsService()!;

        directionsRenderer.setMap(map!);

        const { routes, request } = await route({
          origin: { placeId: origin?.placeId },
          destination: { placeId: destination?.placeId },
          travelMode: google.maps.TravelMode.DRIVING,
          provideRouteAlternatives: true,
        });

        directionsRenderer.setDirections({ routes, request });

        const mapRoutes = routes.map(
          ({ overview_polyline: polyline, summary, legs }) => {
            const bounds = new google.maps.LatLngBounds();
            const polylineElement = new google.maps.Polyline({
              path: google.maps.geometry.encoding.decodePath(polyline),
              map,
              strokeColor: '#BBBBC1',
              strokeWeight: 6,
              geodesic: true,
              strokeOpacity: 0.75,
              clickable: false,
            });
            const { distance, duration } = calculateRouteLegs(legs);

            polylineRef.current[summary] = polylineElement;

            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < polylineElement.getPath().getLength(); i++) {
              bounds.extend(polylineElement.getPath().getAt(i));
            }
            map?.fitBounds(bounds);

            return { polyline, summary, distance, duration };
          },
        );
        const initialRoute = mapRoutes[0];

        setRoutes(mapRoutes);

        if (initialRoute) {
          handleRouteChange(initialRoute);
        }
      } catch (error) {
        toastifyError(error);
      }
    };

    if (origin && destination && map) {
      run();
    }
  }, [map, origin, destination, setPolylineColor, handleRouteChange]);

  const makeRoute = ({ polyline, summary, distance, duration }: Route) => {
    const isRouteActive = polyline === tripPolyline;
    const icon = isRouteActive ? faCircleSmallSolid : faCircleSmall;

    return (
      <Button
        key={polyline}
        theme
        className={clsx(
          'flex h-11 items-center rounded-2xl border border-[#ED4054] px-3 py-2 text-body-2-m text-hannah transition-colors hover:bg-[#ED4054] hover:text-white',
          {
            'bg-[#ED4054] text-white': isRouteActive,
          },
        )}
        onClick={() => handleRouteChange({ summary, polyline })}
      >
        <Icon icon={icon} className="mr-1 h-4 w-4" />
        {summary} ({distance}) {duration}
      </Button>
    );
  };

  const handleSubmit = async () => {
    if (!tripPolyline) {
      toast.error('Choose route');

      return;
    }

    try {
      setLoading(true);

      const originLocation = await geocode({ placeId: origin!.placeId });
      const destinationLocation = await geocode({
        placeId: destination!.placeId,
      })!;

      const waypoints = await getTripWaypoints({
        origin: originLocation!,
        destination: destinationLocation!,
        polyline: tripPolyline,
      });

      dispatch(setTrip({ waypoints }));

      goNext();
    } catch (error) {
      toast.error((error as Error)?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex w-full max-w-[1180px] flex-1 flex-col gap-4">
      <div className="flex gap-5">{routes.map(makeRoute)}</div>
      <div
        ref={mapRef}
        className="w-full flex-1 rounded-2xl bg-eva-light/10 shadow-[2px_2px_8px_0px_#E6E6E6]"
      />
      <TripLayout.Handler
        loading={loading}
        title="Choose the way"
        onHandle={handleSubmit}
      />
    </div>
  );
};

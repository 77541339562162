import { Primitive } from '@typings';

export const getFullName = (
  firstName: Primitive,
  lastName: Primitive,
  defaultName = '-',
) => {
  const fn = firstName ?? '';
  const ln = lastName ?? '';

  return fn || ln ? `${fn} ${ln}` : defaultName;
};

import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { PATH } from '@constants';
import { authLogin } from '@services';
import {
  noop,
  normalizeFormErrors,
  setFormBackendErrors,
  zodErrorMessage,
} from '@utils';
import { countryCodes } from '@content';

import { Button, Field, Link } from '@components';

type Schema = z.infer<typeof schema>;

const formatPhone = (phone: string) => phone.replace(/[^0-9]/g, '');

const schema = z.object({
  countryCode: z.string(),
  phone: z
    .string()
    .min(1, zodErrorMessage('Phone is required'))
    .refine((phone) => {
      const formattedPhone = phone.replace(/[^0-9]/g, '');

      return formattedPhone.length > 8;
    }, zodErrorMessage('Phone is invalid')),
});

type Props = {
  countryCode?: string;
  phone?: string;
  onSuccess?: (state: {
    phone: string;
    token: string;
    countryCode: string;
  }) => void;
};

export const AuthPhoneModalContent = ({
  countryCode,
  phone,
  onSuccess = noop,
}: Props) => {
  const { register, formState, handleSubmit, getValues, setError } =
    useForm<Schema>({
      resolver: zodResolver(schema),
      defaultValues: { countryCode, phone },
    });

  const [loading, setLoading] = useState(false);

  const errors = normalizeFormErrors<keyof Schema>(formState.errors);

  const makeCountryCodeOption = ({
    name,
    code,
  }: (typeof countryCodes)[number]) => (
    <option key={name} value={code}>
      {name} ({code})
    </option>
  );

  const handlePhoneSubmit = handleSubmit(async ({ countryCode, phone }) => {
    try {
      setLoading(true);

      const formattedPhone = formatPhone(phone);

      const { verify: token } = await authLogin(countryCode + formattedPhone);

      onSuccess({ countryCode, token, phone: formattedPhone });
    } catch (error) {
      setFormBackendErrors({ error, setError, getValues });
    } finally {
      setLoading(false);
    }
  });

  return (
    <form onSubmit={handlePhoneSubmit}>
      <p className="mb-6 text-h5">Let&apos;s make a trip together</p>
      <Field.Select
        {...register('countryCode')}
        label="Country / Region"
        className="rounded-b-none"
      >
        {countryCodes.map(makeCountryCodeOption)}
      </Field.Select>
      <Field.Input
        {...register('phone')}
        label="Phone number"
        inputMode="tel"
        type="tel"
        className="-mt-px rounded-t-none"
        error={errors.phone}
      />
      <Field.Note className="text-center">
        We&apos;ll text you to confirm your number. Standard message and data
        rates apply.&nbsp;
        <Link
          theme
          external
          blank
          className="underline"
          to={PATH.TERMS_AND_CONDITIONS}
        >
          Terms and Conditions
        </Link>
      </Field.Note>
      <Button
        loading={loading}
        type="submit"
        variant="hannah"
        className="mt-6 w-full"
      >
        Send code
      </Button>
    </form>
  );
};

import {
  faBanSmoking,
  faBolt,
  faBurger,
  faMugSaucer,
  faPaw as faPawLight,
  faSmoking,
  faSuitcase as faSuitcaseLight,
  faSuitcaseRolling as faSuitcaseRollingLight,
  faUtensilsSlash,
} from '@fortawesome/pro-light-svg-icons';
import {
  faCarSide,
  faHandWave,
  faOctagonExclamation,
  faPaw as faPawRegular,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faEnvelopeOpenText,
  faMemoCircleInfo,
  faPaperPlane,
  faPaw,
  faQuestionCircle,
  faSuitcaseRolling,
} from '@fortawesome/pro-solid-svg-icons';

import { PATH } from '@constants';

import calgaryBanffImage from '@images/destinations/calgary-banff.png';
import calgaryEdmontonImage from '@images/destinations/calgary-edmonton.png';
import kelownaVancouverImage from '@images/destinations/kelowna-vancouver.png';
import torontoMontrealImage from '@images/destinations/toronto-montreal.png';
import torontoOttawaImage from '@images/destinations/toronto-ottawa.png';
import vancouverWhistlerImage from '@images/destinations/vancouver-whistler.png';
import homeImage1 from '@images/home/home-1.png';
import homeImage2 from '@images/home/home-2.png';
import homeImage3 from '@images/home/home-3.png';

export const tripSteps = [
  PATH.TRIP_CREATING_DEPARTURE_DATE,
  PATH.TRIP_CREATING_ROUTES,
  PATH.TRIP_CREATING_WAYPOINTS,
  PATH.TRIP_CREATING_DETAILS,
  PATH.TRIP_CREATING_TARIFFS,
];

export const profileNavigation = [
  {
    icon: faSuitcaseRolling,
    title: 'My trips',
    description: 'Review all your trips as a passenger or driver',
    notification: 'trips',
    path: PATH.PASSENGER_TRIPS,
  },
  {
    icon: faEnvelopeOpenText,
    title: 'Messages',
    description: 'Open chat with a passenger or driver',
    notification: 'messages',
    path: PATH.CHAT,
  },
  {
    icon: faMemoCircleInfo,
    title: 'Terms and Conditions',
    description: 'Review terms and conditions',
    path: PATH.TERMS_AND_CONDITIONS,
  },
  {
    icon: faPaperPlane,
    title: 'Contact us',
    description: 'Let us know if you have any questions',
    path: PATH.CONTACT_US,
  },
  {
    icon: faQuestionCircle,
    title: 'Help Centre',
    description: 'Find answers to all questions',
    path: PATH.HELP_CENTRE,
  },
];

export const tripRules = {
  smoking: {
    title: 'Smoking',
    rule: 'smoking',
    rules: [
      {
        icon: faSmoking,
        title: 'Smoking',
        value: 'smoking',
      },
      {
        icon: faBolt,
        title: 'Vaping',
        value: 'vaping',
      },
      {
        icon: faBanSmoking,
        title: 'No smoking',
        value: 'no-smoking',
      },
    ],
  },
  pets: {
    title: 'Pets',
    rule: 'pets',
    rules: [
      {
        icon: faPawLight,
        title: 'Pets friendly',
        value: 'pets',
      },
      {
        icon: faPawRegular,
        title: 'Small breeds',
        value: 'small-breeds',
      },
      {
        icon: faPaw,
        title: 'No pets',
        value: 'no-pets',
      },
    ],
  },
  baggage: {
    title: 'Baggage',
    rule: 'baggage',
    rules: [
      {
        icon: faSuitcaseRolling,
        title: 'Baggage (L)',
        value: 'baggage-l',
      },
      {
        icon: faSuitcaseRollingLight,
        title: 'Baggage (M)',
        value: 'baggage-m',
      },
      {
        icon: faSuitcaseLight,
        title: 'Baggage (S)',
        value: 'baggage-s',
      },
    ],
  },
  food: {
    title: 'Food',
    rule: 'food',
    rules: [
      {
        icon: faBurger,
        title: 'Food allowed',
        value: 'food-allowed',
      },
      {
        icon: faMugSaucer,
        title: 'Pit stops',
        value: 'pit-stops',
      },
      {
        icon: faUtensilsSlash,
        title: 'No food',
        value: 'no-food',
      },
    ],
  },
};

export const popularDestinations = [
  {
    origin: 'Calgary',
    destination: 'Edmonton',
    image: calgaryEdmontonImage,
    path: 'originPlaceId=ChIJ1T-EnwNwcVMROrZStrE7bSY&destinationPlaceId=ChIJI__egEUioFMRXRX2SgygH0E&seats=1&date=tomorrow',
  },
  {
    origin: 'Calgary',
    destination: 'Banff',
    image: calgaryBanffImage,
    path: 'originPlaceId=ChIJ1T-EnwNwcVMROrZStrE7bSY&destinationPlaceId=ChIJ_UoMkUXKcFMRY5Ss2r6ur8o&seats=1&date=tomorrow',
  },
  {
    origin: 'Vancouver',
    destination: 'Whistler',
    image: vancouverWhistlerImage,
    path: 'originPlaceId=ChIJs0-pQ_FzhlQRi_OBm-qWkbs&destinationPlaceId=ChIJh3uVA7I8h1QR9s_1dehBt0o&seats=1&date=tomorrow',
  },
  {
    origin: 'Kelowna',
    destination: 'Vancouver',
    image: kelownaVancouverImage,
    path: 'originPlaceId=ChIJszDH47aMfVMR98S02j3l-E4&destinationPlaceId=ChIJs0-pQ_FzhlQRi_OBm-qWkbs&seats=1&date=tomorrow',
  },
  {
    origin: 'Toronto',
    destination: 'Ottawa',
    image: torontoOttawaImage,
    path: 'originPlaceId=ChIJpTvG15DL1IkRd8S0KlBVNTI&destinationPlaceId=ChIJrxNRX7IFzkwR7RXdMeFRaoo&seats=1&date=tomorrow',
  },
  {
    origin: 'Toronto',
    destination: 'Montreal',
    image: torontoMontrealImage,
    path: 'originPlaceId=ChIJpTvG15DL1IkRd8S0KlBVNTI&destinationPlaceId=ChIJDbdkHFQayUwR7-8fITgxTmU&seats=1&date=tomorrow',
  },
];

export const helpCentre = [
  {
    title: "I'm a driver",
    icon: faCarSide,
    content: [
      {
        title: 'Am I required to make every stop listed on my trip itinerary?',
        text: [
          'When you post a trip with scheduled stops, you tell passengers that you are willing to make those stops and pick them up along the way. This also increases the visibility of your trip in search results, leading to more bookings and filled seats',
          'In such cases where the requested partial booking is too inconvenient, you can simply decline that booking request',
        ],
      },
      {
        title: 'Can I use Beeple with a rental car?',
        text: 'Yes, you can. But we advise verifying with your car rental company and insurance provider that carpooling is permitted with the rented vehicle',
      },
      {
        title: 'Can I travel with pets?',
        text: [
          'Pets are allowed to trip if:',
          'Accompanied by their owner',
          'Allowed by the driver — specified on their ride details',
          "Before booking, message the driver to let them know you'd like to travel with your pet",
          "If your pet is large or travels in a cage, you may need to book another seat. It’s the pet owner's responsibility to check and bring the necessary travel equipment and health documents",
          'View pet friendly rides only, by filtering your search result with “Pets allowed”',
        ],
      },
    ],
  },
  {
    title: "I'm a passenger",
    icon: faHandWave,
    content: [
      {
        title: 'A city is not recognised?',
        text: 'This is likely because you did not select the city from the drop-down menu. When you start typing the name of a town, our system will automatically propose corresponding results, meaning you can choose your city of choice',
      },
      {
        title: "Why my ride's price may look different?",
        text: [
          'The price displayed for your publications in search results can vary based on the following factors:',
          'Service fee: The listed price for publications includes a breakdown of the base cost plus any applicable service fees',
          'Way-points: Publications are shown separately for each way-point',
        ],
      },
    ],
  },
  {
    title: 'Cancelation',
    icon: faXmark,
    content: [
      {
        title: 'Refunding',
        text: [
          'If the driver cancels a booking, the Passenger is refunded the whole of the sum paid (i.e. the related Service Fees)',
          'If the passenger cancels more than 24 hours before the planned departure, the Passenger shall be refunded half of the Service Fees',
          'If the passenger cancels in the last 24 hours before departure, the Passenger shall be not refunded the Service Fees',
        ],
      },
      {
        title: 'My passenger canceled or didn’t show up',
        text: [
          'If a passenger cancels, we automatically make the seat available again. You’ll get another booking',
          'If someone didn’t show up, you can open a claim by heading to your ride, selecting the passenger and reporting that he/she didn’t show up',
        ],
      },
      {
        title: 'If a driver canceled or didn’t show up',
        text: [
          'If a driver canceled the trip, passengers will be automatically refunded the service fee',
          'If a driver didn’t show up, you need to report that you didn’t travel together within 24 hours of the departure time. Go to My trips > Select the trip > Report > Describe the reason for reporting. After that, you will be automatically refunded the service fee',
        ],
      },
    ],
  },
  {
    title: 'Delete account',
    icon: faOctagonExclamation,
    content: [],
  },
];

export const birthdayOption = {
  months: [
    { value: '01', text: 'January' },
    { value: '02', text: 'February' },
    { value: '03', text: 'March' },
    { value: '04', text: 'April' },
    { value: '05', text: 'May' },
    { value: '06', text: 'June' },
    { value: '07', text: 'July' },
    { value: '08', text: 'August' },
    { value: '09', text: 'September' },
    { value: '10', text: 'October' },
    { value: '11', text: 'November' },
    { value: '12', text: 'December' },
  ],
  days: [
    { value: '01', text: '1' },
    { value: '02', text: '2' },
    { value: '03', text: '3' },
    { value: '04', text: '4' },
    { value: '05', text: '5' },
    { value: '06', text: '6' },
    { value: '07', text: '7' },
    { value: '08', text: '8' },
    { value: '09', text: '9' },
    { value: '10', text: '10' },
    { value: '11', text: '11' },
    { value: '12', text: '12' },
    { value: '13', text: '13' },
    { value: '14', text: '14' },
    { value: '15', text: '15' },
    { value: '16', text: '16' },
    { value: '17', text: '17' },
    { value: '18', text: '18' },
    { value: '19', text: '19' },
    { value: '20', text: '20' },
    { value: '21', text: '21' },
    { value: '22', text: '22' },
    { value: '23', text: '23' },
    { value: '24', text: '24' },
    { value: '25', text: '25' },
    { value: '26', text: '26' },
    { value: '27', text: '27' },
    { value: '28', text: '28' },
    { value: '29', text: '29' },
    { value: '30', text: '30' },
    { value: '31', text: '31' },
  ],
  /**
   * Generates year set from now to 100 years old
   */
  years: (() => {
    const currentYear = new Date().getFullYear();

    return Array.from({ length: 101 }, (_, i) => {
      const value = currentYear - i;

      return {
        value,
        text: value,
      };
    });
  })(),
};

export const homeSteps = [
  {
    title: 'Pick the driver',
    text: 'Find the perfect ride from our range of destinations',
    image: homeImage1,
  },
  {
    title: 'Book a seat',
    text: 'Select 1, 2 or 3 seats for you and your friends',
    image: homeImage2,
  },
  {
    title: 'Discover new cities',
    text: 'New cities at small prices with Beeple',
    image: homeImage3,
  },
];

export const countryCodes = [
  { name: 'Canada', code: '+1' },
  { name: 'United States', code: '+1' },
  { name: 'United Kingdom', code: '+44' },
  { name: 'Argentina', code: '+54' },
  { name: 'Australia', code: '+61' },
  { name: 'Austria', code: '+43' },
  { name: 'Bangladesh', code: '+880' },
  { name: 'Belgium', code: '+32' },
  { name: 'Brazil', code: '+55' },
  { name: 'Bulgaria', code: '+359' },
  { name: 'Chile', code: '+56' },
  { name: 'China', code: '+86' },
  { name: 'Colombia', code: '+57' },
  { name: 'Croatia', code: '+385' },
  { name: 'Cyprus', code: '+357' },
  { name: 'Czech Republic', code: '+420' },
  { name: 'Denmark', code: '+45' },
  { name: 'Egypt', code: '+20' },
  { name: 'Estonia', code: '+372' },
  { name: 'Finland', code: '+358' },
  { name: 'France', code: '+33' },
  { name: 'Germany', code: '+49' },
  { name: 'Greece', code: '+30' },
  { name: 'Hungary', code: '+36' },
  { name: 'Iceland', code: '+354' },
  { name: 'India', code: '+91' },
  { name: 'Indonesia', code: '+62' },
  { name: 'Ireland', code: '+353' },
  { name: 'Israel', code: '+972' },
  { name: 'Italy', code: '+39' },
  { name: 'Japan', code: '+81' },
  { name: 'Latvia', code: '+371' },
  { name: 'Lithuania', code: '+370' },
  { name: 'Luxembourg', code: '+352' },
  { name: 'Malaysia', code: '+60' },
  { name: 'Malta', code: '+356' },
  { name: 'Mexico', code: '+52' },
  { name: 'Netherlands', code: '+31' },
  { name: 'New Zealand', code: '+64' },
  { name: 'Nigeria', code: '+234' },
  { name: 'Norway', code: '+47' },
  { name: 'Pakistan', code: '+92' },
  { name: 'Peru', code: '+51' },
  { name: 'Philippines', code: '+63' },
  { name: 'Poland', code: '+48' },
  { name: 'Portugal', code: '+351' },
  { name: 'Romania', code: '+40' },
  { name: 'Saudi Arabia', code: '+966' },
  { name: 'Singapore', code: '+65' },
  { name: 'Slovakia', code: '+421' },
  { name: 'Slovenia', code: '+386' },
  { name: 'South Africa', code: '+27' },
  { name: 'South Korea', code: '+82' },
  { name: 'Spain', code: '+34' },
  { name: 'Sweden', code: '+46' },
  { name: 'Switzerland', code: '+41' },
  { name: 'Thailand', code: '+66' },
  { name: 'Turkey', code: '+90' },
  { name: 'Ukraine', code: '+380' },
  { name: 'United Arab Emirates', code: '+971' },
  { name: 'Venezuela', code: '+58' },
  { name: 'Vietnam', code: '+84' },
];

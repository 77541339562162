import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { analytics, googlemaps, sentry } from '@features';
import { store } from '@store';

import { Application } from '@components/Application';
import { FallbackError } from '@components/Ui';

import '@features/dayjs.feature';

import './globals.css';

analytics.init();
googlemaps.init();
sentry.init();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<FallbackError />}>
      <Provider store={store}>
        <BrowserRouter>
          <Application />
        </BrowserRouter>
      </Provider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
);

import { cn } from '@utils';

type Props = {
  required?: boolean;
  disabled?: boolean;
  for?: string;
  className?: string;
  children: React.ReactNode;
};

export const FieldLabel = ({
  required = false,
  disabled,
  for: htmlFor,
  className,
  children,
}: Props) => {
  if (!children) {
    return null;
  }

  return (
    <label
      className={cn(
        'absolute start-4 top-4 z-20 origin-[0] -translate-y-4 scale-75 text-body-1-r leading-normal text-neural-03 transition-all peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:text-primary',
        className,
        {
          'required-label': required,
          'text-neural-02': disabled,
        },
      )}
      htmlFor={htmlFor}
    >
      {children}
    </label>
  );
};

FieldLabel.displayName = 'Field.Label';

import { useState } from 'react';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { faCaretDown, faPaperPlane } from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';

import { PATH } from '@constants';
import { isAuthenticatedSelector } from '@selectors';
import { useSelector } from '@hooks';
import { cn, ensureArray } from '@utils';
import { helpCentre } from '@content';

import { Button, Icon, Link, Modal, Render, Theme } from '@components';
import { Layout } from '@components/Layouts';
import { BackButton, DeleteAccountModal } from '@components/Ui';

export const HelpCentrePage = () => {
  const isAuthenticated = useSelector(isAuthenticatedSelector);

  const DANGER_ZONE_ID = 3;
  const [categoryId, setCategoryId] = useState(0);
  const [contentId, setContentId] = useState<number | null>(null);

  const { content } = helpCentre[categoryId];

  const handleContentChange = (id: number) => {
    const value = contentId === id ? null : id;

    setContentId(value);
  };

  const handleCategoryChange = (id: number) => {
    if (categoryId !== id) {
      setCategoryId(id);
      setContentId(null);
    }
  };

  const makeText = (text: string) => <p key={text}>{text}</p>;

  const makeContent = (
    { title, text }: { title: string; text: string | string[] },
    index: number,
  ) => {
    const isActive = contentId === index;
    const ensuredText = ensureArray(text);

    return (
      <Button
        theme
        key={title}
        className={clsx(
          'min-h-[44px] gap-2 rounded-[10px] px-3 py-2 text-14 text-primary shadow-[0px_0px_8px_0px_#EBEBEB] transition-shadow',
          { 'hover:shadow-[0px_0px_8px_4px_#EBEBEB]': !isActive },
        )}
        onClick={() => handleContentChange(index)}
      >
        <p
          className={cn(
            'flex min-h-[28px] items-center gap-3 text-start text-14 text-primary transition-colors group-hover:text-hannah',
            { 'text-hannah': isActive },
          )}
        >
          <Icon
            icon={faCaretDown}
            className={clsx('h-5 w-5 text-16 transition-transform', {
              '-rotate-90': !isActive,
            })}
          />
          {title}
        </p>
        <Render if={isActive}>
          <div className="flex flex-col gap-2 overflow-hidden py-2 pl-8 text-left text-14 text-secondary">
            {ensuredText.map(makeText)}
          </div>
        </Render>
      </Button>
    );
  };

  const makeCategory = (
    { title, icon }: (typeof helpCentre)[number],
    index: number,
  ) => {
    const isDangerZoneForUnauthicatedUser =
      index === DANGER_ZONE_ID && !isAuthenticated;

    return (
      <Button
        theme
        disabled={isDangerZoneForUnauthicatedUser}
        key={title}
        className={cn(
          'flex h-11 items-center gap-3 rounded-[10px] px-3 py-2 text-14 text-primary shadow-[0px_0px_8px_0px_#EBEBEB] transition-all hover:translate-x-1',
          {
            'text-hannah': categoryId === index,
            'text-secondary opacity-50': isDangerZoneForUnauthicatedUser,
          },
        )}
        onClick={() => handleCategoryChange(index)}
      >
        <Icon icon={icon} className="text-20" />
        {title}
        <Icon icon={faChevronRight} className="ml-auto text-18" />
      </Button>
    );
  };

  const renderContent = () => {
    if (categoryId === DANGER_ZONE_ID) {
      return (
        <Theme.Container key={DANGER_ZONE_ID} className="p-6">
          <h4 className="mb-4 text-24 font-600 text-hannah">Delete account</h4>
          <p className="text-16">
            Once you delete an account, there is no going back. Please be
            certain
          </p>
          <p className="mt-2 text-14 text-secondary">
            Cancel your trips and bookings first
          </p>
          <div className="mt-8 flex justify-end">
            <Modal content={<DeleteAccountModal />}>
              <Button variant="hannah">Delete account</Button>
            </Modal>
          </div>
        </Theme.Container>
      );
    }

    return content.map(makeContent);
  };

  return (
    <Layout>
      <div className="mb-20 mt-6 flex items-center gap-3 md:my-6">
        <BackButton />
        <p className="text-18 font-600 text-eva">Help centre</p>
      </div>
      <div className="flex gap-10 px-28 hd:px-10 md:gap-4 md:px-2 sm:flex-col">
        <div className="flex basis-80 flex-col gap-3 md:basis-60">
          {helpCentre.map(makeCategory)}
          <Link
            theme
            to={PATH.CONTACT_US}
            className="group mt-10 flex items-center gap-4 rounded-[20px] p-5 shadow-[2px_2px_12px_1px_#EBEBEB] md:mt-4 md:gap-2 md:p-3 sm:mt-0 sm:rounded-xl"
          >
            <Icon icon={faPaperPlane} className="text-hannah" />
            <div>
              <p className="text-14 font-600 text-primary transition-colors group-hover:text-hannah">
                Contact us
              </p>
              <p className="mt-1 text-10 text-secondary md:mt-0">
                Let us know if you still have questions
              </p>
            </div>
          </Link>
        </div>
        <div className="flex flex-1 flex-col gap-3">{renderContent()}</div>
      </div>
    </Layout>
  );
};

/* eslint-disable */
import { Layout } from '@components/Layouts';
import { PrivacyNavigationHeader } from '@components/Ui';

import '../../terms-page.css';

export const TermsConditionsPage = () => {
  return (
    <Layout>
      <PrivacyNavigationHeader />
      <div className="terms-page py-6">
        <div>
          <h1>Terms and Conditions</h1>
          <h2>1.    Subject</h2>

          <p>Beeple Inc. (referred to as &quot;Beeple&quot;) has created a ridesharing platform available at www.beeple.ca or via a mobile app. This platform connects drivers heading to a destination with passengers going in the same direction to share the trip and associated costs (the &quot;Platform&quot;). These terms and conditions govern the access and usage of the Platform. By clicking &ldquo;Log in / Sign up,&rdquo; you acknowledge having read and accepted all these terms. Beeple is not a party to any agreements between Platform members.</p>

          <h2>2.    Definitions</h2>

         <ul>
            <li>Account: The account created to become a Member and access certain Platform services.</li>
            <li>Advert: An advertisement about a Trip posted by a Driver on the Platform.</li>
            <li>Booking: Defined in Article 4.2.</li>
            <li>Booking Confirmation: Defined in Article 4.2.1.</li>
            <li>Carpooling Advert: An advertisement about a Ride posted by a Driver.</li>
            <li>Carpooling Trip: The journey in an Advert where the Driver agrees to transport Passengers for a Cost Contribution.</li>
            <li>Cost Contribution: The amount requested by the Driver from the Passenger for their share of travel costs.</li>
            <li>Driver: A Member offering to transport another individual for a Cost Contribution.</li>
            <li>Stripe: The payment solution used on the Platform.</li>
            <li>Member: Any individual who has created an Account on the Platform.</li>
            <li>Passenger: The Member who accepts the offer to be transported by the Driver.</li>
            <li>Platform: Defined in Article 1.</li>
            <li>Seat: The seat booked by a Passenger in the Driver&rsquo;s vehicle.</li>
            <li>Services: All services provided by Beeple via the Platform.</li>
            <li>Service Fee: Defined in Article 5.2.</li>
            <li>Waypoint: Defined in Article 4.1.</li>
            <li>Trip: Refers to a Carpooling Trip.</li>
            <li>T&amp;Cs: These Terms and Conditions.</li>
            <li>Website: www.beeple.ca.</li>
        </ul>



          <h2>3.    Registration on the Platform and creation of an Account</h2>

          <p>3.1. Conditions of registration on the Platform</p>
          <p>The Platform is for individuals aged 18 or over. Registration by minors is prohibited.</p>

          <p>3.2. Creation of an Account</p>
          <p>The Platform enables Members to post Carpooling Adverts, view Adverts, and interact with each other to book a Seat. You can view the Adverts without being registered on the Platform. However, you cannot post a Carpooling Advert or book a Seat without first creating an Account and becoming a Member. To create your Account, you can:</p>
          <ol>
              <li>complete and confirm your phone number;</li>
              <li>complete all mandatory fields on the registration form;</li>
          </ol>
          <p>To register on the Platform, you must read and accept these T&amp;Cs and the Privacy Policy. When creating your Account, regardless of the method chosen, you agree to provide accurate and true information, and to update it through your profile or by notifying Beeple Inc., in order to ensure its relevance and accuracy throughout your contractual relationship with Beeple Inc. You agree not to create or use, under your own identity or that of a third party, Accounts other than the one initially created.</p>

          <p>3.3. Verification</p>
          <p>Beeple Inc. may, for the purposes of transparency, improving trust, or preventing and detecting fraud, set up a system for verifying some of the information you provide on your profile. This is particularly the case when you enter your telephone number, email address, or provide us with an identity document. You recognize and accept that any reference on the Platform or the Services to &ldquo;verified&rdquo; information, or any similar term, means only that a Member has successfully passed the verification procedure existing on the Platform or the Services in order to provide you with more information about the Member with whom you are considering traveling. Beeple Inc. cannot guarantee the truthfulness, reliability, or validity of the information subject to the verification procedure.</p>

          <h2>4.    Use of the Services</h2>

          <p>4.1. Posting Adverts</p>
          <p>As a Member, and provided you fulfill the conditions below, you can create and post Carpooling Adverts on the Platform by entering information about the Trip you intend to make (dates/times, collection and arrival points, number of seats offered, options available, amount of the Cost Contribution, etc.).</p>
          <p>When posting your Carpooling Advert, you can indicate the milestone cities where you agree to stop to pick up or drop off Passengers. The sections of the Carpooling Trip between these milestone cities or between one of these milestone towns and the collection point or destination of the Carpooling Trip constitute &ldquo;Waypoints.&rdquo;</p>
          <p>You are only authorized to post a Carpooling Advert if you fulfill all the following conditions:</p>
          <ol>
              <li>You hold a valid driving license;</li>
              <li>You only offer Carpooling Adverts for vehicles you own or use with the express permission of the owner, and in all cases that you are authorized to use for the purposes of ridesharing;</li>
              <li>You are and remain the main driver of the vehicle subject to the Carpooling Advert;</li>
              <li>The vehicle has valid third-party insurance;</li>
              <li>You have no contraindication or medical incapacity for driving;</li>
              <li>The vehicle you intend to use for the Trip is a touring car with 4 wheels and a maximum of 7 seats, excluding cars requiring no license to drive;</li>
              <li>You do not intend to post another advert for the same Carpooling Trip on the Platform;</li>
              <li>You do not offer more Seats than the number available in your vehicle;</li>
              <li>All Seats offered have a seatbelt, even if the vehicle is approved with seats having no seatbelt;</li>
              <li>You use a vehicle in good working order that complies with applicable legal provisions and customs, notably with an up-to-date Motor Vehicle Safety certificate;</li>
              <li>You are a consumer and do not act as a professional.</li>
          </ol>
          <p>You recognize that you are solely responsible for the content of the Carpooling Advert you post on the Platform. Consequently, you represent and warrant the accuracy and truthfulness of all information contained in your Carpooling Advert, and you undertake to fulfill the Trip under the conditions described in your Carpooling Advert.</p>
          <p>Your Carpooling Advert will be posted on the Platform and therefore visible to Members and all visitors, even non-members, conducting a search on the Platform or on the website of Beeple Inc.'s partners. Beeple Inc. reserves the right, at its sole discretion and without notice, to not post or to remove, at any time, any Carpooling Advert that does not comply with the T&amp;Cs or that it considers damaging to its image, that of the Platform, or that of the Services.</p>
          <p>You are informed that in the event that you present yourself as a consumer by using the Platform when in fact you act as a professional, you are exposed to sanctions provided by the applicable law.</p>


          <p>4.2. Booking a Seat when carpooling</p>
          <p>Beeple Inc. has set up a system for booking Seats online (&quot;Booking&quot;) for Trips offered on the Platform. The methods of booking a Seat depend on the nature of the Trip envisaged.</p>
          <p>Beeple Inc. provides users on the Platform with a search engine based on different search criteria (place of origin, destination, dates, number of travelers, etc.). Certain additional functionalities are provided on the search engine when the user is connected with his/her Account. Beeple Inc. invites the user, regardless of the booking process used, to carefully consult and use the search engine to determine the offer that is most adapted to his/her needs.</p>

          <p>4.2.1 Carpooling Trip</p>
          <p>When a Passenger is interested in a Carpooling Advert that offers Booking, they can submit an online Booking request. This Booking request can either be:</p>
          <ol>
              <li>automatically accepted (if the Driver has selected this option when posting the Advert) or</li>
              <li>manually accepted by the Driver.</li>
          </ol>
          <p>At the time of Booking, the Passenger pays the Service Fees online, if applicable. Once Beeple Inc. receives the payment and the Driver confirms the Booking request, the Passenger will receive a confirmation of their Booking (the &quot;Booking Confirmation&quot;).</p>

          <p>If you are a Driver and have opted to manually handle Booking requests when posting your Advert, you must respond to each Booking request. If you do not respond, the Booking request will automatically expire, and any payments made by the Passenger during the Booking request will be refunded.</p>
          <p>Upon Booking Confirmation, Beeple Inc. will provide the telephone number of the Driver (to the Passenger) or the Passenger (to the Driver). From that point on, you are solely responsible for fulfilling the agreement with the other Member.</p>

          <p>4.2.3 Specific Nature of Seat Booking and Use of Services on Behalf of Third Parties</p>
          <p>Any use of the Services, whether as a Passenger or Driver, must be under a specific name. The identity of the Driver and the Passenger must match the information provided to Beeple Inc. and other Members involved in the Carpooling Trip.</p>
          <p>However, Beeple Inc. allows Members to book one or more Seats on behalf of another person. In this case, you must accurately provide the Driver with the first names, age, and telephone number of the person for whom you are booking a Seat at the time of the Booking. It is strictly prohibited to book a Seat for a minor under the age of 16 traveling alone for a Carpooling Trip. If you are booking a Seat for a minor over the age of 16 traveling alone, you must obtain prior consent from the Driver and provide a completed and signed permission form from the minor's legal guardians.</p>
          <p>Additionally, the Platform is intended for individual Seat bookings only. Booking a Seat for transporting any object, package, animal traveling alone, or other material items is not allowed. Furthermore, it is prohibited to post a Carpooling Advert for a Driver other than yourself.</p>

          <p>4.3. Review system</p>
          <p>4.3.1. Operation</p>
          <p>Beeple Inc. encourages you to leave a review about a Driver (if you are a Passenger) or a Passenger (if you are a Driver) with whom you have shared or were supposed to share a Trip. However, you cannot leave a review about another Passenger if you were also a Passenger, or about a Member with whom you have not traveled or were not supposed to travel. Reviews, including those left about you, will only be visible and published after the Member posts them.</p>

          <p>4.3.2. Moderation</p>
          <p>You acknowledge and agree that Beeple Inc. reserves the right to withhold or remove any review, question, comment, or response if it considers the content to be in violation of these T&amp;Cs.</p>

          <p>4.3.3. Limits</p>
          <p>Beeple Inc. reserves the right to suspend your Account, restrict your access to the Services, or terminate these T&amp;Cs if:</p>
          <ol>
              <li>you have received at least three reviews and</li>
              <li>your average rating is less than or equal to 3.</li>
          </ol>


          <h2>5.    Financial conditions</h2>

          <p>Accessing and registering on the Platform, as well as searching for, viewing, and posting Adverts, are free of charge. However, a fee is applied for Booking, as outlined in the conditions below.</p>

          <p>5.1. Determining the Cost Contribution</p>
          <p>As the Driver, you are solely responsible for setting the Cost Contribution for a Carpooling trip. It is strictly forbidden to use our Platform for profit. Therefore, you must ensure that the Cost Contribution requested from your Passengers reflects only the actual costs you incur for the Carpooling Trip. Failure to comply with this may result in you bearing the full risk of recharacterization of the transaction conducted via the Platform.</p>
          <p>When you post a Carpooling Advert, Beeple Inc. will provide a suggested amount for the Cost Contribution based on factors such as the nature of the Carpooling Trip and the distance traveled. This suggested amount is purely advisory, and you have the discretion to adjust it to better reflect your actual costs. To prevent abuse, Beeple Inc. imposes limits on how much you can adjust the Cost Contribution.</p>

          <p>5.2. Service Fees</p>
          <p>Beeple Inc. collects service fees (referred to as the &quot;Service Fees&quot;) at the time of Booking in exchange for using the Platform. Users will be informed about any applicable Service Fees before they are charged. Beeple Inc. reserves the right to change the methods of calculating the Service Fees at any time. These changes will not affect the Service Fees accepted by users prior to the effective date of the modifications.</p>
          <p>For cross-border journeys, please be aware that the calculation methods for the Service Fees and applicable GST (and QST, where applicable) may vary depending on the collection point and/or destination of the Trip.</p>
          <p>When using the Platform for cross-border trips or trips outside of Canada, the Service Fees may be charged by an affiliate company of Beeple Inc. operating the local platform, if applicable.</p>

          <p>5.3. Rounding</p>
          <p>You acknowledge and agree that Beeple Inc. may, at its sole discretion, round the Service Fees and the Cost Contribution up or down to the nearest whole number or decimal.</p>

          <p>5.4. Methods of Payment</p>

          <p>5.4.1. Payment of the Cost Contribution to the Driver</p>
          <p>By using the Platform as a Driver for Carpooling Trips, you confirm that you have read and accepted these terms and conditions. As a Driver, you agree that:</p>
          <p>In the context of a Carpooling Trip, and after the manual or automatic acceptance of the Booking, (i) the Service Fee is paid by the Passenger to Beeple Inc., and (ii) the Cost Contribution is made directly to you by the Passenger in the form agreed upon in advance. Beeple Inc. has no involvement in the payment or non-payment between the Driver and Passenger.</p>

          <p>5.4.2 Payment of the Service Fee</p>
          <p>Payment for any Order made through the Platform must be completed using one of the authorized payment methods listed below. The authorized payment methods are:</p>
          <ul>
              <li>Bank cards</li>
              <li>Vouchers</li>
              <li>Apple Pay, Google Pay</li>
          </ul>
          <p>No Order confirmation will be issued until the full and effective payment of the Trip&rsquo;s Service Fee is received from the Passenger. If the payment is irregular, incomplete, or not made for any reason attributable to the Passenger, the Order will be canceled immediately.</p>
          <p>The Order is confirmed by sending an email to the Passenger containing the Order details. Passengers are advised to check their email inbox settings to ensure that the confirmation email is not sent to the Spam folder.</p>
          <p>Order confirmation is final. Therefore, any changes to the Order will result in either an exchange or cancellation under the conditions of the applicable General Terms and Conditions of Sales. It is the Passenger&rsquo;s responsibility to ensure that the chosen Trip meets their needs and expectations. The accuracy of the Personal Information entered by the Passenger is their responsibility, except in the event of Beeple Inc.'s proven failure to collect, store, or protect such Personal Information.</p>

          <h2>6. Non-Commercial and Non-Business Use of the Services and the Platform</h2>


          <p>You agree to use the Services and the Platform solely to connect, on a non-commercial and non-business basis, with individuals who wish to share a Carpooling Trip with you.</p>

          <p>As a Driver, you agree not to request a Cost Contribution higher than the actual costs you incur, ensuring that no profit is generated. In the context of cost sharing, you must cover your own portion of the Trip's costs. You are solely responsible for accurately calculating your incurred costs and ensuring that the amount requested from Passengers does not exceed these costs (excluding your share), by referring to the applicable tax scale if needed.</p>

          <p>Beeple Inc. reserves the right to suspend your Account if you use a chauffeur-driven or other business vehicle, taxi, or company car and generate a profit from the Platform. You agree to provide Beeple Inc., upon request, with a copy of your car registration certificate and/or any other document proving you are authorized to use the vehicle on the Platform and that you are not profiting from it.</p>

          <p>Beeple Inc. also reserves the right to suspend your Account, limit your access to the Services, or terminate these T&amp;Cs if your activity on the Platform, due to the nature of the Trips offered, their frequency, the number of Passengers carried, and the Cost Contribution requested, results in a profit for you or if there is any indication that you are generating profit on the Platform.</p>


          <h2>7.    Cancellation policy</h2>

          <p>7.1. Terms of Refund in Case of Cancellation</p>
          <p>This cancellation policy applies solely to Carpooling Trips; Beeple Inc. does not offer any guarantees of any nature in case of cancellations for any reason.</p>
          <p>Cancellation of a Seat on a Carpooling Trip by the Driver or the Passenger after Booking Confirmation is subject to the following conditions:</p>
          <ul>
              <li>Cancellation by the Driver: If the Driver cancels the Carpooling Trip or fails to arrive at the meeting point within 30 minutes of the agreed time, the Passenger will be refunded the entire amount paid, including the related Service Fees.</li>
              <li>Cancellation by the Passenger:
                  <ul>
                      <li>More than 24 hours before departure: If the Passenger cancels more than 24 hours before the planned departure time as mentioned in the Carpooling Advert, the Passenger will be refunded half of the Service Fees, with the remaining half retained by Beeple Inc. The Driver will not receive any payment.</li>
                      <li>24 hours or less before departure: If the Passenger cancels 24 hours or less before the planned departure time, the Passenger will not be refunded, and the Service Fees will be retained by Beeple Inc. The Driver will not receive any payment.</li>
                      <li>After the planned departure time: If the Passenger cancels after the planned departure time or fails to arrive at the meeting place 15 minutes before the agreed time, no refund will be issued. The Driver will not receive any payment.</li>
                  </ul>
              </li>
          </ul>
          <p>When a cancellation occurs before departure due to the Passenger, the canceled Seat(s) will be automatically made available to other Passengers for booking online, subject to these T&amp;Cs.</p>
          <p>Beeple Inc. will, at its sole discretion, assess the legitimacy of refund requests based on the available information.</p>

          <p>7.2. Right of Withdrawal</p>
          <p>You have no right of withdrawal from the time of Booking Confirmation, provided the Contract between you and Beeple Inc. for connecting you with another Member has been fully executed.</p>


          <h2>8. Behavior of Users of the Platform and Members</h2>

          
          <p>8.1. Undertakings of All Users of the Platform</p>
              <ul>
                  <li>You acknowledge that you are solely responsible for complying with all laws, regulations, and obligations applicable to your use of the Platform. Additionally, when using the Platform and during Trips, you agree to:</li>
                  <li>(i) Refrain from using the Platform for professional, commercial, or profit-making purposes;</li>
                  <li>(ii) Avoid sending Beeple Inc. (notably during Account creation or updates) or other Members any false, misleading, malicious, or fraudulent information;</li>
                  <li>(iii) Refrain from speaking, behaving, or posting any content on the Platform that is defamatory, injurious, obscene, pornographic, vulgar, offensive, aggressive, unnecessary, violent, threatening, harassing, racist, xenophobic, sexually explicit, incites violence, discrimination, or hatred, encourages illegal activities or substances, or generally contradicts the Platform's purposes, infringes the rights of Beeple Inc. or a third party, or is contrary to good morals;</li>
                  <li>(iv) Respect the rights and image of Beeple Inc., particularly its intellectual property rights;</li>
                  <li>(v) Not create more than one Account on the Platform or create an Account in someone else’s name;</li>
                  <li>(vi) Not attempt to bypass the Platform’s online booking system, such as by sending your contact details to another Member to book outside the Platform and avoid paying Service Fees;</li>
                  <li>(vii) Not contact another Member, especially via the Platform, for purposes other than arranging the terms of ridesharing;</li>
                  <li>(viii) Comply with these T&amp;Cs and the Privacy Policy.</li>
              </ul>
          
          <p>8.2. Undertakings of the Drivers</p>
              <ul>
                  <li>Furthermore, when using the Platform as a Driver, you agree to:</li>
                  <li>(i) Comply with all laws, regulations, and codes related to driving and the vehicle, including holding valid liability insurance and a valid driving license;</li>
                  <li>(ii) Ensure your insurance covers ridesharing and that your Passengers are considered third parties in your vehicle and are thus covered by your insurance;</li>
                  <li>(iii) Avoid taking any risks while driving and abstain from using any substances that may impair your attention and ability to drive safely;</li>
                  <li>(iv) Post Carpooling Adverts only for Trips you actually plan to take;</li>
                  <li>(v) Conduct the Carpooling Trip as described in the Advert (including whether or not to use the motorway) and respect the agreed times and places with the other Members (including the meeting place and drop-off point);</li>
                  <li>(vi) Not take more Passengers than the number of Seats indicated in the Carpooling Advert;</li>
                  <li>(vii) Use a vehicle in good working order that complies with applicable legal provisions and customs, including having an up-to-date Motor Vehicle Safety certificate;</li>
                  <li>(viii) Provide Beeple Inc. or any Passenger who requests it with your driving license, car registration certificate, insurance certificate, Motor Vehicle Safety certificate, and any document proving your capacity to use the vehicle as a Driver on the Platform;</li>
                  <li>(ix) Inform your Passengers promptly in case of any delay or change to the time or the Carpooling Trip;</li>
                  <li>(x) For cross-border Carpooling Trips, hold and make available to Passengers and any authorities any documents proving your identity and right to cross the border;</li>
                  <li>(xi) Wait for Passengers at the agreed meeting place for at least 15 minutes after the agreed time;</li>
                  <li>(xii) Not post a Carpooling Advert for a vehicle you do not own or are not authorized to use for ridesharing;</li>
                  <li>(xiii) Ensure you can be contacted by your Passengers by phone on the number registered on your profile;</li>
                  <li>(xiv) Not generate any profit through the Platform;</li>
                  <li>(xv) Have no medical conditions or contraindications that impair your ability to drive;</li>
                  <li>(xvi) Behave appropriately and responsibly during the Carpooling Trip and adhere to the spirit of ridesharing.</li>
              </ul>
          
          <p>8.3. Undertakings of the Passengers</p>
              <ul>
                  <li>8.3.1 For Carpooling Trips</li>
                  <li>When using the Platform as a Passenger for a Carpooling Trip, you agree to:</li>
                  <li>(i) Exhibit appropriate behavior during the Carpooling Trip to avoid distracting the Driver or disturbing other Passengers;</li>
                  <li>(ii) Respect the Driver’s vehicle and maintain its cleanliness;</li>
                  <li>(iii) Inform the Driver promptly in case of any delays;</li>
                  <li>(iv) Wait for the Driver at the meeting place for at least 30 minutes beyond the agreed time;</li>
                  <li>(v) Provide Beeple Inc., or any Driver who requests it, with your identity card or any document verifying your identity;</li>
                  <li>(vi) Refrain from carrying any item, goods, substance, or animal during the Carpooling Trip that could impede the Driver’s concentration or violate any legal provisions;</li>
                  <li>(vii) For cross-border Carpooling Trips, have and keep available to the Driver and any relevant authorities any documents proving your identity and right to cross the border;</li>
                  <li>(viii) Ensure you can be contacted by your Driver by phone on the number registered on your profile, including at the meeting point.</li>
              </ul>
              <p>If you have booked one or more Seats on behalf of third parties, in compliance with Article 4.2.3, you guarantee that these third parties will adhere to the stipulations of this article and the T&amp;Cs. Beeple Inc. reserves the right to suspend your Account, limit your access to the Services, or terminate these T&amp;Cs if the third party for whom you booked a Seat breaches these T&amp;Cs.</p>

          <h2>9. Suspension of Accounts, Limitation of Access, and Termination</h2>


          <p>You may terminate your contractual relationship with Beeple Inc. at any time, without cost or reason. To do this, simply go to the &quot;Close account&quot; tab on your Profile page under Terms and Conditions.</p>

          <p>In the event of:</p>
          <ul>
              <li>(i) a breach by you of these T&amp;Cs, including but not limited to your obligations as a Member mentioned in Articles 6 and 8 above,</li>
              <li>(ii) exceeding the limit set out in Article 4.3.3 above, or</li>
              <li>(iii) if Beeple Inc. has a genuine reason to believe that it is necessary to protect its security and integrity, that of the Members or third parties, or for the purpose of fraud prevention or investigations, Beeple Inc. reserves the right to:</li>
          </ul>
          <ul>
              <li>(i) Terminate the T&amp;Cs binding you to Beeple Inc. immediately and without notice; and/or</li>
              <li>(ii) Prevent the posting of or remove any review, Advert, message, content, Booking request, or any content you have posted on the Platform; and/or</li>
              <li>(iii) Limit your access to and use of the Platform; and/or</li>
              <li>(iv) Temporarily or permanently suspend your Account.</li>
          </ul>

          <p>When necessary, you will be notified of such measures to enable you to provide explanations to Beeple Inc. Beeple Inc. will then decide, at its sole discretion, whether or not to lift the measures put in place.</p>

          <h2>10. Personal Data</h2>

          <p>In the course of using the Platform, Beeple Inc. will collect and process some of your personal data. By using the Platform and registering as a Member, you acknowledge and accept the processing of your personal data by Beeple Inc. in compliance with applicable law and the stipulations of the Privacy Policy.</p>
          
          <h2>11. Intellectual Property</h2>

          <p>11.1. Content Published by Beeple</p>
          <ul>
              <li>Subject to the content provided by its Members, Beeple Inc. is the sole owner of all intellectual property rights related to the Service, the Platform, its content (including but not limited to texts, images, designs, logos, videos, sounds, data, graphics), and the software and databases that ensure their operation.</li>
              <li>Beeple Inc. grants you a non-exclusive, personal, and non-transferable right to use the Platform and the Services for your personal and private use, on a non-commercial basis, and in accordance with the purposes of the Platform and the Services.</li>
              <li>You are prohibited from any other use or exploitation of the Platform, Services, and their content without the prior written permission of Beeple Inc. Specifically, you are prohibited from:
                  <ul>
                      <li>(i) Reproducing, modifying, adapting, distributing, publicly representing, or disseminating the Platform, the Services, and their content, except as expressly authorized by Beeple Inc.;</li>
                      <li>(ii) Decompiling or reverse engineering the Platform or Services, except as permitted by law;</li>
                      <li>(iii) Extracting or attempting to extract (notably using data mining robots or any other similar data collection tool) a substantial part of the data on the Platform.</li>
                  </ul>
              </li>
          </ul>

          <p>11.2. Content Posted by You on the Platform</p>
          <ul>
              <li>To enable the provision of the Services and in line with the purpose of the Platform, you grant Beeple Inc. a non-exclusive license to use the content and data you provide while using the Services (hereinafter referred to as your &ldquo;Member Content&rdquo;). This license allows Beeple Inc. to distribute your Member Content via digital networks and any communication protocol (notably internet and mobile networks) and to make the content of the Platform available to the public. This authorization is valid worldwide and for the duration of your contractual relationship with Beeple Inc. and includes the following rights:
                  <ul>
                      <li>(i) You authorize Beeple Inc. to reproduce all or part of your Member Content on any digital recording media, known or as yet unknown, including servers, hard disks, memory cards, or any other equivalent media, in any format and by any process, known or as yet unknown, to the extent necessary for storage, backup, transmission, or download operations related to the operation of the Platform and the provision of the Services;</li>
                      <li>(ii) You authorize Beeple Inc. to adapt and translate your Member Content and to reproduce these adaptations on any digital media, current or future, as stipulated in point (i) above, with the aim of providing the Services, notably in different languages. This right includes the option to modify the formatting of your Member Content, with respect for your moral rights, to ensure it aligns with the graphic charter of the Platform and/or to make it technically compatible for publication via the Platform.</li>
                  </ul>
              </li>
          </ul>

          <h2>12. Role of Beeple</h2>

          <ul>
              <li>The Platform serves as an online mobility platform where Members can create and post Carpooling Adverts for ridesharing purposes. These Adverts can be viewed by other Members to learn the details of the Trip and, where applicable, to directly book a Seat in the vehicle with the Member who posted the Advert on the Platform.</li>
              <li>By using the Platform and accepting these T&amp;Cs, you acknowledge that Beeple Inc. is not a party to any agreement made between you and other Members regarding the sharing of Trip costs.</li>
              <li>Beeple Inc. has no control over the behavior of its Members and users of the Platform. It does not own, operate, supply, or manage the vehicles mentioned in the Adverts, nor does it offer any Trips on the Platform.</li>
              <li>You acknowledge and accept that Beeple Inc. does not control the validity, truthfulness, or legality of the Adverts, Seats, and Trips offered. As a ridesharing intermediary, Beeple Inc. does not provide any transport service and does not act as a carrier; its role is limited to facilitating access to the Platform.</li>
              <li>In the context of Carpooling Trips, Members (Drivers or Passengers) act under their sole and full responsibility.</li>
              <li>As an intermediary, Beeple Inc. cannot be held liable for the actual occurrence of a Trip, including but not limited to:
                  <ul>
                      <li>(i) Erroneous information provided by the Driver in their Advert or by any other means regarding the Trip and its terms,</li>
                      <li>(ii) Cancellation or modification of a Trip by a Member,</li>
                      <li>(iii) The behavior of Members before, during, or after the Trip.</li>
                  </ul>
              </li>
          </ul>

           

          <h2>13. Operation, Availability, and Functionalities of the Platform</h2>

          <p>Beeple Inc. will strive to keep the Platform accessible 24/7. However, access to the Platform may be temporarily suspended without notice due to technical maintenance, migration, updates, or network-related outages or constraints.</p>

          <p>Additionally, Beeple Inc. reserves the right to modify or suspend all or part of the Platform or its functionalities, at its sole discretion, either temporarily or permanently.</p>


          <h2>14. Modification of the T&amp;Cs</h2>


          <p>These T&amp;Cs and the documents incorporated by reference constitute the entire agreement between you and Beeple Inc. regarding your use of the Services. Any other document, including mentions on the Platform (FAQ, etc.), is for guideline purposes only.</p>

          <p>Beeple Inc. may modify these T&amp;Cs to adapt to its technological and commercial environment and to comply with current legislation. Any modifications to these T&amp;Cs will be published on the Platform with the effective date mentioned, and you will be notified by Beeple Inc. before they take effect.</p>


          <h2>15. Applicable Law &ndash; Dispute Resolution</h2>


          <p>These T&amp;Cs are written in English and are subject to Canadian law.</p>

          <p>If necessary, you can present your complaints to the competent national ombudsman. In compliance with the rules applicable to mediation, you must notify Beeple Inc. in writing of any dispute to seek an amicable solution before requesting mediation.</p>


          <h2>16. Legal Notices</h2>

          <p>The Platform is published by Beeple Inc.:</p>
          <ul>
              <li>Email: <a href="mailto:legal@beeple.ca">legal@beeple.ca</a></li>
          </ul>
          <p>For any questions, you can contact Beeple Inc. using <a href="https://beeple.ca/contact-us">this</a> contact form.</p>

        </div>  
      </div>
    </Layout>
  );
};

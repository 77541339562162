import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Place, Trip } from '@typings';

export type TripState = Partial<{
  origin: Place;
  destination: Place;
  /**
   * Route summary
   */
  summary: string;
  polyline: string;
  departure: string;
  waypoints: Trip.Waypoint[];
  commentary: string;
}> & {
  details: Trip.Details;
  price: number;
  seats: number;
  rules: Trip.Rules;
};

const initialState = {
  seats: 3,
  rules: {},
  details: {},
} as TripState;

export const tripSlice = createSlice({
  name: 'trip-creating',
  initialState,
  reducers: {
    setTrip: (state, action: PayloadAction<Partial<TripState>>) => {
      const fieldsToChange = action.payload;

      return { ...state, ...fieldsToChange };
    },
    setTripDetails: (state, action: PayloadAction<Partial<Trip.Details>>) => {
      const fieldsToChange = action.payload;

      state.details = { ...state.details, ...fieldsToChange };
    },
    setTripDetailsWaypoint: (
      state,
      action: PayloadAction<{ index: number; price: number }>,
    ) => {
      const { index, price } = action.payload;

      state.details.waypoints[index].price = price;
    },
    setTripRule: (
      state,
      action: PayloadAction<{ name: string; value: string }>,
    ) => {
      const { name, value } = action.payload;

      state.rules[name] = value;
    },
    resetTrip: () => initialState,
  },
});

export const {
  setTrip,
  setTripDetails,
  setTripDetailsWaypoint,
  setTripRule,
  resetTrip,
} = tripSlice.actions;

export default tripSlice.reducer;

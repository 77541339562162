import { DateIsoFormat, Pagination, Params, Place, Trip } from '@typings';
import { axios } from '@features';

export const getTripWaypoints = (params: Params.GetTripWaypoints) => {
  return axios.post<never, Trip.Waypoint[]>('/routes/waypoints', params);
};

export const computeTripRoutes = (params: Params.ComputeTripRoutes) => {
  return axios.post<
    never,
    {
      arrival: DateIsoFormat;
      departure: DateIsoFormat;
      waypoints: Trip.WaypointDetails[];
      origin: Place;
      destination: Place;
      price: number;
    }
  >('/routes/compute', params);
};

export const createTrip = (params: Params.CreateTrip) => {
  return axios.post('/trips', params);
};

export const getSearchTrips = (
  params: Params.GetSearchTrips,
  { page, sort }: { page?: number; sort?: string | null } = {},
) => {
  return axios.post<never, Pagination.Response<Trip.Item[]>>(
    '/trips/search',
    params,
    { params: { page, sort } },
  );
};

export const bookTripSeats = ({ tripId, seats }: Params.BookTripSeats) => {
  return axios.post<never, Trip.Item['booking']>(`/bookings/${tripId}`, {
    passengers: seats,
  });
};

export const getBookings = (page?: number | null) => {
  return axios.get<never, Pagination.Response<Trip.PassengerItem[]>>(
    '/bookings',
    { params: { page } },
  );
};

export const getTrips = (page?: number | null) => {
  return axios.get<never, Pagination.Response<Trip.DriverItem[]>>('/trips', {
    params: { page },
  });
};

export const updateBookingStatus = ({
  tripId,
  bookingId,
  status,
}: Params.UpdateBookingStatus) => {
  return axios.put<
    never,
    Pick<Trip.PassengerItem, 'id' | 'passengers' | 'price' | 'status'>
  >(`/bookings/${tripId}/${bookingId}`, { status });
};

export const cancelTrip = ({ tripId }: Params.CancelTrip) => {
  return axios.put<never>(`/trips/${tripId}`, { status: 'canceled' });
};

export const getRecentTrips = () => {
  return axios.get<
    never,
    {
      total: number;
      trips: Trip.Recent[];
    }
  >('/trips/recent');
};

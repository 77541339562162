import { cn } from '@utils';

type Props = {
  className?: string;
  children?: React.ReactNode;
};

export const ThemeContainer = ({ className, children }: Props) => (
  <div
    className={cn(
      'rounded-[20px] bg-white shadow-[2px_2px_12px_0px_rgba(0,0,0,0.10)]',
      className,
    )}
  >
    {children}
  </div>
);

import { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import dayjs from 'dayjs';
import { useImmer } from 'use-immer';

import { Trip, TripOutletContext } from '@typings';
import { DATE_FORMAT } from '@constants';
import { computeTripRoutes } from '@services';
import { setTrip } from '@slices';
import { tripSelector } from '@selectors';
import { useDispatch, useSelector } from '@hooks';
import { geocode, toastifyError } from '@utils';

import { TripLayout } from '@components/Layouts';
import { TripWaypoint } from '@components/Ui';

export const TripWaypointsPage = () => {
  const dispatch = useDispatch();
  const {
    origin,
    destination,
    departure: tripDeparture,
    waypoints: tripWaypoints = [],
    summary,
  } = useSelector(tripSelector);

  const [loading, setLoading] = useState(false);
  const [waypoints, setWaypoints] = useImmer<Trip.Waypoint[]>(tripWaypoints);

  const { goNext } = useOutletContext<TripOutletContext>();

  const curryCityActivation =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.checked;

      setWaypoints((state) => {
        state[index].isActivated = value;
      });
    };

  const curryLocationChange = (index: number) => (location: Trip.Waypoint) => {
    setWaypoints((state) => {
      const waypoint = state[index];

      state[index] = { ...waypoint, ...location };
    });
  };

  const makeWaypoint = ({ city, geo }: Trip.Waypoint, index: number) => {
    const { isActivated = false } = waypoints[index];
    const key = JSON.stringify(geo);

    return (
      <TripWaypoint
        key={key}
        isActivated={isActivated}
        city={city}
        geo={geo}
        onCityActivation={curryCityActivation(index)}
        onLocationChange={curryLocationChange(index)}
      />
    );
  };

  const waypointsToCompute = waypoints.filter(({ isActivated }) => isActivated);

  const handleSubmit = async () => {
    try {
      setLoading(true);

      const originLocation = await geocode({ placeId: origin?.placeId });
      const destinationLocation = await geocode({
        placeId: destination?.placeId,
      });

      const departure = dayjs(tripDeparture, `${DATE_FORMAT} hh:mm A`).format();
      const payload = {
        departure,
        description: summary!,
        origin: originLocation!,
        destination: destinationLocation!,
        waypoints: waypointsToCompute,
      };

      const {
        origin: computedOrigin,
        destination: computedDestination,
        price,
        waypoints: detailsWaypoints,
        ...details
      } = await computeTripRoutes(payload);

      /**
       * Managing recommended and actual prices requires keeping different price values
       * This formatter is for "Waypoint prices" max limit
       */
      const formattedDetailsWaypoints = detailsWaypoints.map(
        ({ price, ...waypoint }) => ({
          ...waypoint,
          price,
          recommendedPrice: price,
        }),
      );

      dispatch(
        setTrip({
          origin: computedOrigin,
          destination: computedDestination,
          details: {
            ...details,
            price,
            recommendedPrice: price,
            waypoints: formattedDetailsWaypoints,
          },
          waypoints,
        }),
      );

      goNext();
    } catch (error) {
      toastifyError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex w-full flex-col gap-7 px-20 md:px-2">
      <div className="flex items-center gap-2">
        <div className="h-3 w-3 rounded-full bg-eva" />
        <p className="text-16 font-500 text-secondary">
          {origin?.formattedAddress}
        </p>
      </div>
      {waypoints!.map(makeWaypoint)}
      <div className="flex items-center gap-2">
        <div className="h-3 w-3 rounded-full bg-eva" />
        <p className="text-16 font-500 text-secondary">
          {destination?.formattedAddress}
        </p>
      </div>
      <TripLayout.Handler
        loading={loading}
        title="Select the waypoints to find more passengers"
        onHandle={handleSubmit}
      />
    </div>
  );
};

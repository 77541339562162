import { Params, Thunk } from '@typings';
import { JWT_TOKEN } from '@constants';
import { sentry } from '@features';
import * as services from '@services';
import {
  NotificationsState,
  resetNotifications,
  resetUser,
  setConfig,
  setNotifications,
  setUser,
  UserState,
} from '@slices';
import { localStorage } from '@utils';

export const resetUserData = (): Thunk => (dispatch) => {
  localStorage.clear(JWT_TOKEN);

  dispatch(resetUser());
  dispatch(resetNotifications());
};

export const initialize = (): Thunk => async (dispatch) => {
  try {
    const { user, config, notifications } = await services.authInitialization();

    dispatch(setUser(user));
    dispatch(setConfig(config));

    if (user) {
      sentry.setUser(user);
    }

    if (notifications) {
      dispatch(setNotifications(notifications));
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export const authorizeUser =
  (params: Params.VerifyCode): Thunk<Promise<UserState>> =>
  async (dispatch) => {
    try {
      const { user, token, notifications } =
        await services.authVerifyCode(params);

      localStorage.set(JWT_TOKEN, token);
      sentry.setUser(user);

      dispatch(setUser(user));

      if (notifications) {
        dispatch(setNotifications(notifications));
      }

      return user;
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const unauthorizeUser = (): Thunk => async (dispatch) => {
  try {
    await services.authLogout();

    dispatch(resetUserData());
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateProfile =
  (params: Params.UpdateProfile): Thunk<Promise<UserState>> =>
  async (dispatch) => {
    try {
      const user = await services.updateProfile(params);

      dispatch(setUser(user));

      return user;
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const addUserVehicle =
  (params: Params.AddUserVehicle): Thunk<Promise<UserState>> =>
  async (dispatch) => {
    try {
      const user = await services.addUserVehicle(params);

      dispatch(setUser(user));

      return user;
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const updateUserVehicle =
  (params: Params.UpdateUserVehicle): Thunk<Promise<UserState>> =>
  async (dispatch) => {
    try {
      const user = await services.updateUserVehicle(params);

      dispatch(setUser(user));

      return user;
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const readChatMessages =
  (params: Params.ReadChatMessages): Thunk<Promise<NotificationsState>> =>
  async (dispatch) => {
    try {
      const notifications = await services.readChatMessages(params);

      dispatch(setNotifications(notifications));

      return notifications;
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const deleteAccount = (): Thunk => async (dispatch) => {
  try {
    await services.deleteAccount();

    dispatch(resetUserData());
  } catch (error) {
    return Promise.reject();
  }
};

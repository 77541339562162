import { Params } from '@typings';
import { axios } from '@features';
import { ConfigState } from '@slices';

export const getConfig = () => {
  return axios.get<never, ConfigState>('/config');
};

export const getVehicles = () => {
  return axios.get<never, { [key: string]: string[] }>('/exports/vehicles');
};

export const reportSupport = (payload: Params.ReportSupport) => {
  return axios.post('/contact', payload);
};

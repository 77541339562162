import { Any } from '@typings';
import { toast } from '@features';
import { isString } from '@utils';

const formatMessage = (message: string) => message.replace(/\.$/, '');

export const toastifyError = (error: Any) => {
  const message = error?.message;
  const formattedMessage = isString(message) ? formatMessage(message) : message;

  if (message) {
    toast.error(formattedMessage);
  }
};

import { faCircleXmark } from '@fortawesome/pro-thin-svg-icons';

import { useInitialization } from '@hooks/useInitialization';

import { Icon } from '@components';
import { ApplicationRoutes } from '@components/Routes';
import { ToastContainer } from '@components/Toast';
import { Logo } from '@components/Ui';

export const Application = () => {
  const { isInitialized, isError } = useInitialization();

  if (!isInitialized) {
    return (
      <div className="fixed inset-0 flex items-center justify-center">
        <div className="duration-1000 animate-in fade-in">
          <Logo className="w-[360px]" />
        </div>
      </div>
    );
  }

  if (isError) {
    return (
      <div className="flex w-full flex-col items-center gap-10 px-10 pb-12 pt-40">
        <Icon
          icon={faCircleXmark}
          className="h-24 w-24 text-[80px] text-primary"
        />
        <div className="flex max-w-[440px] flex-col gap-2 text-center">
          <p className="text-h5">Cannot fetch configuration</p>
        </div>
      </div>
    );
  }

  return (
    <>
      <ToastContainer />
      <ApplicationRoutes />
    </>
  );
};

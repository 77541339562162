import React from 'react';
import clsx from 'clsx';

import { Render } from '@components';

type Props = {
  name: string;
  className?: string;
  accept?: string;
  children?: React.ReactNode;
};

export const FieldFile = React.forwardRef<HTMLInputElement, Props>(
  ({ name, className, children, ...props }, ref) => (
    <label htmlFor={name} className={clsx('cursor-pointer', className)}>
      <Render if={children}>{children}</Render>
      <input
        {...props}
        type="file"
        ref={ref}
        name={name}
        id={name}
        className="hidden"
      />
    </label>
  ),
);

FieldFile.displayName = 'Field.File';

import { UserState } from '@slices';
import { isObject } from '@utils/typeof';

export const isEmailVerified = (source: UserState | string | null): boolean => {
  if (!source) {
    return false;
  }

  const target = isObject(source) ? source.emailVerifiedAt : source;

  return !!target;
};

export const isUserDeleted = (
  source: UserState | string | null | undefined,
): boolean => {
  if (!source) {
    return false;
  }

  const target = isObject(source) ? source.deletedAt : source;

  return !!target;
};

export const isUserBlocked = (
  source: UserState | string | null | undefined,
): boolean => {
  if (!source) {
    return false;
  }

  const target = isObject(source) ? source.blockedAt : source;

  return !!target;
};

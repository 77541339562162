import { useCallback, useState } from 'react';
import dayjs from 'dayjs';
import { useImmer } from 'use-immer';

import { Pagination, Trip } from '@typings';
import { DATE_FORMAT } from '@constants';
import { getSearchTrips } from '@services';
import { as, geocode, isArray, toastifyError } from '@utils';

import { usePagination } from './usePagination';

type Nullable<T> = T | null | undefined;

const EMPTY_TRIPS = [] as Trip.Item[];

export const useSearchTrips = () => {
  const [loading, setLoading] = useState(false);
  const { pagination, setPagination } = usePagination();
  const [trips, setTrips] = useImmer<Trip.Item[] | null>(null);

  const isFetched = trips !== null;
  const isEmpty = isArray(trips) && trips.length === 0;

  const getTrips = useCallback(
    async (
      {
        seats,
        date,
        originPlaceId,
        destinationPlaceId,
      }: {
        seats: Nullable<number>;
        date?: Nullable<string>;
        originPlaceId: Nullable<string>;
        destinationPlaceId: Nullable<string>;
      },
      {
        page,
        sort,
      }: {
        page?: Pagination.Params['page'];
        sort?: string | null;
      } = {},
    ) => {
      const formattedDate =
        date === 'tomorrow' ? dayjs().add(1, 'day').format(DATE_FORMAT) : date;
      const isDateValid = dayjs(formattedDate, DATE_FORMAT).isValid();

      try {
        if (
          !seats ||
          (date && !isDateValid) ||
          !originPlaceId ||
          !destinationPlaceId
        ) {
          return;
        }

        setLoading(true);

        const origin = await geocode({ placeId: originPlaceId! });
        const destination = await geocode({ placeId: destinationPlaceId! });

        if (!origin || !destination) {
          setLoading(false);

          return;
        }

        const { items, ...pagination } = await getSearchTrips(
          {
            date: date ? formattedDate : undefined,
            origin,
            destination,
            passengers: seats!,
          },
          { page, sort },
        );

        if (page) {
          setTrips((trips) => [...as.a<Trip.Item[]>(trips), ...items]);
        } else {
          setTrips(items);
        }
        setPagination(pagination);
      } catch (error) {
        toastifyError(error);
      } finally {
        setLoading(false);
      }
    },
    [setTrips, setPagination],
  );

  const handleBookSeats = useCallback(
    (tripId: string, booking: Trip.Item['booking']) => {
      setTrips((trips) => {
        const trip = trips?.find(({ id }) => id === tripId);

        if (trip) {
          trip.booking = booking;
        }
      });
    },
    [setTrips],
  );

  return {
    loading,
    isFetched,
    isEmpty,
    trips: trips ?? EMPTY_TRIPS,
    pagination,
    getTrips,
    setPagination,
    onSeatsBook: handleBookSeats,
  };
};

import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { faCarSide } from '@fortawesome/pro-solid-svg-icons';

import { ModalProps, Vehicle } from '@typings';
import { PATH } from '@constants';
import { toast } from '@features';
import { getVehicles } from '@services';
import { UserState } from '@slices';
import { addUserVehicle, updateUserVehicle } from '@thunks';
import { userVehicleSelector } from '@selectors';
import { useDispatch, useSelector } from '@hooks';
import {
  as,
  isObjectEmpty,
  noop,
  normalizeFormErrors,
  setFormBackendErrors,
  toastifyError,
} from '@utils';

import { Button, Field, Icon, Link, Modal } from '@components';

type Vehicles = {
  [key: string]: string[];
};

type Schema = z.infer<typeof schema>;

/**
 * todo: think about moving it to content
 */
const colors = [
  'Black',
  'White',
  'Grey',
  'Silver',
  'Blue',
  'Red',
  'Brown',
  'Gold',
  'Green',
  'Tan',
  'Orange',
];

const schema = z.object({
  make: z.string().min(1),
  model: z.string().min(1),
  color: z.string().min(1),
  plate: z
    .string()
    .transform((plate) =>
      plate
        .trim()
        /**
         * Drops multiple spaces in the middle of a string
         */
        .replaceAll(/\s\s+/g, ' ')
        .toUpperCase(),
    )
    .refine((value) => /^[0-9a-zA-Z\- ]+$/.test(value)),
});

type Props = ModalProps & {
  onSuccess?: (vehicle: Vehicle) => void;
};

export const UpdateVehicleModal = ({
  onSuccess = noop,
  closeModal = noop,
}: Props) => {
  const dispatch = useDispatch();
  const vehicle = useSelector(userVehicleSelector);

  const {
    register,
    formState,
    handleSubmit,
    watch,
    setError,
    getValues,
    reset,
    setValue,
  } = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: vehicle,
  });

  const [loading, setLoading] = useState(false);
  const [vehicles, setVehicles] = useState<Vehicles>({});

  /**
   * At this stage `id` can be empty when none of vehicles are added
   */
  const vehicleId = vehicle.id as Vehicle['id'] | undefined;
  const make = watch('make');
  const models = as.a<string[]>(vehicles[make]);
  const errors = normalizeFormErrors<keyof Schema>(formState.errors);

  useEffect(() => {
    const run = async () => {
      try {
        const vehicles = await getVehicles();

        setVehicles(vehicles);

        if (isObjectEmpty(vehicle)) {
          const make = Object.keys(vehicles)[0];

          setValue('make', make);
        } else {
          reset(vehicle);
        }
      } catch (error) {
        toastifyError(error);
      }
    };

    run();
  }, [vehicle, setValue, reset]);

  useEffect(() => {
    setValue('model', models[0]);
  }, [models, setValue]);

  const destructuringUser = (user: UserState): Vehicle => user.vehicles[0];

  const handleVehicleSubmit = handleSubmit(async (data) => {
    try {
      setLoading(true);

      let vehicle: Vehicle;

      if (vehicleId) {
        const user = await dispatch(
          updateUserVehicle({ ...data, id: vehicleId }),
        );

        vehicle = destructuringUser(user);
      } else {
        const user = await dispatch(addUserVehicle(data));

        vehicle = destructuringUser(user);
      }

      toast.success('Your vehicle information has been successfully updated');

      closeModal();
      onSuccess(vehicle);
    } catch (error) {
      setFormBackendErrors({ error, setError, getValues });
      toast.error((error as Error).message);
    } finally {
      setLoading(false);
    }
  });

  const makeOption = (value: string) => (
    <option key={value.toLowerCase()}>{value}</option>
  );

  if (isObjectEmpty(vehicles)) {
    return (
      <Modal.Content title="Driver’s form" className="w-[480px]">
        <div className="flex h-80 items-center justify-center text-hannah">
          <Icon
            icon={faCarSide}
            iconClassName="fa-bounce"
            className="h-10 w-10 text-64"
          />
        </div>
      </Modal.Content>
    );
  }

  return (
    <Modal.Content title="Driver’s form" className="w-[480px]">
      <form onSubmit={handleVehicleSubmit} className="flex flex-col gap-6">
        <Field.Select
          {...register('make')}
          required
          label="Make"
          error={errors.make}
        >
          {Object.keys(vehicles).map(makeOption)}
        </Field.Select>
        <Field.Select
          {...register('model')}
          required
          label="Model"
          error={errors.model}
        >
          {models.map(makeOption)}
        </Field.Select>
        <Field.Select
          {...register('color')}
          required
          label="Color"
          error={errors.color}
        >
          {colors.map(makeOption)}
        </Field.Select>
        <Field.Input
          {...register('plate')}
          required
          label="Plate"
          note="The licence plate has to be issued and valid in Canada"
          error={errors.plate}
        />
        <Field.Note>
          By continuing you agree to Beeple’s&nbsp;
          <Link
            theme
            external
            blank
            to={PATH.TERMS_AND_CONDITIONS}
            className="underline"
          >
            Terms and Conditions
          </Link>
          ,&nbsp;
          <Link
            theme
            external
            blank
            to={PATH.COKIE_POLICY}
            className="inline-block underline"
          >
            Cookie policy
          </Link>{' '}
          and acknowledge&nbsp;
          <Link
            theme
            external
            blank
            to={PATH.PRIVACY_POLICY}
            className="inline-block underline"
          >
            Privacy and Data protection Policy
          </Link>
        </Field.Note>
        <Button
          loading={loading}
          type="submit"
          variant="hannah"
          className="-mt-4 w-full"
        >
          {vehicleId ? 'Update' : 'Add'}
        </Button>
      </form>
    </Modal.Content>
  );
};

import { Any } from '@typings';
import { isArray, isFunction } from '@utils';

export const formatToCamelCase = (item: Any): unknown => {
  if (isArray(item)) {
    return item.map(formatToCamelCase);
  }

  if (isFunction(item) || item !== Object(item)) {
    return item;
  }

  return Object.fromEntries(
    Object.entries(item).map(([key, value]) => [
      key.replace(/([-_][a-z])/gi, (c) => c.toUpperCase().replace(/[-_]/g, '')),
      formatToCamelCase(value),
    ]),
  );
};

import { useRef, useState } from 'react';
import dayjs from 'dayjs';
import { KeysOfUnion } from 'type-fest';
import { faUser as faUserRegular } from '@fortawesome/pro-regular-svg-icons';
import {
  faCaretDown,
  faCaretRight,
  faCarSide,
  faDiamond,
  faLocationDot,
  faUser,
} from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';

import { Location, Trip } from '@typings';
import { PATH } from '@constants';
import { cn, p } from '@utils';
import { tripRules } from '@content';

import {
  Avatar,
  Button,
  Icon,
  Link,
  Modal,
  Popover,
  Render,
  Theme,
  Tooltip,
} from '@components';

import { GoogleMapModal } from '../Modals';

import { TripBookButton } from './TripBookButton';

type Props = Trip.Item & {
  /**
   * How many seats passanger is going to book for this trip
   */
  bookingSeats: number;
};

export const TripCard = ({
  id: tripId,
  bookingSeats,
  seats,
  freeSeats,
  origin,
  destination,
  departure,
  duration,
  arrival,
  price,
  vehicle,
  comment,
  rules,
  parent,
  polyline,
  driver: { id: driverId, firstName, avatar },
  booking,
}: Props) => {
  const SERVICE_FEE = 0;

  const contentRef = useRef<HTMLDivElement>(null);
  const [isCollapsed, setCollapse] = useState(true);

  const priceBasedOnSeats = price * bookingSeats;
  const tripPrice = priceBasedOnSeats + priceBasedOnSeats * SERVICE_FEE;
  const maxHeight = isCollapsed ? 0 : contentRef.current?.scrollHeight;
  const bookingStatus = booking?.status;

  const handleToggle = () => setCollapse((state) => !state);

  const getFormattedPlace = (
    { city, state, address }: Location.Location,
    isDetailed?: boolean,
  ) => {
    const place = `${city}, ${state}`;

    return isDetailed && address ? `${address}, ${place}` : place;
  };

  const getFormattedVehicle = () => {
    const { make, color, model } = vehicle;

    return `${make} ${model} (${color})`;
  };

  const getParentTrip = () => {
    if (parent) {
      return `${parent.origin.city} - ${parent.destination.city}`;
    }

    return `${origin.city} - ${destination.city}`;
  };

  const makeRule = ([rule, value]: [string, string]) => {
    const currentRule = tripRules[
      rule as KeysOfUnion<typeof tripRules>
    ]?.rules.find((rule) => rule.value === value);

    if (!currentRule) {
      return null;
    }

    const { icon, title } = currentRule;

    return (
      <Tooltip key={rule}>
        <p slot="trigger" className="text-18 text-hannah">
          <Icon key={rule} icon={icon} className="h-5 w-5" />
        </p>
        <p>{title}</p>
      </Tooltip>
    );
  };

  const makeSeat = (_: unknown, index: number) => {
    const counter = index + 1;
    const isBooked = counter <= seats - freeSeats;
    const icon = isBooked ? faUser : faUserRegular;
    const color = isBooked ? 'text-hannah' : 'text-eva';

    return (
      <Icon
        key={counter}
        slot="trigger"
        icon={icon}
        className={clsx('text-24', color)}
      />
    );
  };

  const renderSeats = () => {
    const bookedSeats = seats - freeSeats;
    const className =
      'flex basis-12 justify-center text-primary text-16 items-center font-600 shrink-0';

    if (seats < 4) {
      return Array.from({ length: seats }).map(makeSeat);
    }

    return (
      <p className={className}>
        <Render if={bookedSeats > 0}>
          <Icon icon={faUser} className="mr-1 text-24 text-hannah" />
          <span className="shrink-0 text-secondary">x {bookedSeats}</span>
          <div className="mr-2" />
        </Render>
        <Render if={freeSeats > 0}>
          <Icon icon={faUserRegular} className="mr-1 text-24 text-eva" />
          <span className="shrink-0 text-secondary">x {freeSeats}</span>
        </Render>
      </p>
    );
  };

  return (
    <div
      className={cn(
        'overflow-hidden rounded-3xl border border-transparent bg-white shadow-[0px_0px_8px_0px_#F4F4F4] transition-all hover:shadow-[0px_0px_8px_0px_#e0e0e0]',
        { 'border-hannah': bookingStatus },
      )}
    >
      <Button
        theme
        onClick={handleToggle}
        className="group flex w-full flex-1 items-center gap-3 p-3 sm:flex-wrap sm:justify-center sm:px-2"
      >
        <Link
          theme
          className="group/link block shrink-0 text-center"
          to={p(PATH.USER, driverId)}
        >
          <Avatar
            src={avatar}
            className="h-[60px] w-[60px] text-white transition-colors"
          />
          <p className="mt-1 text-10 text-eva transition-colors group-hover/link:text-hannah sm:text-12">
            {firstName}
          </p>
        </Link>
        <div className="flex-1 self-stretch px-3 sm:order-1 sm:w-full">
          <div className="relative flex flex-1 items-center text-17 font-500 text-eva md:hidden sm:flex">
            <p className="truncate sm:basis-1/4">{getFormattedPlace(origin)}</p>
            <div className="relative mx-3 flex flex-1 items-center text-secondary-light sm:basis-1/2">
              <Icon icon={faDiamond} className="contents shrink-0 text-5" />
              <div className="h-px flex-1 border-b border-dashed border-secondary-light" />
              <div className="absolute inset-0 -top-2">
                <Icon
                  icon={faCarSide}
                  className="m-auto text-16 text-primary"
                />
                <p className="text-center text-7 font-500 text-secondary">
                  {duration}
                </p>
              </div>
              <Icon icon={faCaretRight} className="contents shrink-0 text-10" />
            </div>
            <p className="truncate sm:basis-1/4">
              {getFormattedPlace(destination)}
            </p>
          </div>
          <p className="mt-1 flex justify-between text-caption-2 text-secondary md:flex-col md:gap-1 md:text-left sm:hidden">
            <span>
              <p className="hidden text-17 font-500 text-eva md:block">
                {getFormattedPlace(origin)}
              </p>
              <b>{dayjs(departure).format('MMM D, YYYY')}</b> /{' '}
              {dayjs(departure).format('hh:mm A')}
            </span>
            <div className="relative hidden flex-col items-start text-secondary-light md:flex">
              <Icon icon={faDiamond} className="contents shrink-0 text-5" />
              <div className="ml-0.5 h-10 w-px shrink-0 border-l border-dashed border-secondary-light" />
              <div className="absolute inset-y-0 flex items-center">
                <Icon
                  icon={faCarSide}
                  className="relative -left-2 m-auto text-16 text-primary"
                />
                <p className="text-center text-7 font-500 text-secondary">
                  {duration}
                </p>
              </div>
              <Icon
                icon={faCaretDown}
                className="contents shrink-0 rotate-90 text-10"
              />
            </div>
            <span>
              <p className="hidden text-17 font-500 text-eva md:block">
                {getFormattedPlace(destination)}
              </p>
              <b>{dayjs(arrival).format('MMM D, YYYY')}</b> /{' '}
              {dayjs(arrival).format('hh:mm A')}
            </span>
          </p>
          <div className="mt-1 flex items-center gap-3 text-left md:flex-wrap md:items-start">
            <p className="mr-1 text-caption-2 font-500 text-secondary md:w-full">
              {getFormattedVehicle()}
            </p>
            <Render if={rules}>{Object.entries(rules).map(makeRule)}</Render>
          </div>
        </div>
        <div className="flex basis-32 justify-center gap-1.5">
          {renderSeats()}
        </div>
        <div className="flex basis-24 items-center justify-end">
          <p className="font-500 text-eva">
            <span className="space-after text-15">CA$</span>
            <span className="text-20">{tripPrice}</span>
            <span className="text-10">.00</span>
          </p>
        </div>
      </Button>
      <div
        ref={contentRef}
        style={{ maxHeight }}
        className="overflow-hidden transition-all duration-500"
      >
        {/* Collapse content, div is necessary */}
        <div className="border-t border-[#F5FBFF] p-6 pt-4 sm:px-2">
          <div className="flex gap-5 sm:flex-col">
            <Theme.TripContainer className="flex flex-1 justify-between sm:w-full sm:flex-col sm:gap-4">
              <div>
                <p className="text-12 font-500 text-primary">Date</p>
                <p className="mt-3 text-14 font-700 text-hannah sm:mt-0">
                  {dayjs(departure).format('MMMM DD, YYYY')}
                </p>
              </div>
              <div>
                <p className="text-12 font-500 text-primary">Time</p>
                <p className="mt-3 text-14 font-700 text-hannah sm:mt-0">
                  {dayjs(departure).format('hh:mm A')}
                </p>
              </div>
              <div>
                <p className="text-12 font-500 text-primary">Trip</p>
                <p className="mt-3 text-14 font-700 text-primary sm:mt-0">
                  {getParentTrip()}
                </p>
              </div>
              <div>
                <p className="text-12 font-500 text-primary">Endpoint</p>
                <p className="mt-3 text-14 font-700 text-hannah sm:mt-0">
                  {destination.city}
                </p>
              </div>
            </Theme.TripContainer>
            <Theme.TripContainer className="flex basis-36 flex-col items-center justify-center sm:basis-auto">
              <p className="font-500 leading-none text-eva">
                <span className="space-after text-15">CA$</span>
                <span className="text-20">{priceBasedOnSeats}</span>
                <span className="text-10">.00</span>
              </p>
              <p className="text-center text-10 text-eva">
                Trip payment for {bookingSeats}&nbsp;
                {bookingSeats > 1 ? 'passengers' : 'passenger'}
              </p>
            </Theme.TripContainer>
          </div>
          <div className="mt-5 flex gap-5 sm:flex-col">
            <Theme.TripContainer className="flex flex-1 items-center gap-6 sm:gap-2 sm:px-3">
              <div className="min-w-0">
                <p className="flex items-center gap-1.5 text-12 font-500 text-secondary">
                  Origin point
                  <Modal
                    content={
                      <GoogleMapModal
                        viewMode
                        polyline={polyline}
                        geo={origin.geo}
                      />
                    }
                  >
                    <Icon
                      icon={faLocationDot}
                      className="h-4 w-4 cursor-pointer text-14 text-hannah"
                    />
                  </Modal>
                </p>
                <p className="mt-2 truncate text-14 font-500 text-primary">
                  {getFormattedPlace(origin, true)}
                </p>
              </div>
              <div className="relative flex-1 sm:basis-2/12">
                <div className="h-px border-t border-dashed border-secondary" />
              </div>
              <div className="min-w-0">
                <p className="flex items-center gap-1.5 text-12 font-500 text-secondary">
                  Endpoint
                  <Modal
                    content={
                      <GoogleMapModal
                        viewMode
                        polyline={polyline}
                        geo={destination.geo}
                      />
                    }
                  >
                    <Icon
                      icon={faLocationDot}
                      className="h-4 w-4 cursor-pointer text-14 text-hannah"
                    />
                  </Modal>
                </p>
                <p className="mt-2 truncate text-14 font-500 text-primary">
                  {getFormattedPlace(destination, true)}
                </p>
              </div>
            </Theme.TripContainer>
            <Theme.TripContainer className="flex basis-36 flex-col items-center justify-center sm:basis-auto">
              <p className="font-500 leading-none text-secondary">
                <span className="space-after text-15">CA$</span>
                <span className="text-20">{tripPrice - priceBasedOnSeats}</span>
                <span className="text-10">.00</span>
              </p>
              <Popover align="end" className="w-40">
                <p slot="trigger" className="text-10 text-secondary underline">
                  Service fee
                </p>
                <p>
                  The <b>service fee</b> is charged to provide support and
                  maintenance for the service. <br /> After paying the&nbsp;
                  <b>service fee</b>, you will have the ability to contact the
                  driver.
                </p>
              </Popover>
            </Theme.TripContainer>
          </div>
          <Render if={comment}>
            <p className="mt-3 text-10 text-secondary before:mr-0.5 before:align-middle before:text-25 before:leading-0 before:text-hannah before:content-['“']">
              The notice by {firstName}:&nbsp;
              <span className="font-700 text-hannah">{comment}</span>
            </p>
          </Render>
          <div className="mt-3 flex items-end gap-7 md:gap-4 sm:flex-col sm:px-2">
            <div className="mr-auto text-8 text-secondary">
              <p className="mb-1 text-10 font-500">Terms of payment:</p>
              <p className="md:inline">
                Beeple does not accept payment for the trip. The payment should
                be made directly to the driver
              </p>
              <p className="hidden md:inline">.&nbsp;</p>
              <p className="md:inline">
                Beeple will refund the service fee if the driver cancels your
                request
              </p>
            </div>
            <Button disabled={!bookingStatus} className="h-8 px-4 sm:w-full">
              <Link
                theme
                to={`${PATH.CHAT}/${tripId}/${driverId}`}
                className="block px-8 py-1"
              >
                Chat
              </Link>
            </Button>
            <TripBookButton tripId={tripId} booking={booking} />
            <Render if={bookingStatus}>
              <Link
                variant="hannah"
                className="shrink-0 px-12 sm:w-full"
                to={PATH.PASSENGER_TRIPS}
              >
                My trips
              </Link>
            </Render>
          </div>
        </div>
      </div>
    </div>
  );
};

import { useCallback, useEffect, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { TripOutletContext } from '@typings';
import { IS_LOCAL, PATH } from '@constants';
import { tripSteps } from '@content';

import { Render } from '@components';

import { HeaderLayout } from '../HeaderLayout';

import { TripFooterLayout } from './TripFooterLayout';

export const TripLayout = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const footerRef = useRef<HTMLDivElement>(null);
  const [title, setTitle] = useState('');

  const currentStep = tripSteps.findIndex((path) => pathname === path)!;
  const trackerWidth = (currentStep / tripSteps.length) * 100;

  const handleNextStep = useCallback(() => {
    navigate(tripSteps[currentStep + 1]);
  }, [currentStep, navigate]);

  /**
   * Tracker for direct middle page during trip creating
   * Return a user back to the beginning
   */
  useEffect(() => {
    if (!IS_LOCAL) {
      navigate(PATH.TRIP_CREATING_DEPARTURE_DATE);
    }
    /**
     * Skip tracking `navigate`
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex h-screen flex-1 flex-col">
      <HeaderLayout className="max-w-none" />
      <div className="relative flex flex-1 flex-col">
        <div
          className="absolute bottom-0 left-0 h-1 translate-y-1/2 rounded-full bg-eva transition-all duration-500"
          style={{ width: `${trackerWidth}%` }}
        />
        <div className="mx-auto flex max-h-[calc(100vh-220px)] w-full max-w-[1280px] flex-1 flex-col items-center gap-8 overflow-auto px-2 py-8">
          <Render if={title}>
            <h2 className="text-headline font-500">{title}</h2>
          </Render>
          <Outlet
            context={
              {
                footerRef,
                currentStep,
                setTitle,
                goNext: handleNextStep,
              } satisfies TripOutletContext
            }
          />
        </div>
      </div>
      <div ref={footerRef} />
    </div>
  );
};

TripLayout.Handler = TripFooterLayout;

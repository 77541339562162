import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DateIsoFormat, Vehicle } from '@typings';

export type UserState = {
  id: string;
  phone: string;
  firstName: string | null;
  lastName: string | null;
  dateOfBirth: string | null;
  createdAt: DateIsoFormat;
  email: string | null;
  avatar: string | null;
  vehicles: Vehicle[];
  emailVerifiedAt: DateIsoFormat | null;
  trips: number;
  bookings: number;
  deletedAt: string | null;
  blockedAt: string | null;
};

const initialState = {} as UserState;

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (_, action: PayloadAction<UserState | undefined>) =>
      action.payload ?? initialState,
    resetUser: () => initialState,
  },
});

export const { setUser, resetUser } = userSlice.actions;

export default userSlice.reducer;

import ReactGA from 'react-ga4';

import { GA_TRACKING_ID } from '@constants';

export const analytics = {
  init: () => {
    if (GA_TRACKING_ID) {
      ReactGA.initialize([{ trackingId: GA_TRACKING_ID }]);
    }
  },
};

import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { faChevronDown, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { faUser } from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';

import { PATH } from '@constants';
import { unauthorizeUser } from '@thunks';
import {
  isAuthenticatedSelector,
  notificationsSelector,
  userSelector,
} from '@selectors';
import { useClickableState, useDispatch, useSelector } from '@hooks';
import { cn, isNumber, toastifyError } from '@utils';

import {
  Avatar,
  Button,
  Icon,
  Link,
  Modal,
  Popover,
  Render,
  Transition,
} from '@components';
import { AuthModal } from '@components/Ui';

export const UserPanel = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const { avatar } = useSelector(userSelector);
  const { trips, messages } = useSelector(notificationsSelector);

  const navigate = useNavigate();

  const buttonRef = useRef<HTMLButtonElement>(null);
  const modalContainerRef = useRef<HTMLDivElement>(null);

  const [loading, setLoading] = useState(false);
  const [isOpen, setOpen] = useClickableState(buttonRef, {
    space: modalContainerRef,
  });

  const notificationCount = trips + messages;

  const className =
    'relative flex h-10 w-10 items-center justify-center shadow-[2px_2px_12px_0px_rgba(0,0,0,0.10)] rounded-full bg-white text-hannah transition-colors';
  const itemClassName =
    'w-full justify-between whitespace-nowrap px-0 text-14 text-eva';

  const handleLogout = async () => {
    try {
      setLoading(true);

      await dispatch(unauthorizeUser());

      setOpen(false);
      navigate(PATH.HOME);
    } catch (error) {
      toastifyError(error);
    } finally {
      setLoading(false);
    }
  };

  const renderNotification = (count: number, className?: string) => {
    if (!isNumber(count) || count === 0) {
      return null;
    }

    return (
      <p
        className={cn(
          'pointer-events-none flex h-5 w-5 items-center justify-center rounded-full border-2 border-white bg-[#F31212] text-11 font-700 text-white',
          className,
        )}
      >
        {count}
      </p>
    );
  };

  if (!isAuthenticated) {
    return (
      <Popover showArrow={false} align="end" className="rounded-2xl px-4 py-2">
        <Button
          theme
          stopPropagation={false}
          slot="trigger"
          className="flex items-center gap-2"
        >
          <div className={className}>
            <Icon icon={faUser} className="text-20" />
          </div>
          <Icon
            icon={faChevronDown}
            className="text-hannah transition-transform"
          />
        </Button>
        <div className="flex w-[200px] flex-col">
          <Modal content={<AuthModal />}>
            <Button variant="ghost" className={itemClassName}>
              Login / Signup
            </Button>
          </Modal>
          <div className="my-2.5 h-px bg-neural-02" />
          <Link variant="ghost" className={itemClassName} to={PATH.CONTACT_US}>
            Contact us
          </Link>
          <Link variant="ghost" className={itemClassName} to={PATH.HELP_CENTRE}>
            Help centre
          </Link>
        </div>
      </Popover>
    );
  }

  return (
    <div className="relative" ref={modalContainerRef}>
      <Button
        theme
        ref={buttonRef}
        className="flex items-center gap-2"
        onClick={() => setOpen(true)}
      >
        <div className={className}>
          <Render if={!avatar}>
            <Render if={loading}>
              <Icon
                icon={faSpinner}
                className="text-20"
                iconClassName="fa-spin-pulse"
              />
            </Render>
            <Render if={!loading}>
              <Avatar src={null} className="h-10 w-10" iconClassName="w-8" />
            </Render>
          </Render>
          <Render if={avatar}>
            <Avatar src={avatar} className="h-10 w-10" iconClassName="w-8" />
          </Render>
          {renderNotification(
            notificationCount,
            'absolute bottom-0 left-0 -translate-x-1/4 translate-y-1/4',
          )}
        </div>
        <Icon
          icon={faChevronDown}
          className={clsx('text-hannah transition-transform', {
            'rotate-90': isOpen,
          })}
        />
      </Button>
      <Transition in={isOpen} unmountOnExit mountOnEnter>
        <div
          className={clsx(
            'absolute right-0 top-full z-40 mt-2 min-w-[240px] rounded-2xl bg-white px-6 py-2 shadow-[0px_1px_3px_1px_rgba(0,0,0,0.25)] duration-300 animate-in fade-in slide-in-from-right-2',
            {
              'animate-in fade-in slide-in-from-right-2': isOpen,
              'animate-out fade-out slide-out-to-right-2': !isOpen,
            },
          )}
        >
          <Link
            variant="ghost"
            className={clsx(itemClassName, 'font-600')}
            to={PATH.PASSENGER_TRIPS}
          >
            My trips
            {renderNotification(trips)}
          </Link>
          <Link
            variant="ghost"
            className={clsx(itemClassName, 'font-600')}
            to={PATH.CHAT}
          >
            Messages
            {renderNotification(messages)}
          </Link>
          <div className="-mx-2 my-2.5 h-px bg-neural-02" />
          <Link variant="ghost" className={itemClassName} to={PATH.PROFILE}>
            Profile
          </Link>
          <Link variant="ghost" className={itemClassName} to={PATH.HELP_CENTRE}>
            Help centre
          </Link>
          <Link variant="ghost" className={itemClassName} to={PATH.CONTACT_US}>
            Contact us
          </Link>
          <Button
            variant="ghost-error"
            className={itemClassName}
            onClick={handleLogout}
          >
            Log out
          </Button>
        </div>
      </Transition>
    </div>
  );
};

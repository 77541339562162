import { Theme } from '@components';
import { Layout } from '@components/Layouts';
import notFoundImage from '@images/not-found.png';

export const NotFoundPage = () => {
  return (
    <Layout>
      <Theme.Wrapper
        slot="wrapper"
        className="flex flex-1 flex-col items-center justify-center"
      >
        <p className="mb-10 text-30 font-600 text-eva">Oops! No page found</p>
        <img src={notFoundImage} alt="Not found" width="360" />
      </Theme.Wrapper>
    </Layout>
  );
};

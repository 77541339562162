import { useNavigate } from 'react-router-dom';

import { PATH } from '@constants';
import { isAuthenticatedSelector } from '@selectors';
import { useSelector } from '@hooks';

import { Button, Link, Modal } from '@components';
import { AuthModal } from '@components/Ui';

type Props = {
  className?: string;
  children?: React.ReactNode;
};

export const CreateTripButton = ({ className, children }: Props) => {
  const isAuthenticated = useSelector(isAuthenticatedSelector);

  const navigate = useNavigate();

  if (isAuthenticated) {
    return (
      <Link theme to={PATH.TRIP_CREATING} className={className}>
        {children}
      </Link>
    );
  }

  return (
    <Modal
      content={<AuthModal onSuccess={() => navigate(PATH.TRIP_CREATING)} />}
    >
      <Button theme className={className}>
        {children}
      </Button>
    </Modal>
  );
};

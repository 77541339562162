import { useContext } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Trip } from '@typings';
import { toastifyError } from '@utils';

import { Button, ChaseSpinner, Render } from '@components';
import { Layout } from '@components/Layouts';
import { SearchTrip } from '@components/SearchTrip';
import { TripsContext } from '@components/TripsProvider';
import { TripCard } from '@components/Ui';

const sortings = [
  {
    title: 'By time',
    value: 'departure',
  },
  {
    title: 'Low price',
    value: 'price',
  },
  {
    title: 'High price',
    value: '-price',
  },
  {
    title: 'By rating',
    value: 'rating',
  },
];

export const SearchPage = () => {
  const [, setSearchParams] = useSearchParams();

  const {
    loading,
    isFetched,
    isEmpty,
    seats,
    trips,
    sort,
    params,
    pagination: { total, next },
    getTrips,
  } = useContext(TripsContext);

  const handleNextPage = async () => {
    if (!next) {
      return;
    }

    try {
      await getTrips(params, { page: next, sort });
    } catch (error) {
      toastifyError(error);
    }
  };

  const handleSortChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const sort = event.target.value;

    setSearchParams((searchParams) => {
      searchParams.set('sort', sort);

      return searchParams;
    });

    try {
      getTrips(params, { sort });
    } catch (error) {
      toastifyError(error);
    }
  };

  const makeSortOption = ({ title, value }: (typeof sortings)[number]) => (
    <option key={value} value={value}>
      {title}
    </option>
  );

  const makeItem = (trip: Trip.Item) => (
    <TripCard {...trip} bookingSeats={seats} key={trip.id} />
  );

  const renderContent = () => {
    if (!isFetched) {
      return (
        <div className="mt-20 flex justify-center">
          <ChaseSpinner color="eva" className="h-10 w-10" />
        </div>
      );
    }

    if (isEmpty) {
      return (
        <p className="mt-20 text-center text-24 font-600 text-eva">
          No trips found
        </p>
      );
    }

    return (
      <>
        <Render if={trips}>
          <div className="mb-2 mt-7 px-3 sm:px-0">
            <div className="mb-4 flex items-center justify-between">
              <p className="text-15 font-600 text-hannah">
                {total} {total > 1 ? 'trips' : 'trip'}
              </p>
              <select
                defaultValue={sort ?? undefined}
                onChange={handleSortChange}
                className="text-15 font-600 text-hannah"
              >
                {sortings.map(makeSortOption)}
              </select>
            </div>
            <div className="flex flex-col gap-2">{trips.map(makeItem)}</div>
          </div>
        </Render>
        <Render if={next}>
          <div className="mt-6 text-center">
            <Button loading={loading} variant="eva" onClick={handleNextPage}>
              Load more
            </Button>
          </div>
        </Render>
      </>
    );
  };

  return (
    <Layout headerVariant="without-border">
      <div className="mt-6 px-3 sm:px-0">
        <SearchTrip isOwn />
      </div>
      {renderContent()}
    </Layout>
  );
};

import { cn } from '@utils';

type Props = {
  className?: string;
  children?: React.ReactNode;
};

export const ThemeWrapper = ({ className, children }: Props) => (
  <div className={cn('mx-auto w-full max-w-[1280px] flex-1 px-2', className)}>
    {children}
  </div>
);

import { tv } from './tailwind';

export const buttonVariants = tv({
  base: 'relative inline-flex cursor-pointer items-center justify-center rounded-full py-1 px-6 transition-colors text-button h-11',
  variants: {
    variant: {
      primary:
        'bg-primary text-white  hover:bg-primary-dark disabled:bg-neural-01 disabled:text-neural-02',
      eva: 'bg-eva text-white  hover:bg-eva-dark disabled:bg-neural-01 disabled:text-neural-02',
      hannah:
        'bg-hannah text-white  hover:bg-hannah-dark disabled:bg-neural-01 disabled:text-neural-02',
      ghost:
        'bg-transparent text-primary  hover:text-primary-dark disabled:text-neural-04',
      'ghost-error':
        'bg-transparent text-error  hover:text-error-dark disabled:text-neural-04',
      'ghost-secondary':
        'bg-transparent text-secondary  hover:text-secondary-dark disabled:text-neural-04',
    },
  },
  defaultVariants: {
    variant: 'primary',
  },
});

export const fieldVariants = tv({
  base: 'block rounded-2xl px-4 py-2 w-full text-body-1-r text-primary border border-neural-03 focus:border-eva peer h-14 appearance-none transition-all',
  variants: {
    label: {
      true: 'pt-4',
    },
    error: {
      true: '!border-error',
    },
  },
});

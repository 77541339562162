import { useState } from 'react';

import { Pagination } from '@typings';

export const usePagination = () => {
  const [pagination, setPagination] = useState<Pagination.Payload>({
    total: 0,
    page: 1,
    next: null,
  });

  return { pagination, setPagination };
};

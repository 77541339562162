import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { getEmailVerificationToken } from '@services';
import { noop, normalizeFormErrors, setFormBackendErrors } from '@utils';

import { Button, Field } from '@components';

type Schema = z.infer<typeof schema>;

const schema = z.object({
  email: z.string().min(1).email(),
});

type Props = {
  email?: string;
  onSuccess?: (phone: string, token: string) => void;
};

export const EmailVerificationEmailModalContent = ({
  email,
  onSuccess = noop,
}: Props) => {
  const { register, formState, handleSubmit, getValues, setError } =
    useForm<Schema>({
      resolver: zodResolver(schema),
      defaultValues: { email },
    });

  const [loading, setLoading] = useState(false);

  const errors = normalizeFormErrors<keyof Schema>(formState.errors);

  const handleEmailSubmit = handleSubmit(async ({ email }) => {
    try {
      setLoading(true);

      const { verify: token } = await getEmailVerificationToken(email);

      onSuccess(email, token);
    } catch (error) {
      setFormBackendErrors({ error, setError, getValues });
    } finally {
      setLoading(false);
    }
  });

  return (
    <form onSubmit={handleEmailSubmit}>
      <Field.Input
        {...register('email')}
        autoFocus={false}
        label="Email"
        inputMode="email"
        type="email"
        error={errors.email}
      />
      <Button
        loading={loading}
        type="submit"
        variant="hannah"
        className="mt-6 w-full"
      >
        Send a code
      </Button>
    </form>
  );
};

import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useLocation, useOutletContext } from 'react-router-dom';

import { AnyFunction, TripOutletContext, Vehicle } from '@typings';
import { getEmailVerificationToken } from '@services';
import { UserState } from '@slices';
import {
  isEmailVerifiedSelector,
  userSelector,
  userVehicleSelector,
} from '@selectors';
import { useSelector } from '@hooks';
import { isObjectEmpty } from '@utils';
import { tripSteps } from '@content';

import { Button, Link, Modal, Theme } from '@components';
import {
  EmailVerificationModal,
  UpdateProfileModal,
  UpdateVehicleModal,
  UserFillingModal,
} from '@components/Ui';

type ModalType = 'vehicle' | 'profile' | 'email' | 'new-user' | null;

type Props = {
  loading?: boolean;
  title?: string;
  onHandle?: AnyFunction;
};

/**
 * This part of layout is handler for `TripLayout`
 * and aimed to be added to trip related pages.
 *
 * The cause of this component existence is detecting "Next" button click
 */
export const TripFooterLayout = ({ loading, title, onHandle }: Props) => {
  const isEmailVerified = useSelector(isEmailVerifiedSelector);
  const { firstName } = useSelector(userSelector);
  const vehicle = useSelector(userVehicleSelector);

  const { pathname } = useLocation();
  const { footerRef, setTitle, goNext } = useOutletContext<TripOutletContext>();

  /**
   * Generates unique ID for modals
   */
  const [uuid, setUuid] = useState('');
  /**
   * Manages email `confirm-email` modal's token after `new-user` process
   */
  const [emailConfirmationToken, setEmailConfirmationToken] = useState('');
  const [modal, setModal] = useState<ModalType>(null);

  const currentStep = tripSteps.findIndex((path) => pathname === path)!;
  const buttonBaseClassName = 'min-w-[240px] sm:min-w-min px-10';

  useEffect(() => {
    if (title) {
      setTitle(title);
    }
  }, [title, setTitle]);

  const resetModal = () => {
    setModal(null);
    setUuid('');
  };

  const resetEmailConfirmationModal = () => {
    resetModal();

    if (emailConfirmationToken) {
      setEmailConfirmationToken('');
    }
  };

  const handleModalChange = (modal: ModalType) => {
    const uuid = Math.random().toString(16).slice(2);

    setModal(modal);
    setUuid(uuid);
  };

  const handleSubmit = () => {
    const isNoVehicle = isObjectEmpty(vehicle);
    const isNewUser = !firstName && isNoVehicle;

    if (emailConfirmationToken) {
      handleModalChange('email');

      return null;
    }

    if (isNewUser) {
      handleModalChange('new-user');

      return null;
    }

    if (!firstName) {
      handleModalChange('profile');

      return null;
    }

    if (isNoVehicle) {
      handleModalChange('vehicle');

      return null;
    }

    if (!isEmailVerified) {
      handleModalChange('email');

      return null;
    }

    if (onHandle) {
      onHandle();
    }
  };

  const handleProfileFill = async ({ email }: UserState) => {
    try {
      const { verify: token } = await getEmailVerificationToken(email!);

      setEmailConfirmationToken(token);
      handleModalChange('email');
    } catch (error) {
      resetModal();
    }
  };

  const handeVehicleAdd = (vehicle: Vehicle) => {
    if (isEmailVerified && onHandle) {
      onHandle(vehicle);
    } else {
      setModal('email');
    }
  };

  const handleUserFilled = (token: string | null | undefined) => {
    if (token) {
      setEmailConfirmationToken(token);
      handleModalChange('email');
    } else {
      resetModal();
    }
  };

  const handleEmailConfirm = () => {
    resetModal();

    if (onHandle) {
      onHandle();
    }
  };

  const renderPrevButton = () => {
    if (currentStep === 0) {
      return <div />;
    }

    return (
      <Link
        variant="eva"
        className={buttonBaseClassName}
        to={tripSteps[currentStep - 1]}
      >
        Back
      </Link>
    );
  };

  const renderNextButton = () => {
    if (currentStep + 1 === tripSteps.length) {
      return (
        <Button
          loading={loading}
          variant="eva"
          className={buttonBaseClassName}
          onClick={handleSubmit}
        >
          All is ready!
        </Button>
      );
    }

    return (
      <Button
        loading={loading}
        variant="eva"
        className={buttonBaseClassName}
        onClick={onHandle ?? goNext}
      >
        Next
      </Button>
    );
  };

  if (!footerRef.current) {
    return null;
  }

  return (
    <>
      {createPortal(
        <footer className="relative border-t border-eva px-2 py-8">
          <Theme.Wrapper className="flex w-full justify-between">
            {renderPrevButton()}
            {renderNextButton()}
            {modal === 'new-user' && (
              <Modal
                defaultOpen
                key={uuid}
                onClose={resetModal}
                content={
                  <UserFillingModal
                    onSuccess={handleUserFilled}
                    closeModal={resetModal}
                  />
                }
              />
            )}
            {modal === 'vehicle' && (
              <Modal
                defaultOpen
                key={uuid}
                onClose={resetModal}
                content={<UpdateVehicleModal onSuccess={handeVehicleAdd} />}
              />
            )}
            {modal === 'profile' && (
              <Modal
                defaultOpen
                key={uuid}
                onClose={resetModal}
                content={<UpdateProfileModal onSuccess={handleProfileFill} />}
              />
            )}
            {modal === 'email' && (
              <Modal
                defaultOpen
                key={uuid}
                onClose={resetEmailConfirmationModal}
                content={
                  <EmailVerificationModal
                    token={emailConfirmationToken}
                    onSuccess={handleEmailConfirm}
                  />
                }
              />
            )}
          </Theme.Wrapper>
        </footer>,
        footerRef.current,
      )}
    </>
  );
};

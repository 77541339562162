export const HOME = '/';
export const SEARCH = '/search';
export const PROFILE = '/profile';
export const CHAT = '/messages';
export const TRIPS = '/trips';
export const PASSENGER_TRIPS = '/my-trips';
export const DRIVER_TRIPS = `${TRIPS}/driver`;

export const USER = '/user';

export const CONTACT_US = '/contact-us';
export const HELP_CENTRE = '/help-centre';

export const TERMS_AND_CONDITIONS = '/terms-and-conditions';
export const PRIVACY_POLICY = '/privacy-policy';
export const COKIE_POLICY = '/cookie-policy';

export const TRIP_CREATING = '/trip';
export const TRIP_CREATING_DEPARTURE_DATE = `${TRIP_CREATING}/departure-date`;
export const TRIP_CREATING_ROUTES = `${TRIP_CREATING}/routes`;
export const TRIP_CREATING_WAYPOINTS = `${TRIP_CREATING}/waypoints`;
export const TRIP_CREATING_DETAILS = `${TRIP_CREATING}/details`;
export const TRIP_CREATING_TARIFFS = `${TRIP_CREATING}/tariffs`;

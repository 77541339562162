import { RefObject, useEffect, useState } from 'react';

import { ensureArray } from '@utils';

type Options = {
  defaultValue?: boolean;
  /**
   * The elemenets beloningto to clickable space
   *
   * Think about opmtimization. Currently, developer has to memoize `space` if it is array because of re-renders
   */
  space?: RefObject<HTMLElement> | RefObject<HTMLElement>[];
};

export const useClickableState = (
  ref: RefObject<HTMLButtonElement>,
  { defaultValue = false, space = [] }: Options = {},
) => {
  const [value, setValue] = useState<boolean>(defaultValue);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      /**
       * Click event is targeted out of clickable area. For example, user clicked out of clickable zone
       */
      const isNotPersonal =
        ref.current && !ref.current.contains(event.target as Node);
      const isSpace = ensureArray<RefObject<HTMLElement>>(space).some(
        (ref) => ref.current?.contains(event.target as Node),
      );

      if (isNotPersonal && !isSpace) {
        setValue(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, space]);

  return [value, setValue] as const;
};

/**
 * Local storage constants
 */
export const JWT_TOKEN = 'JWT_TOKEN';

export const PHOTO_MIMETYPES = 'image/png, image/jpeg, image/gif, image/webp';

export const CURRENCY_SIGN = {
  USD: '$',
  CAD: 'CA$',
};

export const DATE_FORMAT = 'YYYY-MM-DD';

import { useEffect, useState } from 'react';
import { faLocationDot } from '@fortawesome/pro-solid-svg-icons';

import { AnyFunction, Location, Place, Trip } from '@typings';
import { as, cn, geocode, noop } from '@utils';

import { Button, Field, Icon, Modal } from '@components';

import { GoogleMapModal } from '../Modals';

type Props = {
  isActivated: boolean;
  city: string;
  geo: Location.Geo;
  onCityActivation?: AnyFunction;
  onLocationChange?: (location: Trip.Waypoint) => void;
};

export const TripWaypoint = ({
  isActivated,
  city,
  geo,
  onCityActivation,
  onLocationChange = noop,
}: Props) => {
  const [waypoint, setWaypoint] = useState<Trip.Waypoint>();

  const { address, formattedAddress } = as.o<Trip.Waypoint>(waypoint);
  const placeholder = `${city}'s address`;

  useEffect(() => {
    const run = async () => {
      const waypoint = await geocode({ geo });

      if (waypoint) {
        setWaypoint(waypoint);
        onLocationChange(waypoint);
      }
    };

    if (isActivated && geo && !waypoint) {
      run();
    }
    /**
     * No needed to track onLocationChange change
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActivated, geo, waypoint]);

  const handleLocationApply = (place: Place) => {
    const appliedWaypoint = { ...place, isActivated: true };

    setWaypoint(appliedWaypoint);
    onLocationChange(appliedWaypoint);
  };

  return (
    <div
      key={city}
      className="flex h-12 w-full gap-4 rounded-2xl bg-[#F8FCFF] px-2 py-1.5 md:h-auto md:flex-col md:gap-2 md:py-3"
    >
      <Field.Checkbox
        checked={isActivated}
        containerClassName="self-center basis-1/4 px-2 py-1 min-w-0 md:w-full"
        className="items-center font-500 text-primary [&>span]:truncate"
        iconClassName="text-0 peer-checked:bg-eva peer-checked:border-eva w-3 h-3 -mt-0.5"
        onChange={onCityActivation}
      >
        {city}
      </Field.Checkbox>
      <div className="h-full w-px bg-[#DBF0FF] md:h-px md:w-full" />
      <Modal
        content={
          <GoogleMapModal
            title={placeholder}
            geo={waypoint?.geo}
            onApply={handleLocationApply}
          />
        }
      >
        <Button
          theme
          disabled={!isActivated}
          className={cn(
            'flex flex-1 items-center justify-between gap-2 rounded-xl px-2 text-left text-body font-500 text-primary/25 transition-colors',
            { 'hover:bg-[#DBF0FF] text-primary': isActivated },
          )}
        >
          {formattedAddress || address || placeholder}
          <Icon icon={faLocationDot} className="text-22 text-hannah" />
        </Button>
      </Modal>
    </div>
  );
};

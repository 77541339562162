import axios from 'axios';

import { Any } from '@typings';
import { JWT_TOKEN, SERVER_API_URL } from '@constants';
import { formatToCamelCase, formatToSnakeCase, localStorage } from '@utils';

const instance = axios.create({
  baseURL: SERVER_API_URL,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use(
  (config) => {
    const state = { ...config };
    const { data } = state;
    const token = localStorage.get<string>(JWT_TOKEN);

    if (token) {
      state.headers.Authorization = `Bearer ${token}`;
    }

    if (data) {
      state.data = formatToSnakeCase(data);
    }

    return state;
  },
  (error) => Promise.reject(error),
);

instance.interceptors.response.use(
  ({ data, config: { formatResponse = true } }) =>
    formatResponse ? (formatToCamelCase(data) as Any) : data,
  ({
    response: {
      config: { formatResponse = true },
      data,
    },
  }) => {
    const formattedData = formatResponse ? formatToCamelCase(data) : data;

    return Promise.reject(formattedData);
  },
);

export default instance;

import type { User } from '@sentry/react';
import * as Sentry from '@sentry/react';

import { ENVIRONMENT, PACKAGE_VERSION, SENTRY_DSN } from '@constants';
import { UserState } from '@slices';
import { removeEmptyProperties } from '@utils';

export const sentry = {
  init: ({ debug = false } = {}) => {
    if (SENTRY_DSN) {
      Sentry.init({
        dsn: SENTRY_DSN,
        release: `beeple-web@${PACKAGE_VERSION}`,
        environment: ENVIRONMENT,

        /**
         * Set tracesSampleRate to 1.0 to capture 100%
         * of transactions for performance monitoring.
         */
        tracesSampleRate: 1.0,

        /**
         * If debug is enabled SDK will attempt to print out useful debugging
         * information if something goes wrong with sending the event.
         */
        debug,
      });
    }
  },

  setUser: ({ id, firstName, lastName, email, phone }: UserState) => {
    const user = removeEmptyProperties({
      id,
      firstName,
      lastName,
      email,
      phone,
    }) as User;

    Sentry.setUser(user);
  },
};

import { useRef } from 'react';
import { useOutletContext } from 'react-router-dom';
import { faMinus, faPlus } from '@fortawesome/pro-solid-svg-icons';

import { TripOutletContext } from '@typings';
import { setTrip } from '@slices';
import { tripSelector } from '@selectors';
import { useDispatch, useSelector } from '@hooks';

import { Button, Icon } from '@components';
import { TripLayout } from '@components/Layouts';
import { TripRules } from '@components/Ui';

export const TripDetailsPage = () => {
  const dispatch = useDispatch();
  const { seats, commentary } = useSelector(tripSelector);

  const { goNext } = useOutletContext<TripOutletContext>();
  const commentaryRef = useRef<HTMLTextAreaElement>(null);

  const isMinimalSeats = seats === 1;
  const isMaximalSeats = seats === 7;

  const handleSeatsChange = (diff: 1 | -1) => {
    dispatch(setTrip({ seats: seats + diff }));
  };

  const handleSubmit = () => {
    const commentary = commentaryRef.current?.value;

    if (commentary) {
      dispatch(setTrip({ commentary }));
    }

    goNext();
  };

  return (
    <div className="mt-4 flex w-full flex-1 flex-col items-center gap-6">
      <TripLayout.Handler title="Trip details" onHandle={handleSubmit} />
      <div className="flex flex-col">
        <h5 className="text-center text-h5">
          How many passengers can be added to the trip?
        </h5>
        <div className="mt-7 flex items-start justify-center gap-3">
          <Button
            theme
            className="mt-3 rounded-full bg-eva text-[18px] text-white transition-colors hover:bg-eva-dark disabled:bg-neural-03"
            disabled={isMinimalSeats}
            onClick={() => handleSeatsChange(-1)}
          >
            <Icon className="h-8 w-8" icon={faMinus} />
          </Button>
          <div className=" w-40">
            <p className="h-[52px] w-full rounded-xl border border-eva text-center text-h4 font-400">
              {seats}
            </p>
            <p
              className="mt-1 text-center text-caption-3 text-[#22AF27]"
              slot="note"
            >
              Think about the comfort of <br /> rear seat passengers
            </p>
          </div>
          <Button
            theme
            disabled={isMaximalSeats}
            className="mt-3 rounded-full bg-eva text-[18px] text-white transition-colors hover:bg-eva-dark disabled:bg-neural-03"
            onClick={() => handleSeatsChange(1)}
          >
            <Icon className="h-8 w-8" icon={faPlus} />
          </Button>
        </div>
      </div>
      <div className="mt-4 flex w-full justify-center gap-6 md:flex-col">
        <TripRules />
        <div className="flex basis-[560px] flex-col gap-6 md:basis-60">
          <h4 className="text-center text-title-1 font-500">
            Additional comment
          </h4>
          <textarea
            ref={commentaryRef}
            defaultValue={commentary}
            className="flex-1 resize-none rounded-2xl border border-transparent bg-[#FAFDFF] px-5 py-4 text-primary shadow-[0px_0px_5px_0px_#F2F2F2] transition-colors hover:border-neural-01 focus:border-eva"
          />
        </div>
      </div>
    </div>
  );
};

import { Navigate, Route, Routes } from 'react-router-dom';

import { PATH } from '@constants';
import { isAuthenticatedSelector } from '@selectors';
import { useSelector } from '@hooks';
import { p } from '@utils';

import * as Page from '@pages';
import { ProfileLayout, TripLayout, TripsLayout } from '@components/Layouts';
import { TripsProvider } from '@components/TripsProvider';

export const ApplicationRoutes = () => {
  const isAuthenticated = useSelector(isAuthenticatedSelector);

  const authenticatedUserRoutes = isAuthenticated && (
    <>
      <Route element={<ProfileLayout />}>
        <Route element={<Page.Profile />} path={PATH.PROFILE} />
      </Route>
      <Route
        element={<Page.Chat />}
        path={p(PATH.CHAT, ':tripId?', ':userId?')}
      />
      <Route element={<TripsLayout />}>
        <Route element={<Page.PassengerTrips />} path={PATH.PASSENGER_TRIPS} />
        <Route element={<Page.DriverTrips />} path={PATH.DRIVER_TRIPS} />
      </Route>
      <Route element={<TripLayout />}>
        <Route
          element={<Page.TripDepartureDate />}
          path={PATH.TRIP_CREATING_DEPARTURE_DATE}
        />
        <Route element={<Page.TripRoutes />} path={PATH.TRIP_CREATING_ROUTES} />
        <Route
          element={<Page.TripWaypoints />}
          path={PATH.TRIP_CREATING_WAYPOINTS}
        />
        <Route
          element={<Page.TripDetails />}
          path={PATH.TRIP_CREATING_DETAILS}
        />
        <Route
          element={<Page.TripTariffs />}
          path={PATH.TRIP_CREATING_TARIFFS}
        />
        <Route
          element={<Navigate replace to={PATH.TRIP_CREATING_DEPARTURE_DATE} />}
          path={PATH.TRIP_CREATING}
        />
      </Route>
    </>
  );

  return (
    <Routes>
      <Route element={<Page.Home />} path={PATH.HOME} />
      <Route path={p(PATH.USER, ':userId')} element={<Page.User />} />
      <Route element={<Page.ContactUs />} path={PATH.CONTACT_US} />
      <Route element={<Page.HelpCentre />} path={PATH.HELP_CENTRE} />
      <Route
        element={<Page.TermsConditions />}
        path={PATH.TERMS_AND_CONDITIONS}
      />
      <Route element={<Page.PrivacyPolicy />} path={PATH.PRIVACY_POLICY} />
      <Route element={<Page.CookiePolicy />} path={PATH.COKIE_POLICY} />
      <Route
        element={
          <TripsProvider>
            <Page.Search />
          </TripsProvider>
        }
        path={PATH.SEARCH}
      />
      {authenticatedUserRoutes}
      <Route element={<Page.NotFound />} path="*" />
    </Routes>
  );
};

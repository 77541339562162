import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { toast } from '@features';
import { reportSupport } from '@services';
import { userSelector } from '@selectors';
import { useSelector } from '@hooks';
import { normalizeFormErrors, setFormBackendErrors } from '@utils';

import { Button, Theme } from '@components';
import { Layout } from '@components/Layouts';
import { FieldInput } from '@components/Shared/Fields/FieldInput';
import { BackButton } from '@components/Ui';

type Schema = z.infer<typeof schema>;

const schema = z.object({
  firstName: z.string().min(1),
  phone: z.string().min(1),
  email: z.string().email().min(1),
  message: z.string().min(1),
});
export const ContactUsPage = () => {
  const { firstName, phone, email } = useSelector(userSelector);

  const { register, handleSubmit, setError, getValues, resetField, formState } =
    useForm<Schema>({
      resolver: zodResolver(schema),
      defaultValues: {
        firstName: firstName ?? undefined,
        phone,
        email: email ?? undefined,
      },
    });

  const [loading, setLoading] = useState(false);

  const errors = normalizeFormErrors(formState.errors);

  const handleReportSubmit = handleSubmit(async ({ phone, ...data }) => {
    try {
      setLoading(true);

      const formattedPhone = `+${phone.replace(/[^0-9]/g, '')}`;

      await reportSupport({ ...data, phone: formattedPhone });

      resetField('message');
      toast.success(
        'Thank you for contacting support. We will address it promptly',
      );
    } catch (error) {
      setFormBackendErrors({ error, setError, getValues });
    } finally {
      setLoading(false);
    }
  });

  return (
    <Layout headerVariant="with-border" className="flex flex-1 flex-col">
      <div className="my-6 flex items-center gap-3">
        <BackButton />
        <p className="text-18 font-600 text-eva">Contact us</p>
      </div>
      <div className="flex flex-1 items-center justify-between md:flex-col md:gap-8">
        <div className="text-hannah md:text-center">
          <p className="mb-10 text-32 font-700 md:mb-4">
            Still have questions? <br /> Fill the form out and we will help you
          </p>
          <p className="text-secondary">
            You can also email:{' '}
            <a href="mailto:contact@beeple.ca" className="underline">
              contact@beeple.ca
            </a>
          </p>
        </div>
        <div className="w-auto sm:w-full">
          <Theme.Container className="flex w-[444px] flex-col gap-3 px-7 py-8 sm:w-full sm:p-4">
            <form className="contents" onSubmit={handleReportSubmit}>
              <FieldInput
                {...register('firstName')}
                required
                label="First name"
                error={errors.firstName}
              />
              <FieldInput
                {...register('phone')}
                required
                label="Phone"
                error={errors.phone}
              />
              <FieldInput
                {...register('email')}
                required
                label="Email"
                error={errors.email}
              />
              <textarea
                {...register('message')}
                required
                placeholder="Describe your question"
                className="h-[280px] resize-none rounded-2xl border border-neural-03 p-4"
              />
              <Button loading={loading} variant="hannah" type="submit">
                Submit
              </Button>
            </form>
          </Theme.Container>
        </div>
      </div>
    </Layout>
  );
};

import { createSelector } from 'reselect';

import { State, Vehicle } from '@typings';
import { as, isEmailVerified, isObjectEmpty } from '@utils';

export const userSelector = (state: State) => state.user;

export const isAuthenticatedSelector = createSelector(
  userSelector,
  (user) => !isObjectEmpty(user),
);

export const isEmailVerifiedSelector = createSelector(
  userSelector,
  ({ emailVerifiedAt }) => isEmailVerified(emailVerifiedAt),
);

export const userVehicleSelector = createSelector(
  userSelector,
  ({ vehicles = [] }) => as.o<Vehicle>(vehicles[0]),
);

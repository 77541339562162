import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import { Location } from '@typings';
import { cn, noop } from '@utils';

import { Button, Render } from '@components';

type Props = {
  className?: string;
  predictions: Location.PlacePrediction[];
  onClick?: (prediction: Location.PlacePrediction) => void;
};

export const PlacePredictions = ({
  className,
  predictions,
  onClick = noop,
}: Props) => {
  const [target, setTarget] = useState<number>(0);

  useEffect(() => {
    setTarget(0);
  }, [predictions.length]);

  useEffect(() => {
    const predictionCount = predictions.length;

    const handleTargetChange = ({ key }: React.KeyboardEvent) => {
      if (key === 'ArrowDown' && target < predictionCount) {
        setTarget((target) => target + 1);
      }

      if (key === 'ArrowUp' && target > 0) {
        setTarget(target - 1);
      }

      if (key === 'Enter' && predictionCount) {
        onClick(predictions[target]);
      }
    };

    if (predictionCount) {
      // @ts-expect-error todo: fix type
      document.addEventListener('keydown', handleTargetChange);
    }

    return () => {
      if (predictionCount) {
        // @ts-expect-error todo: fix type
        document.removeEventListener('keydown', handleTargetChange);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target, predictions]);

  const makePrediction = (
    prediction: Location.PlacePrediction,
    index: number,
  ) => {
    const { placeId, description } = prediction;

    return (
      <Button
        theme
        key={placeId}
        className={clsx(
          'h-8 px-4 text-left text-caption text-eva transition-colors hover:bg-[#F1F1F1]',
          { 'bg-[#F1F1F1]': index === target },
        )}
        onClick={() => onClick(prediction)}
      >
        {description}
      </Button>
    );
  };

  return (
    <Render if={predictions}>
      <div
        className={cn(
          'absolute left-0 top-full z-30 mt-6 flex w-full flex-col rounded-xl bg-white py-2 shadow-[0px_1px_3px_1px_rgba(0,0,0,0.25)]',
          className,
        )}
      >
        {predictions.map(makePrediction)}
      </div>
    </Render>
  );
};

/* eslint-disable */
import { Layout } from '@components/Layouts';
import { PrivacyNavigationHeader } from '@components/Ui';

import '../../terms-page.css';

export const PrivacyPolicyPage = () => {
  return (
    <Layout>
      <PrivacyNavigationHeader />
      <div className="terms-page py-6">
        <h1>
          <span>Privacy and Data Protection Policy</span>
        </h1>
        <h2>
          <b>1. General</b>
        </h2>
        <div>
          <span>
            <br />
          </span>
          <span>Beeple Inc.</span>
          <span>
            , as the data controller, is dedicated to
            safeguarding your privacy. This document, known as the Privacy
            Policy, is intended to inform you about how we handle the
            collection, utilization, and disclosure of information you may
            supply through our platform, accessible via the website <a href="https://beeple.ca/">beeple.ca </a>
            or our mobile applications (the "Platform"). This Privacy Policy,
            along with our Terms and Conditions, any additional documents
            mentioned therein, and our Cookie Policy, delineates the framework
            for how any personal data collected from you, or provided by you,
            will be managed by us. We encourage you to read this carefully to
            fully understand our perspective and approach towards your personal
            data and its treatment.
          </span>
        </div>
        <h2>
          <b>2. Collecting information</b>
        </h2>
        <div>
          <b>2.1. Information you provide</b>
        </div>
        <h1>
          <span></span>
        </h1>
        <div>
          <span>
            You may supply us with data, including details that can identify you
            or the passengers for whom you make seat reservations ("Personal
            Data"), while utilizing our Platform. This can occur through various
            means such as completing forms on the Platform (e.g., the
            registration form), participating in our competitions, promotions,
            or surveys, communicating with us by phone, email, or other methods,
            and reporting issues concerning our Platform usage. The types of
            information you provide may include:
          </span>
        </div>
        <p>
          <span></span>
        </p>
        <p>
            2.1.1. Required details for registration and accessing the services
            we offer on our Platform, including your name, email address, date
            of birth, and phone number. These details are mandatory for account
            creation on our Platform. Your phone number is specifically needed
            for posting or booking carpool trips. Without this information, you
            will not be able to create a user account or access the services
            offered on our Platform;
        </p>
        <p>
            2.1.2. A photograph;
        </p>
        <p>
            2.1.3. A postal address;
        </p>
        <p>
            2.1.4. Your responses to any surveys or questionnaires, such as your
            reviews of trips conducted through our Platform. This information
            may be utilized for analytical and user understanding purposes;
        </p>
        <p>
            2.1.5. Details about your vehicles;
        </p>
        <p>
            2.1.6. A mini-biography and your gender, should you choose to
            provide this information;
        </p>
        <p>
            2.1.7. A record of any communications between you and Beeple,
            including phone calls, which may be recorded or monitored (for
            instance, with agents from our Community Relations Center). At the
            start of each call, you have the option to object to this
            processing;
        </p>
        <p>
            2.1.8. Records of your bookings or travel advertisements posted on
            our Platform; 2.1.9. Details of financial or accounting transactions
            conducted through our Platform or otherwise, including your bank
            account or payment card details, and specifics of trips you have
            booked or offered through our Platform, reflecting your travel
            preferences;
        </p>
        <p>
            2.1.10. Information about your visits to our Platform and the
            resources you access; 2.1.11. Information required from you when
            reporting a problem with our Platform or our services, including the
            subject of your request for support;
        </p>
        <p>
            2.1.12. Location data, to assist in finding trips near you or to
            help publish your trip offerings.
        </p>
        <h3>
          <b>2.2. Information we collect automatically</b>
        </h3>
        <p>
          <span>
            2.2.1. When you register using social media authentication options,
            Beeple Inc. accesses certain Personal Data from your social media
            account like your first name, surname, profile picture, and email,
            in line with the terms of the social media platforms. Additionally,
            we may gather some of your Personal Data through your interactions
            with third-party social media features, such as using the “Like”
            button.
          </span>
        </p>
        <p>
          <span>
            2.2.2. During each of your visits to our Platform, we may, as
            permitted by applicable laws and with your consent where necessary,
            collect data related to the devices and networks you utilize to
            access our services. This data may include: your IP address, log-in
            data, browser type and version, browser plugin types and versions,
            operating system and platform, details of your visit such as the URL
            clickstream to, through, and from our Platform, the content you
            viewed or searched for, search terms used, download errors, duration
            of visits to specific pages, your device’s advertising identifier,
            page interactions, and any phone number used to contact us. We
            gather this information using various technologies, including
            cookies. For more details, please see our Cookie Policy.
          </span>
        </p>
        <p>
          <span>
            2.2.3. We collect aggregated data about your activities on our
            Platform, such as the number of rides you offer and your message
            response rate. This information may be displayed on your public
            profile if you have one.
          </span>
        </p>
        <h1>
          <span></span>
        </h1>
        <div>
          <b>2.3. Data Retention</b>
        </div>
        <p>
          <span>2.3.1. With the exception of the Personal Data categories 
            outlined in clauses 2.3.2 and 2.3.3, the following retention periods
            apply to your Personal Data:
          </span>
        </p>
        <ul>
          <p>
            <span>
              - Your Personal Data will be archived 5 years after your last
              interaction with our Platform if your account remains open;
            </span>
          </p>
          <p>
            <span>
              - If you close your account, your data will be retained for 30 days,
              unless there is a negative rating or report associated with your
              account, in which case the data is kept for 2 years after account
              closure.
            </span>
          </p>
        </ul>
        <p>
          <span>
            2.3.2. Specific categories of Personal Data are stored for varying
            durations:
          </span>
        </p>
        <ul>
          <p>
            <span>
              - Financial records (such as payments and refunds) are retained as
              required by tax and accounting legislation, which may mandate
              keeping accounting records for up to 10 years;
            </span>
          </p>
          <p>
            <span>
              - User-generated content like comments, feedback, and ratings is
              anonymized following the periods mentioned in section 2.3.1 but
              remains accessible on our Platform;
            </span>
          </p>
          <p>
            <span>
              - Log data is kept for up to 12 months from the collection date;
            </span>
          </p>
          <p>
            <span>
              - Recordings of phone calls with our Community Relations service are
              stored for up to one month, and related analysis documents (like
              time and duration of the call) are kept for up to one year;
            </span>
          </p>
          <p>
            <span>
              - Data collected for ID verification and fraud detection purposes
              are retained for one year;
            </span>
          </p>
          <p>
            <span>
              - Payment card information, if you opted to save it for future
              transactions, is stored until you withdraw your consent or until
              the card's expiration date, whichever is sooner.
            </span>
          </p>
        </ul>
        <p>
          <span>
            2.3.3. If your account is suspended or blocked, your data will be
            retained for either 2 or 10 years, depending on the severity of the
            infraction, to prevent circumvention of the rules governing our
            Platforms.
          </span>
        </p>
        <p>
          <span>
            2.3.4. We may archive some of your Personal Data for a period not
            exceeding five (5) years to fulfill our legal obligations and manage
            potential disputes.
          </span>
        </p>
        <h1>
          <span></span>
        </h1>
        <h2>
          3. How do we use the information we collect from you?
        </h2>
        <div>
          <span>We will use the information we collect:</span>
        </div>
        <table>
          <tr>
            <td>
              <p>
                <span>PURPOSES</span>
              </p>
            </td>
            <p>
              <p>
                <span>LEGAL BASIS</span>
              </p>
            </p>
          </tr>
          <tr>
            <td>
              <p>
                <span>3.1. </span>
                <span>
                  To fulfill our obligations from any contracts between you and
                  us or between you and our business partners, and to provide
                  the requested information and services.
                </span>
              </p>
              <p>
                <span></span>
              </p>
            </td>
            <p>
              <p>
                <span>
                  This processing is necessary for the performance of our mutual
                  contractual obligations.
                </span>
              </p>
            </p>
          </tr>
          <tr>
            <td>
              <p>
                <span>3.2.</span>
                <span>
                  To send you service-related information by email, text
                  message, or other communication means (e.g., booking
                  confirmation).
                </span>
              </p>
              <p>
                <span></span>
              </p>
            </td>
            <p>
              <p>
                <span>
                  This processing is necessary for the performance of our mutual
                  obligations.
                </span>
              </p>
            </p>
          </tr>
          <tr>
            <td>
              <p>
                <span>3.3.</span>
                <span>
                  To collect payment from you or to enable payments via Stripe
                  on the Platform.
                </span>
              </p>
              <p>
                <span></span>
              </p>
            </td>
            <p>
              <p>
                <span>
                  This processing is necessary for the performance of our mutual
                  contractual obligations and for compliance with legal
                  obligations.
                </span>
              </p>
            </p>
          </tr>
          <tr>
            <td>
              <p>
                <span>3.4</span>
                <span>
                  To enable you to save your payment card information for future
                  transactions on the Platform.
                </span>
              </p>
              <p>
                <span></span>
              </p>
            </td>
            <p>
              <p>
                <span>
                  This processing is carried out with your consent. You can
                  withdraw your consent at any time by deleting your information
                  in your profile or by contacting our Community Relations
                  Center.
                </span>
              </p>
            </p>
          </tr>
          <tr>
            <td>
              <p>
                <span>3.5.</span>
                <span>
                  To enable you to personalize your user profile on our
                  Platform.
                </span>
              </p>
              <p>
                <span></span>
              </p>
            </td>
            <p>
              <p>
                <span>
                  This processing is carried out with your consent. You can
                  withdraw your consent at any time by deleting your information
                  in your profile or by contacting our Community Relations
                  Center.
                </span>
              </p>
            </p>
          </tr>
          <tr>
            <td>
              <p>
                <span>3.6.</span>
                <span>
                  To enable communication and interaction with other members on
                  the Platform regarding our services or trips, and to organize
                  such trips.
                </span>
              </p>
              <p>
                <span></span>
              </p>
            </td>
            <p>
              <p>
                <span>
                  This processing is necessary for the performance of our mutual
                  contractual obligations.
                </span>
              </p>
            </p>
          </tr>
          <tr>
            <td>
              <p>
                <span>3.7.</span>
                <span>
                  To provide access and communication with our Community
                  Relations Center.
                </span>
              </p>
              <p>
                <span></span>
              </p>
              <p>
                <span></span>
              </p>
            </td>
            <p>
              <p>
                <span>
                  This processing is (i) necessary for the performance of our
                  mutual contractual obligations, or (ii) necessary for the
                  establishment, exercise, or defense of legal claims.
                </span>
              </p>
            </p>
          </tr>
          <tr>
            <td>
              <p>
                <span>3.8.</span>
                <span>
                  To enhance our Community Relations Center and train our
                  customer agents by recording phone conversations with us.
                </span>
              </p>
              <p>
                <span></span>
              </p>
            </td>
            <p>
              <p>
                <span>
                  This processing is based on our legitimate interest (providing
                  high-quality customer support/enhancing customer support). You
                  can object to this processing during each contact.
                </span>
              </p>
            </p>
          </tr>
          <tr>
            <td>
              <p>
                <span>3.9.</span>
                <span>
                  To ensure compliance with applicable laws, our Terms and
                  Conditions, and our Privacy Policy. Certain breaches may lead
                  to the suspension of your account on our Platform.
                </span>
              </p>
              <p>
                <span></span>
              </p>
            </td>
            <p>
              <p>
                <span>
                  his processing is necessary (i) for the performance of our
                  mutual contractual obligations, (ii) based on our legitimate
                  interest (preventing rule circumvention on our Platform), in
                  which case you have the right to object, or (iii) for the
                  establishment, exercise, or defense of legal claims.
                </span>
              </p>
              <p>
                <span></span>
              </p>
              <p>
                <span></span>
              </p>
            </p>
          </tr>
          <tr>
            <td>
              <p>
                <span>3.10.</span>
                <span>
                  To send you marketing materials and recommendations about
                  goods or services related to our services, in accordance with
                  applicable laws and with your consent where required. We also
                  use your data for targeted advertising on social media
                  platforms or third-party sites.
                </span>
              </p>
              <p>
                <span></span>
              </p>
            </td>
            <p>
              <p>
                <span>
                  This processing is (i) based on your consent, which you can
                  withdraw at any time by modifying your preferences in your
                  profile, clicking the unsubscribe link in our communications,
                  or contacting our Community Relations Center; or (ii) based on
                  our legitimate interest (providing relevant advertisements),
                  in which case you have the right to object.
                </span>
              </p>
            </p>
          </tr>
          <tr>
            <td>
              <p>
                <span>3.11.</span>
                <span>
                  To notify you about changes to our services or assist you in
                  using our services, by email, text message, or other
                  communication means.
                </span>
              </p>
              <p>
                <span></span>
              </p>
            </td>
            <p>
              <p>
                <span>
                  This processing is necessary (i) for the performance of our
                  mutual contractual obligations, (ii) for compliance with our
                  legal obligations, or (iii) for the establishment, exercise,
                  or defense of legal claims.
                </span>
              </p>
            </p>
          </tr>
          <tr>
            <td>
              <p>
                <span>3.12.</span>
                <span>
                  To verify the information in your Passport, Driving Licence,
                  ID Card, and other identity documents collected at
                  registration or any other time during your use of our
                  Platform.
                </span>
              </p>
              <p>
                <span></span>
              </p>
            </td>
            <p>
              <p>
                <span>
                  This processing is carried out (i) based on your consent for
                  obtaining the Verified Profile badge (you can withdraw your
                  consent at any time by contacting our Community Relations
                  Center), (ii) for compliance with legal obligations, or (iii)
                  for the establishment, exercise, or defense of legal claims.
                </span>
              </p>
            </p>
          </tr>
          <tr>
            <td>
              <p>
                <span>3.13.</span>
                <span>
                  To administer our Platforms and for internal operations,
                  including troubleshooting, data analysis, testing, and
                  research.
                </span>
              </p>
              <p>
                <span></span>
              </p>
            </td>
            <td>
              <p>
                <span>
                  This processing is based on our legitimate interest (ensuring
                  the security of our Platform and improving its features).
                </span>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <span>3.14.</span>
                <span>
                  To monitor customer relations through satisfaction surveys,
                  studies, polls, product tests, and statistics via email or
                  phone calls.
                </span>
              </p>
              <p>
                <span></span>
              </p>
            </td>
            <td>
              <p>
                <span>
                  This processing is based on our legitimate interest
                  (evaluating our Platform and services to improve them). You
                  can object to this processing when contacted.
                </span>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <span>3.15.</span>
                <span>
                  To improve our Platform, ensuring that content is presented
                  effectively for you and your device.
                </span>
              </p>
              <p>
                <span></span>
              </p>
            </td>
            <td>
              <p>
                <span>
                  This processing is based on our legitimate interest (providing
                  meaningful content).
                </span>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <span>3.16.</span>
                <span>
                  To allow participation in interactive features of our service,
                  such as searching for trips or publishing trips based on your
                  location.
                </span>
              </p>
              <p>
                <span></span>
              </p>
            </td>
            <td>
              <p>
                <span>
                  This processing is necessary (i) for the performance of our
                  mutual contractual obligations or (ii) based on your consent
                  where required by law. You can withdraw your consent by
                  disabling access permissions in your phone’s operating system.
                </span>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <span>3.17.</span>
                <span>
                  To keep our Platform safe and secure, including fighting
                  against fraud.
                </span>
              </p>
              <p>
                <span></span>
              </p>
            </td>
            <td>
              <p>
                <span>
                  This processing is based on (i) our legitimate interest
                  (fighting fraud and ensuring Platform security), (ii)
                  compliance with legal obligations, or (iii) carried out for
                  the establishment, exercise, or defense of legal claims.
                </span>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <span>3.18.</span>
                <span>
                  To measure or understand the effectiveness of advertising and
                  deliver relevant advertising to our users.
                </span>
              </p>
              <p>
                <span></span>
              </p>
            </td>
            <td>
              <p>
                <span>
                  This processing is based on (i) our legitimate interest
                  (measuring and optimizing advertising efficiency) or (ii) your
                  consent where required by law. You can adjust your cookie
                  settings at any time in your Beeple Inc. account settings.
                </span>
              </p>
            </td>
          </tr>
        </table>
        <h1>
          <span></span>
        </h1>
        <h2>
          4. Recipients of Your Information and Their Purposes
        </h2>
        <p>
            4.1. Within our Platform, certain information about you is shared
            with community members during service use. This occurs on your
            public profile or through the booking process; for instance, your
            phone number may be shared with other members to coordinate shared
            rides.
        </p>
        <p>

            4.2. We exchange Personal Data with other Beeple Inc. entities and
            affiliates to support the services described in this Privacy Policy.

        </p>
        <p>

            4.3. We may disclose your information to legal and regulatory
            authorities such as courts, law enforcement, government agencies,
            and tax authorities, or to authorized third parties under certain
            circumstances. This includes situations where it's legally required,
            or where we believe it necessary to: (i) address legal claims made
            against Beeple  Inc., (ii) comply with judicial proceedings, (iii)
            enforce agreements with our members, including our Terms and
            Conditions and Privacy Policy, (iv) respond to emergencies that pose
            a threat to public health or safety, (v) conduct investigations, or
            (vi) protect the rights, property, or safety of Beeple Inc., its
            members, or others.

        </p>
        <p>

            4.4. We collaborate with various third parties who may receive your
            Personal Data:
        </p>
        <ul>
          <p>
            <span>
              - Social media platforms that connect your Beeple Inc. profile with
              their services;
            </span>
          </p>
          <p>
            <span>
              - Business partners advertising their services on our Platform,
              which may include insurance, banking, or rental services;
            </span>
          </p>
          <p>
            <span>
              - Business partners promoting our services on their websites;
            </span>
          </p>
          <p>
            <span>
              - Subcontractors in technical, payment, identity verification,
              delivery services, or analytics.
            </span>
          </p>
        </ul>
        <p>
            4.5. Sharing of your Personal Data with the third parties mentioned
            in section 4.4 occurs under these circumstances:
        </p>
        <p>
          <span>
            4.5.1. With third-party service providers to fulfill contracts with
            you, enhancing or facilitating our services (e.g., processing
            payments on our Platform);
          </span>
        </p>
        <p>
          <span>
            4.5.2. As part of the booking process, displaying your name, photo,
            mobile number, and/or email address on the Platform or sharing it
            with other members;
          </span>
        </p>
        <p>
          <span>
            4.5.3. Through our rating system, where your reviews and a reduced
            version of your name and photo are published on the Platform;
          </span>
        </p>
        <p>
          <span>
            4.5.4. Utilizing analytics and search engine providers to improve
            and optimize our Platform;
          </span>
        </p>
        <p>
          <span>
            4.5.5. When you specifically request it, such as by using social
            media authentication methods;
          </span>
        </p>
        <p>
          <span>
            4.5.6. When distributing parts of our Platform, like posted trips,
            on our business partners’ websites via APIs or widgets;
          </span>
        </p>
        <p>
          <span>
            4.5.7. In cases of business transactions like sales or acquisitions,
            where your Personal Data might be disclosed to prospective buyers or
            sellers.
          </span>
        </p>
        <h1>
          <span></span>
        </h1>
        <p>
            4.6. With your consent, where required by law, we may merge your
            Personal Data and cookie information we exchange with our business
            partners, using this combined information solely for the outlined
            purposes.
        </p>
        <h1>
          <span></span>
        </h1>
        <p>

            4.7. If you engage with our services through our business partners'
            connecting services, be aware that their privacy policies may also
            apply to you, in addition to ours. We do not govern how these
            partners collect or process your information on their platforms.

        </p>
        <h1>
          <span></span>
        </h1>
        <h1>
          <span></span>
        </h1>
        <h1>
          <span></span>
        </h1>
        <p>

            4.8. In accordance with applicable regulations, financial
            transactions on our platform, specifically payouts, are managed by
            Stripe, a leading global payment solutions provider. Stripe acts as
            a payment processor, independently managing payment processing in
            compliance with its obligations as a financial entity under various
            financial sector regulations.

        </p>
        <p>
          <span>
            The personal data collected and processed by Stripe during payment
            transactions are governed by Stripe's privacy policy. This includes
            data necessary for payment processing such as your credit card
            details, billing information, and any other data required for
            financial transactions. As detailed in our Terms and Conditions,
            Stripe may also require you to provide additional information for
            identity verification and fraud prevention purposes, commonly
            referred to as "Know Your Customer" (KYC) procedures.
          </span>
        </p>
        <p>
          <span>
            The KYC checks carried out by Stripe are separate and distinct from
            any verifications that Beeple Inc. might perform in relation to the
            use of our Platform. These checks by Stripe ensure compliance with
            legal standards and are aimed at maintaining the security and
            integrity of financial transactions. For more information on how
            Stripe handles personal data, please refer to the Stripe privacy
            policy available on their website.
          </span>
        </p>
        <h1>
          <span></span>
        </h1>
        <h1>
          <span></span>
        </h1>
        <h2>
          5. How we handle and moderate your messages
        </h2>
        <h1>
          <span></span>
        </h1>
        <p>
            5.1. We may review the messages you send to other members of our
            community through our Platform to prevent fraud, enhance our
            services, provide customer support, and ensure compliance with
            agreements made with our members, such as our Terms and Conditions.
            For instance, to prevent bypassing our online booking system, we
            might scan and analyze messages exchanged via our Platform to ensure
            they do not contain any contact details or references to other
            websites. Depending on their content, messages may be blocked or
            undergo partial or complete filtering.
        </p>
        <p>
            5.2. We do not scan or analyze your messages with other community
            members for commercial or advertising purposes. While we might use
            automated methods to moderate these messages, no automated decisions
            are made concerning individuals based on this moderation.
        </p>
        <h1>
          <span></span>
        </h1>
        <h2>
            6. Targeted Advertising and Communications via Email and Text Messages
        </h2>
        <p>
          <span>
            In line with applicable laws and with your consent where necessary,
            we utilize the information you provide on our Platform to engage in
            electronic direct marketing activities. These activities may include
            (i) sending you our newsletters, (ii) inviting you to our events or
            sending other communications we believe you might find interesting,
            or (iii) delivering targeted advertisements to you on social media
            platforms or on third-party websites, tailored to your profile and
            interests.
          </span>
        </p>
        <p>
          <span>
            For electronic marketing communications: You have the option to
            withdraw your consent at any time. This can be done by (i) adjusting
            your preferences in your profile settings, (ii) clicking on the
            unsubscribe link included in each of our communications, or (iii)
            reaching out to us using the contact details listed in section 1
          </span>
          <span>2</span>
          <span>.</span>
        </p>
        <p>
          <span>For targeted advertisements and content:</span>
        </p>
        <ul>
          <p>
            <span>- On social media platforms (e.g., Twitter, Facebook): You can opt out at any time either by adjusting your
              advertisement settings directly through your social media account
              or by contacting us as per article 13.
            </span>
          </p>
          <p>
            <span>
              - On third-party websites: Please refer to our Cookie Policy for
              instructions on how to object to such practices.
            </span>
          </p>
        </ul>
        <p id="h.az7n093kjvpj">
          <span></span>
        </p>
        <h2>
          <span>7. Is your Information being transferred, how and where?</span>
        </h2>
        <p>
          <span>
            Primarily, we store the Personal Data we collect about you in
            Canada. Nevertheless, some of our service providers are located in
            countries outside of Canada ("third countries"). Consequently, we
            may transfer some of your data to these third countries. This
            includes transfers to countries that may not have data protection
            laws as comprehensive as those in Canada.
          </span>
        </p>
        <p>
          <span>
            In cases where we transfer data to third countries without adequate
            levels of data protection as defined by Canadian standards, we
            ensure that the transfers comply with The Personal Information
            Protection and Electronic Documents Act (PIPEDA) and that
            appropriate safeguards are in place. These safeguards typically
            include implementing standard contractual clauses approved by
            Canadian authorities to ensure a sufficient level of protection for
            your personal information.
          </span>
        </p>
        <p>
          <span>
            Should you require more details about these safeguards, such as
            specifics regarding the standard contractual clauses, you can
            contact our Group Data Protection Officer at privacy@beeple.ca. This
            approach ensures that your personal data remains protected in line
            with Canadian legal standards when transferred internationally.
          </span>
        </p>
        <h1>
          <span></span>
        </h1>
        <h2>
          <span>8. Your Rights Regarding Personal Data</span>
        </h2>
        <p>
          <span>
            8.1. You have the right to request and obtain a copy of the personal
            data we hold about you (your right of data access).
          </span>
        </p>
        <p>
          <span>
            8.2. You can withdraw your consent for processing activities that
            are based on your consent at any time, as outlined in Article 3.
          </span>
        </p>
        <p>
          <span>
            8.3. You have the right to ask for the deletion of your personal
            data or the correction of any inaccuracies within that data (your
            rights to erasure and rectification). However, we may retain certain
            information about you as mandated by law or if we have a legitimate
            reason to do so, such as preventing fraud or ensuring compliance
            with our Terms and Conditions if we suspect a violation.
          </span>
        </p>
        <p>
          <span>
            8.4. You are entitled to object to the processing of your personal
            data at any time if it is for direct marketing purposes or if the
            processing is based on our legitimate interests (your right to
            object to processing).
          </span>
        </p>
        <p>
          <span>
            8.5. You have the right to restrict the processing of your personal
            data under certain conditions, such as if you dispute the accuracy
            of your data and we are verifying its accuracy, if you have objected
            to processing and we are determining whether our legitimate grounds
            override yours, if the data has been unlawfully processed and you
            opt for restriction over deletion, or if we no longer need the data
            but it is required by you to establish, exercise, or defend legal
            claims (your right to restriction of processing).
          </span>
        </p>
        <p>
          <span>
            8.6. You have the right to data portability, meaning you can receive
            the personal data you provided to us in a structured, commonly used,
            and machine-readable format and have the right to transmit that data
            to another controller (your right to data portability).
          </span>
        </p>
        <p>
          <span>
            8.7. You have the right to lodge a complaint with the appropriate
            data protection authority or seek judicial remedy if you believe
            your rights have been violated.
          </span>
        </p>
        <p>
          <span>
            8.8. You can set directives concerning the handling of your personal
            data posthumously.
          </span>
        </p>
        <p>
          <span>
            8.9. To exercise any of these rights, please reach out to the Group
            Data Protection Officer via the contact details listed in section
            12.
          </span>
        </p>
        <h2>
          <span>9. Cookies</span>
        </h2>
        <p>
          <span>To find out more, please see our Cookie Policy.</span>
        </p>
        <h1>
          <span></span>
        </h1>
        <h1>
          <span></span>
        </h1>
        <h2>
          <span>10. External Links and Social Media</span>
        </h2>
        <p>
          <span></span>
        </p>
        <p>
          <span>
            Periodically, our Platform may include links to the websites of our
            partner networks, advertisers, and affiliates. When you follow a
            link to any of these external sites, it's important to understand
            that these sites operate under their own privacy policies. We do not
            hold responsibility or liability for these independent policies. We
            advise you to review the privacy practices of these sites before
            submitting any Personal Data to them.
          </span>
        </p>
        <p>
          <span></span>
        </p>
        <h2>
          <span>11.Updates to Our Privacy Policy</span>
          <span></span>
        </h2>
        <p>
          <span></span>
        </p>
        <p>
          <span>
            We may update our Privacy Policy from time to time. Any changes will
            be posted on this page. We will notify you or seek your consent
            where necessary. Please visit this page regularly to stay informed
            of any changes to our Privacy Policy.
          </span>
        </p>
        <p>
          <span></span>
        </p>
        <h2>
          <span>12. Contact Information &amp; Data Protection</span>
        </h2>
        <p>
          <span></span>
        </p>
        <p>
          <span>
            Officer If you have any questions regarding this Privacy Policy or
            wish to make requests concerning your Personal Data, please contact
            our Data Protection Officer via email at privacy@beeple.ca.
          </span>
        </p>
        <h1>
          <span></span>
        </h1>
        <h1>
          <span></span>
        </h1>
      </div>
    </Layout>
  );
};

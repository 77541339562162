import { Any } from '@typings';

import { isObjectEmpty } from '../isObjectEmpty';
import { isArray, isObject } from '../typeof';

export type BackendValidationErrors = Array<{
  field: string;
  message: string;
}>;

/**
 * todo: fix type
 */
type Options = {
  error: Any;
  setError: Any;
  getValues: Any;
  replace?: string[][];
};

export const setFormBackendErrors = ({
  error,
  getValues,
  setError,
  replace = [],
}: Options) => {
  const errors = error?.errors;

  if (!isObject(errors)) {
    return;
  }

  if (isObjectEmpty(errors)) {
    return true;
  }

  const values = getValues();

  Object.entries(errors).forEach(([errorFieldName, errors]) => {
    const [fieldReplaceTo, fieldToReplace] =
      replace.find(([source]) => source === errorFieldName) ?? [];
    const isMatched = !!fieldReplaceTo;
    const fieldName = fieldToReplace ?? errorFieldName;
    /**
     * Skip `fieldToReplace` checking for `values[fieldToReplace]` existence, because `replace` is about developer custom field names
     */
    const isFieldNameRegistered =
      isMatched || Object.prototype.hasOwnProperty.call(values, errorFieldName);

    if (isFieldNameRegistered && isArray(errors)) {
      setError(fieldToReplace ?? fieldName, { message: errors[0] });
    }
  });
};

import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import dayjs from 'dayjs';
import { z } from 'zod';
import { faImageUser } from '@fortawesome/pro-light-svg-icons';
import clsx from 'clsx';

import { AnyFunction, ModalProps } from '@typings';
import { PATH, PHOTO_MIMETYPES } from '@constants';
import { toast } from '@features';
import { updateProfile } from '@thunks';
import { userSelector } from '@selectors';
import { useDispatch, useSelector } from '@hooks';
import {
  as,
  cn,
  getFileForSubmit,
  getFileImage,
  noop,
  normalizeFormErrors,
  setFormBackendErrors,
  zodErrorMessage,
} from '@utils';
import { birthdayOption } from '@content';

import { Avatar, Button, Field, Icon, Link, Modal, Render } from '@components';

const AVATAR_MAX_SIZE = 5242880;

type Schema = z.infer<typeof schema>;

const schema = z.object({
  firstName: z.string().min(1, zodErrorMessage('First name is required')),
  lastName: z.string(),
  avatar: z
    .union([z.instanceof(FileList), z.string(), z.null()])
    .refine((avatar) => !!avatar, zodErrorMessage('Photo is required'))
    .refine((avatar) => {
      if (avatar instanceof FileList) {
        return avatar[0].size < AVATAR_MAX_SIZE;
      }

      /**
       * Pass if not binary file
       */
      return true;
    }, zodErrorMessage('Max size is up to 5 MB')),
  birthday: z
    .object({
      day: z
        .string()
        .min(1, zodErrorMessage('Date is required', 'birthday'))
        .max(2, zodErrorMessage('Day is invalid')),
      month: z
        .string()
        .min(1, zodErrorMessage('Month is required'))
        .max(2, zodErrorMessage('Month is invalid')),
      year: z
        .string()
        .min(1, zodErrorMessage('Year is required'))
        .min(4, zodErrorMessage('Year is invalid'))
        .max(4, zodErrorMessage('Year is invalid')),
    })
    .refine(
      ({ day, month, year }) => {
        const date = `${month}.${day}.${year}`;
        const isValid = dayjs(date, 'MM.DD.YYYY', true).isValid();

        return isValid;
      },
      zodErrorMessage('Date of birth is invalid', 'birthday'),
    )
    .refine(
      ({ day, month, year }) => {
        const date = `${month}.${day}.${year}`;
        const birthday = dayjs(date, 'MM.DD.YYYY');
        const now = dayjs();
        const isAdult = now.diff(birthday, 'year') >= 18;

        return isAdult;
      },
      zodErrorMessage('You must be 18 years of age or older', 'birthday'),
    ),
  email: z.string().min(1, zodErrorMessage('Email is required')).email(),
});

type Props = ModalProps & {
  onSuccess?: AnyFunction;
};

export const UpdateProfileModal = ({
  onSuccess = noop,
  closeModal = noop,
}: Props) => {
  const dispatch = useDispatch();
  const {
    avatar: userAvatar,
    email,
    firstName,
    lastName,
    dateOfBirth,
  } = useSelector(userSelector);

  const [month, day, year] = as(dateOfBirth, '').split('.');

  const { watch, register, formState, handleSubmit, getValues, setError } =
    useForm<Schema>({
      resolver: zodResolver(schema),
      defaultValues: {
        avatar: userAvatar,
        email: as(email),
        firstName: as(firstName),
        lastName: as(lastName),
        birthday: { day, month: month || undefined, year },
      },
    });

  const [loading, setLoading] = useState(false);

  const errors = normalizeFormErrors<keyof Schema>(formState.errors);
  const watchAvatar = watch('avatar');
  const avatar = getFileImage(watchAvatar);
  const birthdayErrors = normalizeFormErrors<
    keyof Schema['birthday'] | 'birthday'
  >(
    /**
     * todo: resolve type
     */
    // @ts-ignore
    formState.errors.birthday,
  );

  const handleProfileSubmit = handleSubmit(
    async ({ avatar, birthday: { day, month, year }, ...data }) => {
      try {
        setLoading(true);

        const dateOfBirth = `${month}.${day}.${year}`;
        const payload = {
          ...data,
          dateOfBirth,
          avatar: getFileForSubmit(avatar),
        };

        const user = await dispatch(updateProfile(payload));

        toast.success('Your profile has been successfully updated');
        onSuccess(user);
        closeModal();
      } catch (error) {
        setFormBackendErrors({
          error,
          getValues,
          setError,
          replace: [['dateOfBirth', 'birthday.birthday']],
        });
      } finally {
        setLoading(false);
      }
    },
  );

  /**
   * Class names for inputs
   */
  const cx = (className?: string, error?: string | boolean): string =>
    clsx('relative focus:z-20', className, { 'z-10': error });

  const renderFootnote = (
    title: string,
    ...errors: Array<string | undefined>
  ) => {
    const error = errors.find((error) => error);

    if (error) {
      return <Field.Error>{error}</Field.Error>;
    }

    return <Field.Note>{title}</Field.Note>;
  };

  return (
    <Modal.Content title="Profile" className="w-[640px]">
      <form onSubmit={handleProfileSubmit}>
        <div className="flex [&>*]:flex-1">
          <Field.Input
            {...register('firstName')}
            showError={false}
            label="First name"
            className={cx('rounded-r-none capitalize', errors.firstName)}
            error={errors.firstName}
          />
          <Field.Input
            {...register('lastName')}
            showError={false}
            label="Last name"
            className={cx('-ml-px rounded-l-none capitalize', errors.lastName)}
            error={errors.lastName}
          />
        </div>
        {renderFootnote(
          'Make sure it matches the name on your government ID',
          errors.firstName,
          errors.lastName,
        )}
        <div className="mt-6 flex [&>*]:flex-1">
          <Field.Select
            {...register('birthday.year')}
            showError={false}
            label="Year"
            className={cx('rounded-r-none w-full', birthdayErrors.year)}
            options={birthdayOption.years}
            error={birthdayErrors.year}
          />
          <Field.Select
            {...register('birthday.month')}
            showError={false}
            label="Month"
            className={cx('rounded-none -ml-px w-full', birthdayErrors.month)}
            options={birthdayOption.months}
            error={birthdayErrors.month}
          />
          <Field.Select
            {...register('birthday.day')}
            showError={false}
            label="Day"
            className={cx(
              'rounded-l-none -ml-0.5 w-auto w-full',
              birthdayErrors.day,
            )}
            options={birthdayOption.days}
            error={birthdayErrors.day}
          />
        </div>
        {renderFootnote(
          "To continue, you need to be at least 18 years old. Your birthday won't be shared with other people who use Beeple",
          errors.birthday,
          birthdayErrors.day,
          birthdayErrors.month,
          birthdayErrors.year,
          birthdayErrors.birthday,
        )}
        <Field.Input
          {...register('email')}
          label="Email"
          type="email"
          inputMode="email"
          note="We'll email you trip confirmations and receipts"
          className="mt-6"
          error={errors.email}
        />
        <Field.File
          {...register('avatar')}
          accept={PHOTO_MIMETYPES}
          className={cn(
            'group relative mt-4 flex h-[100px] w-full rounded-2xl border bg-[#7c909b]/10 text-secondary transition-colors hover:border-primary',
            { 'border-error': errors.avatar },
          )}
        >
          <Button theme className="pointer-events-none w-full">
            <Render if={avatar}>
              <Avatar
                shape="rectangle"
                src={avatar}
                className="pointer-events-none absolute inset-y-0 left-3 m-auto h-20 w-20"
              />
            </Render>
            <p className="flex items-center justify-center gap-2 text-14 transition-colors group-hover:text-primary sm:flex-col sm:gap-0">
              <Icon icon={faImageUser} className="text-24" />
              {avatar ? 'Change' : 'Upload'} photo
            </p>
          </Button>
        </Field.File>
        <Field.Error variant="static">{errors.avatar}</Field.Error>
        <Render if={!errors.avatar}>
          <Field.Note>
            With a real photo, you will inspire more trust among other users.
            Max size is up to 5 MB
          </Field.Note>
        </Render>
        <Field.Note className="mt-6">
          By continuing you agree to Beeple’s&nbsp;
          <Link
            theme
            external
            blank
            to={PATH.TERMS_AND_CONDITIONS}
            className="underline"
          >
            Terms and Conditions
          </Link>
          ,&nbsp;
          <Link
            theme
            external
            blank
            to={PATH.COKIE_POLICY}
            className="inline-block underline"
          >
            Cookie policy
          </Link>
          &nbsp;and acknowledge&nbsp;
          <Link
            theme
            external
            blank
            to={PATH.PRIVACY_POLICY}
            className="inline-block underline"
          >
            Privacy and Data protection Policy
          </Link>
        </Field.Note>
        <Button
          loading={loading}
          type="submit"
          variant="hannah"
          className="mt-2 w-full"
        >
          Update
        </Button>
      </form>
    </Modal.Content>
  );
};

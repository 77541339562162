import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { AnyFunction } from '@typings';
import { PATH } from '@constants';
import { toast } from '@features';
import { authorizeUser } from '@thunks';
import { useDispatch } from '@hooks';
import { noop, setFormBackendErrors, zodErrorMessage } from '@utils';

import { Button, Field, Link } from '@components';

type Schema = z.infer<typeof schema>;

const schema = z.object({
  code: z.string().min(1, zodErrorMessage('Code is required')),
});

type Props = {
  countryCode: string;
  phone: string;
  token: string;
  onBack?: () => void;
  onSuccess?: AnyFunction;
};

export const AuthCodeModalContent = ({
  countryCode,
  phone,
  token,
  onBack = noop,
  onSuccess = noop,
}: Props) => {
  const dispatch = useDispatch();

  const { control, handleSubmit, setError, getValues } = useForm<Schema>({
    resolver: zodResolver(schema),
  });

  const [loading, setLoading] = useState(false);

  const handleCodeSubmit = handleSubmit(async ({ code }) => {
    try {
      setLoading(true);

      await dispatch(authorizeUser({ code, token }));

      toast.success('Welcome to Beeple!');
      onSuccess();
    } catch (error) {
      setFormBackendErrors({ error, setError, getValues });
    } finally {
      setLoading(false);
    }
  });

  return (
    <form onSubmit={handleCodeSubmit}>
      <p className="mb-6">
        Enter the code we sent over SMS to <b>{countryCode + phone}</b>
      </p>
      <Controller
        control={control}
        name="code"
        render={({ field: { onChange }, fieldState: { error } }) => {
          const fieldError = error?.message;

          return (
            <>
              <Field.OtpInput
                error={fieldError}
                onChange={(value) => onChange({ target: { value } })}
              />
              <Field.Error className="text-center">{fieldError}</Field.Error>
              <Field.Note className="mt-4 inline-block text-center">
                By confirming, I agree to to Beeple’s&nbsp;
                <Link
                  theme
                  external
                  blank
                  to={PATH.TERMS_AND_CONDITIONS}
                  className="underline"
                >
                  Terms and Conditions
                </Link>
                ,&nbsp;
                <Link
                  theme
                  external
                  blank
                  to={PATH.COKIE_POLICY}
                  className="inline-block underline"
                >
                  Cookie policy
                </Link>
                &nbsp;and acknowledge{' '}
                <Link
                  theme
                  external
                  blank
                  to={PATH.PRIVACY_POLICY}
                  className="inline-block underline"
                >
                  Privacy and Data protection Policy
                </Link>
              </Field.Note>
            </>
          );
        }}
      />
      <Button
        loading={loading}
        type="submit"
        variant="hannah"
        className="mt-2 w-full"
      >
        Confirm
      </Button>
      <Button
        variant="ghost-secondary"
        className="mx-auto mt-2 flex"
        onClick={onBack}
      >
        Back
      </Button>
    </form>
  );
};

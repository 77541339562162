import { isString } from '@utils';

export const getFileImage = (
  source: FileList | string | null,
  placeholder: string = '',
) => {
  if (!source) {
    return placeholder;
  }

  if (isString(source)) {
    return source;
  }

  const file = source?.[0];

  if (file) {
    return URL.createObjectURL(file);
  }

  return placeholder;
};

export const getFileForSubmit = (
  source: FileList | File | string | null | undefined,
) => {
  if (source instanceof FileList) {
    return source[0];
  }

  /**
   * Server won't accept string
   */
  if (isString(source)) {
    return undefined;
  }

  return source;
};

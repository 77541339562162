import { faCircleXmark } from '@fortawesome/pro-thin-svg-icons';

import { SUPPORT_EMAIL } from '@constants';

import { Icon, Link, Theme } from '@components';

export const FallbackError = () => {
  return (
    <Theme.Wrapper className="flex flex-col items-center gap-10 py-40">
      <Icon icon={faCircleXmark} className="h-24 w-24 text-88 text-neural-03" />
      <div className="flex max-w-[440px] flex-col gap-2 text-center">
        <p className="text-h5">Something went wrong</p>
        <p>
          For further assistance, please contact support at&nbsp;
          <Link
            external
            theme
            to={`mailto:${SUPPORT_EMAIL}`}
            className="text-primary underline hover:no-underline"
          >
            {SUPPORT_EMAIL}
          </Link>
        </p>
      </div>
    </Theme.Wrapper>
  );
};

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as sentry from '@sentry/react';
import dayjs from 'dayjs';
import clsx from 'clsx';

import { Trip } from '@typings';
import { DATE_FORMAT, PATH } from '@constants';
import { toast } from '@features';
import { getRecentTrips } from '@services';
import { geocode, getFormattedPlace } from '@utils';

import { Button, ChaseSpinner, Render } from '@components';

export const RecentTrips = () => {
  const navigate = useNavigate();

  const [loadingId, setLoadingId] = useState<string | null>(null);
  const [total, setTotal] = useState(0);
  const [trips, setTrips] = useState<Trip.Recent[]>([]);

  useEffect(() => {
    const getTrips = async () => {
      try {
        let length = 0;
        const { total, trips } = await getRecentTrips();

        if (trips.length >= 8) {
          length = 8;
        } else if (trips.length >= 4) {
          length = 4;
        }

        setTotal(total);
        setTrips(trips.slice(0, length));
      } catch (error) {
        /**
         * Continue regardless error
         */
      }
    };

    getTrips();
  }, []);

  const sortEnabledTrips = (a: Trip.Recent, b: Trip.Recent) =>
    +b.enabled - +a.enabled;

  const handleTripNavigate = async ({
    id,
    origin,
    destination,
    departure,
  }: Trip.Recent) => {
    try {
      setLoadingId(id);

      const [originPlace, destinationPlace] = await Promise.all([
        geocode({ geo: origin.geo }),
        geocode({ geo: destination.geo }),
      ]);

      if (!originPlace || !destinationPlace) {
        toast.error('Something went wrong');
        sentry.captureMessage(
          `Could not get place for "New ride" ${origin.city} - ${destination.city}`,
        );

        return;
      }

      const dateDayjs = dayjs(departure);
      const formattedDate = dateDayjs.format(DATE_FORMAT);
      const params = {
        originPlaceId: originPlace.placeId,
        destinationPlaceId: destinationPlace.placeId,
        seats: 1,
        date: formattedDate,
      };
      // @ts-expect-error setting seats as number
      const searchParams = new URLSearchParams(params);

      navigate(`${PATH.SEARCH}?${searchParams.toString()}`);
    } catch (error) {
      /**
       * foo
       */
    } finally {
      setLoadingId(null);
    }
  };

  const makeTrip = (trip: Trip.Recent) => {
    const { id, origin, destination, departure, price, enabled } = trip;
    const date = dayjs(departure);
    const loading = id === loadingId;

    return (
      <Button
        theme
        key={id}
        loading={loading}
        className={clsx(
          'relative block overflow-hidden rounded-3xl border border-transparent p-5 pt-4 shadow-[0px_2px_4px_2px_#F5F5F5] transition-colors hover:border-eva',
          { 'pointer-events-none opacity-50': !enabled },
        )}
        onClick={() => handleTripNavigate(trip)}
      >
        <Render if={loading}>
          <div className="absolute inset-0 flex items-center justify-center bg-white/75">
            <ChaseSpinner color="hannah" />
          </div>
        </Render>
        <p className="mb-3 flex justify-between text-14 font-500 text-primary">
          <span className="text-eva">{date.format('MMM DD, YYYY')}</span>
          <span>{date.format('h:mm A')}</span>
        </p>
        <div className="flex items-end justify-between gap-2">
          <div className="flex flex-1 flex-col gap-2 text-left text-14 font-500 text-primary">
            <p>{getFormattedPlace(origin)}</p>
            <p>{getFormattedPlace(destination)}</p>
          </div>
          <p className="text-14 font-500 text-eva">
            CA$ <span className="text-24">{price}</span>.00
          </p>
        </div>
      </Button>
    );
  };

  if (!trips.length) {
    return null;
  }

  return (
    <div className="mt-11">
      <Render if={total > 0}>
        <h3 className="mb-4 px-7 text-20 font-700 text-primary">
          <span className="text-[#22AF27]">+{total}</span> New rides
        </h3>
      </Render>
      <div className="grid grid-cols-4 gap-x-8 gap-y-[22px] md:grid-cols-2 sm:grid-cols-1">
        {trips.sort(sortEnabledTrips).map(makeTrip)}
      </div>
    </div>
  );
};

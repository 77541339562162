import { Children, cloneElement, RefObject, useState } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';

import { Any } from '@typings';
import { cn } from '@utils';

import { Button, Icon } from '@components';

import { ModalContent } from './ModalContent';
import { ModalDivider } from './ModalDivider';

type Props = {
  className?: string;
  disabled?: boolean;
  defaultOpen?: boolean;
  showCloseButton?: boolean;
  preventOutsideClick?: boolean;
  content?: React.ReactElement;
  /**
   * Modal trigger
   */
  children?: React.ReactElement;
  /**
   * Portal container
   */
  containerRef?: RefObject<HTMLElement>;
  onClose?: (...args: unknown[]) => void;
};

export const Modal = ({
  className,
  disabled,
  defaultOpen = false,
  showCloseButton = true,
  preventOutsideClick = false,
  content,
  children,
  containerRef,
  onClose = () => {},
}: Props) => {
  const [open, setOpen] = useState(defaultOpen);

  const isOpen = !disabled && open;

  const handleClose = (...args: unknown[]) => {
    onClose(...args);

    setOpen(false);
  };

  const handlePointerDownOutside = (event: Event) => {
    if (preventOutsideClick) {
      event.preventDefault();
    }
  };

  return (
    <Dialog.Root open={isOpen} onOpenChange={setOpen}>
      {children && (
        <Dialog.Trigger asChild disabled={disabled}>
          {children}
        </Dialog.Trigger>
      )}
      <Dialog.Portal container={containerRef?.current}>
        <Dialog.Overlay className="fixed inset-0 z-40 bg-black/50 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out data-[state=open]:fade-in" />
        <Dialog.Content
          onPointerDownOutside={handlePointerDownOutside}
          className={cn(
            'fixed left-1/2 top-1/2 z-50 flex max-h-full max-w-full -translate-x-1/2 -translate-y-1/2 outline-none data-[state=closed]:animate-modal-out data-[state=open]:animate-modal-in',
            className,
          )}
        >
          {Children.map(content, (child) =>
            cloneElement(child as React.ReactElement<Any>, {
              closeModal: handleClose,
            }),
          )}
          {showCloseButton && (
            <Dialog.Close asChild onClick={handleClose}>
              <Button
                theme
                className="absolute right-5 top-5 text-[20px] text-neural-03 outline-none transition-colors hover:text-black"
              >
                <Icon icon={faXmark} />
              </Button>
            </Dialog.Close>
          )}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

Modal.Content = ModalContent;
Modal.Divider = ModalDivider;

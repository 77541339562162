import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Location } from '@typings';

export type ConfigState = {
  geo: Location.Geo;
};

const initialState = {
  /**
   * Calgary
   */
  geo: {
    lat: 51.0276233,
    lng: -114.087835,
  },
} as ConfigState;

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setConfig: (_, action: PayloadAction<ConfigState>) => action.payload,
  },
});

export const { setConfig } = configSlice.actions;

export default configSlice.reducer;

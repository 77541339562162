import { combineReducers } from 'redux';

import notificationsReducer from './slices/cache/notifications.slice';
import tripReducer from './slices/cache/trip.slice';
import configReducer from './slices/config.slice';
import userReducer from './slices/user.slice';

export const rootReducer = combineReducers({
  config: configReducer,
  user: userReducer,
  cache: combineReducers({
    notifications: notificationsReducer,
    trip: tripReducer,
  }),
});
